import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

// Hashtag formatlama funksiyası
function hashtagFormatter(quill) {
  const text = quill.getText();
  const ops = [];
  let cursor = 0;

  // Regex-i yeniləyirik: boşluqla bitən hashtag-ləri tutmaq üçün
  text.replace(/(#[a-zA-Z0-9]+)(\s|$)/g, (match, hashtag, space, offset) => {
    if (cursor < offset) {
      ops.push({ retain: offset - cursor });
    }
    ops.push({
      retain: hashtag.length,
      attributes: { bold: true, color: "#202020" },
    });
    // Boşluğu normal formatda saxlayırıq
    if (space) {
      ops.push({ retain: space.length });
    }
    cursor = offset + match.length;
  });

  if (cursor < text.length) {
    ops.push({ retain: text.length - cursor });
  }

  quill.updateContents({ ops }, "silent");
}

// Quill üçün modullar və formatlar
const modules = {
  toolbar: false,
  keyboard: {
    bindings: {
      hashtag: {
        key: " ",
        prefix: /#[a-zA-Z0-9]+$/,
        handler(range, context) {
          if (context.prefix) {
            const hashtag = context.prefix[0];
            const index = range.index - hashtag.length;
            this.quill.formatText(index, hashtag.length, {
              bold: true,
              color: "#202020",
            });
            this.quill.insertText(range.index, " ", {
              bold: false,
              color: null,
            });
          }
        },
      },
    },
  },
  clipboard: {
    matchVisual: false,
  },
};

const formats = ["bold", "color"];

const HashtagHighlighter = ({
  text = "",
  setText,
  placeholder = "Write something...",
}) => {
  const { t } = useTranslation();
  const quillRef = React.useRef(null);

  React.useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      // Hər bir simvol dəyişikliyində hashtag formatlamanı yoxla
      quill.on("text-change", () => {
        requestAnimationFrame(() => {
          hashtagFormatter(quill);
        });
      });
    }
  }, []);

  const handleChange = (content, delta, source, editor) => {
    const htmlContent = editor.getHTML();
    setText(htmlContent);
  };

  return (
    <StyledQuillWrapper>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={text}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        placeholder={t("write-something")}
      />
    </StyledQuillWrapper>
  );
};

export default HashtagHighlighter;

const StyledQuillWrapper = styled.div`
  .ql-container {
    border: none;
    font-size: 16px;
    font-family: inherit;
  }

  .ql-editor {
    padding: 10px;
    min-height: 50px;
    max-height: 200px;
    overflow-y: auto;
    word-wrap: break-word;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #029199;
      border-radius: 4px;
    }

    p {
      margin: 0;
    }

    .hashtag {
      font-weight: bold;
      color: #0073e6;
    }
  }

  .ql-editor.ql-blank::before {
    color: #aaa;
    font-style: normal;
  }

  @media (max-width: 768px) {
    .ql-editor {
      min-height: 150px;
      font-size: 14px;
      padding: 10px 10px 10px 5px;
    }
  }
`;
