import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import { X } from "tabler-icons-react";
import { useParams } from "react-router-dom";
import CommentSection from "../posts/CommentSection";
import { useMediaQuery } from "@mantine/hooks";

const ModalShowPost = ({ isOpen, onClose, post, community_id, type }) => {
  const xs = useMediaQuery("(max-width: 768px)");

  const { text, community_images = [], community_videos = [] } = post;
  const allMedia = [
    ...community_images.map((img) => ({ type: "image", url: img.image })),
    ...community_videos.map((video) => ({
      type: "video",
      url: video.video_url,
    })),
  ];

  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>
          <X color={xs ? "#000" : "#D9D9D9"} size={24} />
        </CloseButton>

        <ModalBody>
          <SwiperContainer>
            <Swiper
              navigation={true}
              modules={[Navigation]}
              className="mySwiper"
              speed={500}
              slidesPerView={1}
              slidesPerGroup={1}
              watchSlidesProgress={true}
              preventInteractionOnTransition={true}
              allowTouchMove={true}
              threshold={5}
            >
              {allMedia.map((media, index) => (
                <SwiperSlide key={index}>
                  {media.type === "image" ? (
                    <Image src={media.url} alt={`media-${index}`} />
                  ) : (
                    <IframeWrapper>
                      <Iframe
                        src={media.url}
                        title={`video-${index}`}
                        allowFullScreen
                      />
                    </IframeWrapper>
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          </SwiperContainer>

          <ContentSection>
            <div
              className="post-text"
              dangerouslySetInnerHTML={{ __html: text }}
            />
            <CommentSection
              community_id={community_id}
              post_id={post.id}
              type={type}
            />
          </ContentSection>
        </ModalBody>
      </ModalContent>
    </ModalOverlay>
  );
};

// Styled Components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 1012px;
  height: 83vh;
  background: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    height: 95vh;
    width: 95%;
    margin: 0 auto;
    border-radius: 0;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: -35px;
  right: -40px;
  z-index: 9999999;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  &:hover {
    background: #f5f5f5;
    color: #000;
  }

  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
    top: -10px;
    right: -4px;
    background: rgba(255, 255, 255, 0.8);
    color: #000;
  }
`;

const ModalBody = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  height: 100%;
  overflow: hidden;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 25vh 70vh;
    height: 100%;
  }
`;

const SwiperContainer = styled.div`
  background: rgba(0, 0, 0, 0.85);
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    height: 100%;
  }

  .swiper {
    height: 100%;
    width: 100%;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Ox stillərini burda tənzimləyirik */
  .swiper-button-next,
  .swiper-button-prev {
    color: #2e2e2e; /* yaşıl oxlar */
    background: #fff;
    filter: drop-shadow(0px -1px 15px rgba(0, 0, 0, 0.2));
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    &:after {
      font-size: 14px;
    }

    &:hover {
      background: #e0e0e0; /* hovere basqa bir reng */
    }

    @media (max-width: 768px) {
      width: 30px;
      height: 30px;
    }
  }

  /* Oxların pozisiyasını tənzimləmək üçün */
  .swiper-button-prev {
    left: 10px;
  }

  .swiper-button-next {
    right: 10px;
  }

  /* Disabled vəziyyətində stil */
  .swiper-button-disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const IframeWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  width: 100%;
  height: 100%;
`;

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

const ContentSection = styled.div`
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .post-text {
    font-size: 16px;
    color: #4a4a4a;
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding-right: 16px;
    border-bottom: 1px solid #dfdfdf;
    flex-shrink: 0;

    @media (max-width: 768px) {
      font-size: 14px;
      margin-bottom: 8px;
      padding-bottom: 8px;
    }
  }

  @media (max-width: 768px) {
    padding: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  > div:last-child {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      flex: 1;
      min-height: 0;
    }
  }
`;

const PostText = styled.div``;

export default ModalShowPost;
