import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteComment } from "../../../services/apiPost";

export const useDeleteComment = (community_id, post_id, commentPage, replyPage, replyingTo) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({comment_id,sectionType}) => deleteComment({ community_id, post_id, comment_id:comment_id }),
        onSuccess: (data,{sectionType}) => {
            // If deleting a reply, invalidate only replies query
            if (sectionType === "deleteReply") {
                queryClient.invalidateQueries({ 
                    queryKey: ["replies", community_id, post_id, replyingTo, replyPage] 
                });
            } 
            // If deleting a main comment, invalidate only comments query
            else {
                queryClient.invalidateQueries({ 
                    queryKey: ["comments", community_id, post_id, commentPage] 
                });
            }
        },
    });
};