import React from "react";
import { Accordion, Group, Text } from "@mantine/core";
import { Edit, Plus } from "tabler-icons-react";
import { useMediaQuery } from "@mantine/hooks";

const CustomAccordion = ({
  defaultValue,
  value,
  title,
  handleActiveCreateForm,
  isCreateFormActive,
  handleActiveEditForm,
  isEditFormActive,
  handleActiveIcons,
  isShowIcons,
  handleId,
  handleSkillHeaderId,
  children,
  isPublic,
  user,
  data,
  userData,
}) => {
  const [opened, setOpened] = React.useState(defaultValue);

  const handleIconClick = ({ event, type }) => {
    event.stopPropagation();
    setOpened(value);
    
    if (type === "create") {
      handleActiveEditForm && handleActiveEditForm(false);
      handleId && handleId(null);
      handleActiveIcons && handleActiveIcons(false);
      handleActiveCreateForm && handleActiveCreateForm(!isCreateFormActive);
    } else {
      handleActiveCreateForm && handleActiveCreateForm(false);
      handleActiveIcons && handleActiveIcons(!isShowIcons);
      handleActiveEditForm && handleActiveEditForm(!isEditFormActive);
    }

    if (handleSkillHeaderId && value === "interest") {
      handleSkillHeaderId(4);
    } else if (handleSkillHeaderId && value === "endorsement") {
      handleSkillHeaderId(5);
    } else if (handleSkillHeaderId && value === "language") {
      handleSkillHeaderId(3);
    } else if (handleSkillHeaderId && value === "professional-skill") {
      handleSkillHeaderId(2);
    } else if (handleSkillHeaderId && value === "soft-skill") {
      handleSkillHeaderId(1);
    }
  };

  const xs = useMediaQuery("(max-width: 576px)");
  const isShowEdit = (data && data.length > 0) || userData;
  const isShowPlusAtCountry = !(
    value === "country" &&
    user &&
    user?.country_name
  );
  const isShowPlusAtCity = !(value === "city" && user && user?.city_name);
  const isShowPlusAtSchoolType = !(
    value === "school-type" &&
    user &&
    (user?.school_type === 0 || user?.school_type === 1)
  );
  const isShowPlusAtGrade = !(value === "class" && user && user?.grade);

  return (
    <Accordion 
      disableChevronRotation 
      value={opened}
      onChange={setOpened}
    >
      <Accordion.Item value={value}>
        <Accordion.Control>
          <Group position="apart" align="center">
            <Text
              size={xs ? 16 : 18}
              weight={500}
              color="#364152"
              component="h2"
            >
              {title}
            </Text>
            {!isPublic && (
              <Group style={{ zIndex: 1 }}>
                {value !== "about" &&
                  ( isShowPlusAtCountry &&
                    isShowPlusAtCity &&
                    isShowPlusAtSchoolType &&
                    isShowPlusAtGrade) && (
                    <Plus
                      size={18}
                      color="#364152"
                      strokeWidth={1.75}
                      onClick={(event) =>
                        handleIconClick({ event, type: "create" })
                      }
                      aria-label="Add new item"
                    />
                  )}
                {isShowEdit && (
                  <Edit
                    size={18}
                    color="#364152"
                    strokeWidth={1.75}
                    onClick={(event) =>
                      handleIconClick({ event, type: "edit" })
                    }
                    aria-label="Edit item"
                  />
                )}
              </Group>
            )}
          </Group>
        </Accordion.Control>
        <Accordion.Panel>{children}</Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default CustomAccordion;
