import React, { useContext, useEffect, useState } from "react";
import { PostLayoutStyled } from "../../style";
import { Group, Text, Menu } from "@mantine/core";
import like from "../../../../Assets/SVGs/community/post/reactions/like.svg";
import support from "../../../../Assets/SVGs/community/post/reactions/support.svg";
import funny from "../../../../Assets/SVGs/community/post/reactions/funny.svg";
import interesting from "../../../../Assets/SVGs/community/post/reactions/interesting.svg";
import beneficial from "../../../../Assets/SVGs/community/post/reactions/beneficial.svg";
import reactions from "../../../../Assets/SVGs/community/post/reaction.svg";
import line from "../../../../Assets/SVGs/community/post/line.svg";
import { Message, ThumbUp } from "tabler-icons-react";
import {
  IconShare3,
  IconDots,
  IconEdit,
  IconTrash,
  IconBookmark,
  IconPin,
} from "@tabler/icons-react";
import { format, parseISO } from "date-fns";
import { useParams } from "react-router-dom";
import { useDeletePost } from "../../hooks/post/useDeletePost";
import { usePinPost } from "../../hooks/post/usePinPost";
import { useSavePost } from "../../hooks/post/useSavePost";
import { useDeleteHighlightPost } from "../../hooks/highlight/useDeleteHighlightPost";
import { useGetReactions } from "../../hooks/post/reaction/useGetReactions";
import { useReactToPost } from "../../hooks/post/reaction/useReactToPost";
import { useUnreactToPost } from "../../hooks/post/reaction/useUnreactToPost";
import CommentSection from "./CommentSection";
import { useGetAllReactions } from "../../hooks/post/reaction/useGetAllReactions";
import { useGetComment } from "../../hooks/post/comment/useGetComment";
import { useGetAllHighlightReactions } from "../../hooks/post/reaction/useGetAllHighlightReactions";
import { AppContext } from "../../../../Helpers/Context";
import ShareDropdown from "../community-panel/post-panel/ShareDropdown";
import { useUnsavePost } from "../../hooks/post/useUnsavePost";
import { useUnpinPost } from "../../hooks/post/useUnpinPost";
import { useGetHighlightReaction } from "../../hooks/post/reaction/highlight/useGetHighlightReaction";
import { usePinHighlightPost } from "../../hooks/highlight/usePinHighlightPost";
import { useMediaQuery } from "@mantine/hooks";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import { UserAvatar } from "../../../../Components/Common/UserAvatar";

const PostLayout = ({
  children,
  post,
  setActiveTab,
  setOpenModal,
  postType,
  setPostId,
  openModal,
  type,
  postTab,
  label,
  isOwner,
}) => {
  const azMonths = [
    "Yan",
    "Fev",
    "Mar",
    "Apr",
    "May",
    "İyun",
    "İyul",
    "Avq",
    "Sen",
    "Okt",
    "Noy",
    "Dek",
  ];
  const { t } = useTranslation();
  const { user } = useContext(AppContext);
  const isOwnerPost = user?.id === post?.author?.id;
  const [svg_icon, setSvgIcon] = useState(null);
  const { id: community_id, highlightId } = useParams();
  const { mutate: deletePost } = useDeletePost(community_id);
  const { mutate: deleteHighlightPost } = useDeleteHighlightPost(
    community_id,
    highlightId,
    postTab,
    label
  );
  const { mutate: pinPost } = usePinPost({
    community_id,
    highlightId,
    post_id: post.id,
    post_tab: postTab,
    label: label,
  });
  const { mutate: pinHighlightPost } = usePinHighlightPost({
    community_id,
    highlightId,
    post_id: null,
    post_tab: postTab,
    label: label,
  });
  const { mutate: unpinPost } = useUnpinPost({
    community_id,
    highlightId,
    post_id: post.id,
    post_tab: postTab,
    label: label,
  });
  const { mutate: savePost } = useSavePost(
    community_id,
    highlightId,
    postTab,
    label,
    type
  );
  const { mutate: unsavePost } = useUnsavePost(
    community_id,
    highlightId,
    postTab,
    label,
    type
  );
  const { data: reaction } = useGetReactions(community_id, post.id);
  const { data: highlightReaction } = useGetHighlightReaction(
    community_id,
    post.id,
    highlightId
  );
  const { mutate: reactToPost } = useReactToPost(
    community_id,
    post.id,
    highlightId
  );
  const { mutate: unreactToPost } = useUnreactToPost(
    community_id,
    post.id,
    highlightId
  );

  const { data: allReactions } = useGetAllReactions(community_id, post.id);
  const { data: allHighlightReactions } = useGetAllHighlightReactions(
    community_id,
    post.id,
    highlightId
  );
  const [showCommentSection, setShowCommentSection] = useState(false);
  const [commentCount, setCommentCount] = useState(0);
  const [showShareDropdown, setShowShareDropdown] = useState(false);
  const [showReactionDropdown, setShowReactionDropdown] = useState(false);
  const [animatingReaction, setAnimatingReaction] = useState(null);

  const reactionOptions = [
    { id: 1, icon: like, name: "Bəyən" },
    { id: 2, icon: support, name: "Dəstək" },
    { id: 3, icon: funny, name: "Gülməli" },
    { id: 4, icon: interesting, name: "Maraqlı" },
    { id: 5, icon: beneficial, name: "Faydalı" },
  ];

  const getSelectedReaction = () => {
    if (highlightId) {
      return reactionOptions.find(
        (option) => option.name === highlightReaction?.reaction_name
      );
    } else {
      return reactionOptions.find(
        (option) => option.name === reaction?.reaction_name
      );
    }
  };

  const formatPostDate = (dateString) => {
    try {
      const date = parseISO(dateString);
      const day = format(date, "d");
      const monthIndex = parseInt(format(date, "M")) - 1;
      const time = format(date, "HH:mm");

      return `${day} ${azMonths[monthIndex]} ${time}`;
    } catch (error) {
      console.error("Date parsing error:", error);
      return dateString;
    }
  };

  const handlePostEdit = () => {
    setOpenModal(true);
    setActiveTab(postType);
    setPostId(post.id);
  };

  const handleDeletePost = () => {
    if (highlightId) {
      deleteHighlightPost({
        community_id: community_id,
        highlight_id: highlightId,
        post_id: post.id,
      });
    } else {
      deletePost({ community_id: community_id, id: post.id });
    }
  };
  const handlePostPin = () => {
    if (highlightId) {
      pinHighlightPost({
        community_id: community_id,
        highlightId,
        post_id: post.id,
      });
    } else {
      pinPost({ community_id: community_id, post_id: post.id });
    }
  };
  const handleSavePost = () => {
    if (post?.is_saved) {
      unsavePost({ community_id: community_id, post_id: post.id });
    } else {
      savePost({ community_id: community_id, post_id: post.id });
    }
  };

  useEffect(() => {
    if (!openModal && setPostId) {
      setPostId(null);
    }
  }, [openModal, setPostId]);

  useEffect(() => {
    if (post) {
      setSvgIcon(post.label_output.svg_icon);
    }
  }, [post]);

  const handleReactToPost = (data) => {
    setAnimatingReaction(reactionOptions[data.reaction - 1]);

    setTimeout(() => {
      setAnimatingReaction(null);
    }, 500);

    if (highlightId) {
      if (
        highlightReaction?.reaction_name ===
        reactionOptions[data.reaction - 1].name
      ) {
        unreactToPost({ postId: post.id });
      } else {
        reactToPost({ postId: post.id, data });
      }
    } else {
      if (reaction?.reaction_name === reactionOptions[data.reaction - 1].name) {
        unreactToPost({ postId: post.id });
      } else {
        reactToPost({ postId: post.id, data });
      }
    }
  };

  const { data: commentsList } = useGetComment(
    community_id,
    post.id,
    1,
    showCommentSection
  );

  useEffect(() => {
    if (commentsList) {
      setCommentCount(commentsList.count);
    }
  }, [commentsList]);

  const getShareUrl = () => {
    const baseUrl = window.location.origin;
    if (type === "highlight") {
      return `${baseUrl}/community/${community_id}/highlight/${highlightId}`;
    }
    return `${baseUrl}/community/${community_id}`;
  };
  const xs = useMediaQuery("(max-width: 768px)");

  const getReactionIcon = (reactionName) => {
    const reaction = reactionOptions.find(
      (option) => option.name === reactionName
    );
    return reaction?.icon;
  };

  const getUniqueReactions = (reactions) => {
    if (!reactions) return [];
    const uniqueReactionNames = [
      ...new Set(reactions.map((r) => r.reaction_name)),
    ];
    return uniqueReactionNames;
  };

  const currentReactions = highlightId
    ? allHighlightReactions?.data
    : allReactions?.data;
  const reactionCount = highlightId
    ? allHighlightReactions?.count
    : allReactions?.count;
  const uniqueReactions = getUniqueReactions(currentReactions);
  return (
    <PostLayoutStyled>
      {post?.is_pinned && (
        <Group spacing={xs ? 4 : 6} mb={xs ? 8 : 10}>
          <IconPin size={xs ? 16 : 18} color="#029199" />
          <Text size={xs ? 14 : 16} weight={500} color="#029199">
            {t("pinned-post")}
          </Text>
        </Group>
      )}
      <Group mb={xs ? 15 : 20} align="center" position="apart">
        <Group align="center" spacing={xs ? 8 : 12}>
          {/* <img
            style={{
              width: xs ? 45 : 55,
              height: xs ? 45 : 55,
              borderRadius: "50%",
            }}
            src={post?.author?.profile_img}
            alt="avatar"
          /> */}
          <UserAvatar img={post?.author?.profile_img} size={xs ? 45 : 55} radius="50%" />
          <div>
            <Group align="center" spacing={xs ? 4 : 6}>
              {post?.author?.badge?.svg_icon && (
                <div
                  style={{
                    width: xs ? 16 : 18,
                    height: xs ? 16 : 18,
                    marginTop: 2,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: post?.author?.badge?.svg_icon?.replace(
                      "<svg",
                      `<svg width="${xs ? 16 : 18}" height="${xs ? 16 : 18}"`
                    ),
                  }}
                />
              )}
              <Text mb={0} weight={500} size={xs ? 14 : 16} c="#202020">
                {post?.author?.first_name} {post?.author?.last_name}
              </Text>
            </Group>
            <Text
              span
              c="#808080"
              size={xs ? 12 : 14}
              weight={500}
              color="#A8AEB4"
            >
              {formatPostDate(post?.created_at)}
            </Text>
          </div>
        </Group>
        <Group spacing={xs ? 6 : 8}>
          {svg_icon && (
            <div
              style={{ width: xs ? 18 : 22, height: xs ? 18 : 22 }}
              className="svg-icon"
              dangerouslySetInnerHTML={{ __html: svg_icon }}
            />
          )}
            <Menu position="bottom-end" shadow="md">
              <Menu.Target>
                <IconDots
                  size={xs ? 24 : 28}
                  strokeWidth={2}
                  style={{ cursor: "pointer", color: "#707070" }}
                />
              </Menu.Target>

              <Menu.Dropdown>
                {isOwner && (
                  <Menu.Item
                    onClick={handlePostPin}
                    style={{
                      fontSize: xs ? 14 : 16,
                      fontWeight: 500,
                      color: "#393838",
                    }}
                    icon={<IconPin size={xs ? 20 : 23} />}
                  >
                    {post?.is_pinned
                      ? t("unpin-the-post")
                      : t("pin-the-post")}
                  </Menu.Item>
                )}
                {(isOwnerPost && type !== 'savedPosts') && (
                  <Menu.Item
                    onClick={handlePostEdit}
                    style={{
                      fontSize: xs ? 14 : 16,
                      fontWeight: 500,
                      color: "#393838",
                    }}
                    icon={<IconEdit size={xs ? 20 : 23} />}
                  >
                    {t("edit-post")}
                  </Menu.Item>
                )}
                {(isOwnerPost && type !== 'savedPosts') && (
                  <Menu.Item
                    onClick={handleDeletePost}
                    style={{
                      fontSize: xs ? 14 : 16,
                      fontWeight: 500,
                      color: "#393838",
                    }}
                    icon={<IconTrash size={xs ? 20 : 23} />}
                    color="red"
                  >
                    {t("delete-post")}
                  </Menu.Item>
                )}
                <Menu.Item
                  onClick={handleSavePost}
                  style={{
                    fontSize: xs ? 14 : 16,
                    fontWeight: 500,
                    color: "#393838",
                  }}
                  icon={
                    <IconBookmark
                      size={xs ? 20 : 23}
                      fill={post?.is_saved ? "#393838" : "none"} // is_saved true olduqda dolacaq
                      color="#393838" // konturun rəngi
                    />
                  }
                >
                  {post?.is_saved ? t("unsave-post") : t("save-post")}
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
        </Group>
      </Group>
      {children}
      <Group mt={11} align="center" position="apart">
        <Group spacing={0}>
          {uniqueReactions.map((reactionName, index) => (
            <img
              key={reactionName}
              style={{
                width: xs ? 15 : 17,
                height: xs ? 15 : 17,
                marginRight:
                  index === uniqueReactions.length - 1
                    ? xs
                      ? 7
                      : 10
                    : xs
                    ? 5
                    : 7,
              }}
              src={getReactionIcon(reactionName)}
              alt={reactionName}
            />
          ))}
          <Text
            span
            c="#808080"
            size={xs ? 13 : 15}
            weight={500}
            color="#707070"
          >
            {reactionCount} {t("reaction-count")}
          </Text>
        </Group>
        <Text
          style={{ cursor: "pointer" }}
          onClick={() => setShowCommentSection((prev) => !prev)}
          span
          color="#029199"
          size={xs ? 13 : 15}
          weight={500}
        >
          {commentCount} {t("comment-count")}
        </Text>
      </Group>
      <div className="line"></div>
      <Group
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <div style={{ position: "relative" }}>
          <AnimatePresence>
            {animatingReaction && (
              <motion.div
                initial={{
                  scale: 0,
                  y: 0,
                  opacity: 0,
                  x: "-50%",
                }}
                animate={{
                  scale: [0, 1.5, 1],
                  y: -50,
                  opacity: [0, 1, 0],
                }}
                exit={{
                  scale: 0,
                  opacity: 0,
                }}
                transition={{
                  duration: 0.5,
                  times: [0, 0.5, 1],
                }}
                style={{
                  position: "absolute",
                  bottom: "100%",
                  left: "50%",
                  transformOrigin: "bottom center",
                  pointerEvents: "none",
                }}
              >
                <img
                  src={animatingReaction.icon}
                  alt={animatingReaction.name}
                  style={{
                    width: "32px",
                    height: "32px",
                  }}
                />
              </motion.div>
            )}
          </AnimatePresence>

          <Group
            style={{ cursor: "pointer" }}
            onClick={() => setShowReactionDropdown(!showReactionDropdown)}
            align="center"
            spacing={xs ? 4 : 5}
          >
            {getSelectedReaction() ? (
              <img
                src={getSelectedReaction().icon}
                alt={getSelectedReaction().name}
                style={{ width: xs ? 20 : 20, height: xs ? 20 : 20 }}
              />
            ) : (
              <ThumbUp
                size={xs ? 20 : 24}
                color="#393838"
                fill={
                  highlightId
                    ? highlightReaction?.id
                      ? "#393838"
                      : "none"
                    : reaction?.id
                    ? "#393838"
                    : "none"
                }
              />
            )}
            <Text size={xs ? 13 : 15} weight={500} color="#393838">
              {getSelectedReaction()?.name || t("like")}
            </Text>
          </Group>

          <AnimatePresence>
            {showReactionDropdown && (
              <motion.div
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.8, opacity: 0 }}
                transition={{
                  type: "spring",
                  stiffness: 300,
                  damping: 25,
                }}
                style={{
                  position: "absolute",
                  bottom: "100%",
                  left: 0,
                  background: "white",
                  padding: "6px 10px",
                  borderRadius: "11.264px",
                  boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
                  display: "flex",
                  gap: "16px",
                  marginBottom: "8px",
                }}
              >
                {reactionOptions.map((option) => (
                  <motion.img
                    key={option.id}
                    src={option.icon}
                    alt={option.name}
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                    style={{
                      width: option.id === 3 ? (xs ? "24px" : "29px") : (xs ? "20px" : "24px"),
                      height: option.id === 3 ? (xs ? "23px" : "28px") : (xs ? "20px" : "24px"), 
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      const data = { reaction: option.id };
                      handleReactToPost(data);
                      setShowReactionDropdown(false);
                    }}
                  />
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        <Group
          style={{ cursor: "pointer" }}
          onClick={() => setShowCommentSection((prev) => !prev)}
          align="center"
          spacing={xs ? 4 : 5}
        >
          <Message size={xs ? 20 : 24} color="#393838" />
          <Text size={xs ? 13 : 15} weight={500} color="#393838">
            {t("comment-section")}
          </Text>
        </Group>
        <Group
          onClick={() => setShowShareDropdown(true)}
          style={{ cursor: "pointer" }}
          align="center"
          spacing={xs ? 4 : 5}
        >
          <IconShare3 size={xs ? 20 : 24} color="#393838" />
          <Text size={xs ? 13 : 15} weight={500} color="#393838">
            {t("post-share")}
          </Text>
        </Group>
      </Group>

      {showCommentSection && (
        <CommentSection
          showCommentSection={showCommentSection}
          community_id={community_id}
          post_id={post.id}
        />
      )}

      <ShareDropdown
        isOpen={showShareDropdown}
        onClose={() => setShowShareDropdown(false)}
        url={getShareUrl()}
      />
    </PostLayoutStyled>
  );
};

export default PostLayout;
