import { Skeleton } from "@mantine/core";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { useTranslation } from "react-i18next";
import { UserAvatar } from "../../../../../../Components/Common/UserAvatar";

const CollaboratorSection = ({ isLoading, collaborators }) => {
  const {t} = useTranslation()
  // Minimum 3 slayd olduqda loop rejimini aktivləşdirək
  const hasEnoughSlides = collaborators?.results?.length >= 3;

  return (
    <div className="panel-section collaborator">
      <h4>{t('collaborator')}</h4>
      {isLoading ? (
        <div style={{ display: "flex", gap: "10px" }}>
          {[1, 2, 3, 4, 5].map((_, index) => (
            <Skeleton key={index} height={45} width={45} circle />
          ))}
        </div>
      ) : (
        <Swiper
          modules={[Navigation, Autoplay]}
          spaceBetween={10}
          slidesPerView="auto"
          autoplay={hasEnoughSlides ? {
            delay: 2500,
            disableOnInteraction: false,
          } : false}
          loop={hasEnoughSlides}
          navigation={collaborators?.results?.length > 1}
          enabled={collaborators?.results?.length > 0}
        >
          {collaborators?.results?.map((collaborator, index) => (
            <SwiperSlide key={index} style={{ width: "auto" }}>
              <div className="avatar">
                {/* <img 
                  style={{borderRadius: "50%", width:"100%", height:"100%"}} 
                  src={collaborator?.user?.profile_img} 
                  alt="avatar" 
                /> */}
                <UserAvatar img={collaborator?.user?.profile_img} size="100%" radius="50%" />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};

export default CollaboratorSection; 