import { Card, Grid, Group, Stack, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Hourglass, Language, Tag } from "tabler-icons-react";

export function ProgramCard({ props }) {
  const { link, image, price,title, language, duration } = props;
  const lg = useMediaQuery("(max-width: 1280px)");
  const md = useMediaQuery("(max-width: 1120px)");
  const md2 = useMediaQuery("(max-width: 990px)");
  const sm = useMediaQuery("(max-width: 720px)");
  const xs = useMediaQuery("(max-width: 576px)");
  return (
    <Grid.Col span={xs ? 12 : sm ? 6 : md2 ? 12 : md ? 6 : 4}>
      <CardStyle component={Link} to={link} p={md2 ? 11 : 14} radius=".75rem" withBorder>
        <Card.Section>
          <img
            src={image?.name ? URL.createObjectURL(image) : image}
            height={158}
            width="100%"
            alt="Card"
            style={{ objectFit: "fill" }}
          />
        </Card.Section>

        <Stack
          spacing={10}
          position="apart"
          mt="xs"
          mb="0"
          style={{ height: "56px" }}
        >
          <Text size={lg ? 14 : 16} weight={600} color="#333333">
            {title?.length > (sm ? 30 : md2 ? 55 : md ? 40 : 30)
              ? title.slice(0, sm ? 30 : md2 ? 55 : md ? 40 : 30) + "..."
                : title || "Type something"}
            </Text>
            <Group spacing={lg ? 8 : 10}>
              <Group spacing={4}>
                <Language strokeWidth={1.75} color="#62657D" size={18} />
                <Text size={lg ? 13 : 14} weight={400} color="#3B3F5C">
                  {language}
                </Text>
              </Group>
              <div
                style={{
                width: "1px",
                height: "11px",
                background: "#62657D",
              }}
            ></div>
            <Group spacing={4}>
              <Hourglass strokeWidth={1.75} color="#62657D" size={18} />
              <Text size={lg ? 13 : 14} weight={400} color="#3B3F5C">
                {duration}
              </Text>
            </Group>
            <div
              style={{
                width: "1px",
                height: "11px",
                background: "#62657D",
              }}
            ></div>
            <Group spacing={4}>
              <Tag strokeWidth={1.75} color="#62657D" size={18} />
              <Text size={lg ? 13 : 14} weight={400} color="#3B3F5C">
                {price} ₼
              </Text>
            </Group>
          </Group>
        </Stack>
      </CardStyle>
    </Grid.Col>
  );
}

const CardStyle = styled(Card)`
  border: 1px solid #dee2e6;
  box-shadow: 0px 4.72px 9.45px 0px #d3dae2;
`;
