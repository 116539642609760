import React from "react";
import styled from "styled-components";
import { Skeleton, Grid, Group } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

// Banner Skeleton
export const BannerSkeleton = () => (
  <BannerStyle>
    <div className="banner-content">
      <Skeleton height={45} width="60%" mb={20} radius="md" />
      <Skeleton height={70} width="80%" radius="md" />
    </div>
  </BannerStyle>
);

// RightBox Skeleton
export const RightBoxSkeleton = () => {
  const matches = useMediaQuery("(max-width: 576px)");
  
  return (
    <RightBoxStyle>
      <Grid align="center">
        <Grid.Col xl={6} md={6} sm={12}>
          <div className="txt">
            <Skeleton height={35} width="90%" mb={20} radius="md" />
            <Skeleton height={100} width="100%" mb={15} radius="md" />
            <Skeleton height={20} width="80%" mb={10} radius="md" />
            <Skeleton height={20} width="70%" radius="md" />
          </div>
        </Grid.Col>
        <Grid.Col xl={6} md={6} sm={12} className="right">
          {matches ? (
            <div style={{ padding: "20px" }}>
              <Skeleton height={200} width="100%" radius="lg" mb={20} />
            </div>
          ) : (
            <div className="card-container">
              {[1, 2].map((item) => (
                <Skeleton key={item} height={200} width="100%" radius="lg" />
              ))}
            </div>
          )}
        </Grid.Col>
      </Grid>
    </RightBoxStyle>
  );
};

// Audience Skeleton
export const AudienceSkeleton = () => (
  <AudienceStyle>
    <div className="audience-content">
      <Skeleton height={35} width={200} mb={20} radius="md" />
      <Skeleton height={300} width="100%" radius="lg" />
    </div>
    <Group position="center" mt={50} spacing={30}>
      {[1, 2, 3].map((item) => (
        <Skeleton key={item} height={50} width={200} radius="md" />
      ))}
    </Group>
  </AudienceStyle>
);

// Partners Skeleton
export const PartnersSkeleton = () => (
  <PartnersStyle>
    <Skeleton height={35} width={200} mb={20} radius="md" />
    <Grid gutter="md">
      {[1, 2, 3, 4, 5, 6].map((item) => (
        <Grid.Col key={item} span={4}>
          <Skeleton height={80} radius="md" />
        </Grid.Col>
      ))}
    </Grid>
  </PartnersStyle>
);

// Contact Form Skeleton
export const ContactFormSkeleton = () => (
  <ContactFormStyle>
    <Skeleton height={35} width={200} mb={40} radius="md" />
    <div className="form-container">
      <Group grow mb={20}>
        <Skeleton height={70} radius="md" />
        <Skeleton height={70} radius="md" />
      </Group>
      <Group grow mb={20}>
        <Skeleton height={70} radius="md" />
        <Skeleton height={70} radius="md" />
      </Group>
      <Skeleton height={70} mb={20} radius="md" />
      <Skeleton height={150} mb={20} radius="md" />
      <Skeleton height={45} radius="md" />
    </div>
  </ContactFormStyle>
);

// Styles
const BannerStyle = styled.div`
  min-height: 362px;
  padding: 140px 60px 0;
  background: #f5f5f5;
  
  @media (max-width: 990px) {
    padding: 40px 20px;
    text-align: center;
  }
`;

const RightBoxStyle = styled.div`
  max-width: 90%;
  margin: 0 auto 80px auto;
  
  .card-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
  }
  
  @media (max-width: 990px) {
    .txt {
      text-align: center;
    }
  }
`;

const AudienceStyle = styled.div`
  padding: 50px 20px;
  max-width: 90%;
  margin: 0 auto;
  
  @media (max-width: 768px) {
    padding-top: 90px;
  }
`;

const PartnersStyle = styled.div`
  max-width: 90%;
  margin: 40px auto 80px;
  text-align: center;
`;

const ContactFormStyle = styled.div`
  max-width: 90%;
  margin: 0 auto 80px;
  text-align: center;
  
  .form-container {
    max-width: 45%;
    margin: 0 auto;
    padding: 32px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 0px 10px 0px #00000014;
    
    @media (max-width: 768px) {
      max-width: 100%;
    }
  }
`; 