import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateComment } from "../../../services/apiPost";

export const useUpdateComment = (community_id, post_id, comment_id,commentPage,replyPage,replyingTo,sectionType) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (data) => updateComment({ community_id, post_id, comment_id, data }),
        onSuccess: () => {
            if(sectionType === "editReply"){
                queryClient.invalidateQueries({ queryKey: ["replies", community_id, post_id, replyingTo, replyPage] });
            } else {
                queryClient.invalidateQueries({ queryKey: ["comments", community_id, post_id, commentPage] });
            }
        },
    });
};