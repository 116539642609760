import { Avatar } from "@mantine/core";
import defaultImage from "../../Assets/SVGs/header/user.png";

export const UserAvatar = ({ img, size, radius }) => {
  return (
    <Avatar
      src={
        img && img.includes("user-profile")
          ? img
          : defaultImage
      }
      size={size}
      radius="50%"
    />
  );
};
