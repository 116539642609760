import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import { Group, Text, Title, Modal, Stack } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useQuery } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import LottieLoading from "../../Components/Loaders/LottieLoading";
import badge from "../../Assets/SVGs/steams/pathway/badge.svg";
import Lottie from "lottie-react";
import congrat from "../../Assets/Lottie/congrat.json";
import { useTranslation } from "react-i18next";
import { showNotification } from "@mantine/notifications";
import { AlertCircle } from "tabler-icons-react";
import {
  HalfCircle,
  LeftBottom,
  LeftCenter,
  LeftTop,
  RightBottom,
  RightTop,
  Rocket,
} from "../../theme/index.jsx";
import { pathwayThemes } from "../../theme/pathwayTheme";
import {
  HalfCircleWrapper,
  Line,
  NonReversedStage,
  ReversedStage,
  RocketIconWrapper,
  Stage,
  StageContainer,
} from "../../Components/Programs/ProgramStage.jsx";

const WeekPathway = ({ type }) => {
  const link = type === "steam" ? "steam/steam/" : "bootcamp/";
  const tUnit = type === "steam" ? "week" : "section";
  const xs = useMediaQuery("(max-width: 1120px)");
  const unitNumber = localStorage.getItem(`${tUnit}Number`);
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const status = searchParams.get(`${tUnit}-${unitNumber}-Completed`);
  const [isOpen, setIsOpen] = useState(true);
  const { t } = useTranslation();
  const [sortedData, setSortedData] = useState([]);
  const navigate = useNavigate();
  const [unitBadge, setUnitBadge] = useState("");

  const getIconSrc = (icon, isSelected, replacedColor) => {
    if (isSelected) {
      return icon?.replace("%23CDCDCD", replacedColor); // stroke rengini değiştir
    }
    return icon?.src;
  };

  const { data: units, isLoading } = useQuery({
    queryKey: [`${tUnit}s`, id],
    queryFn: async () => {
      const response = await axios.get(`${link}${id}/${tUnit}`);
      return response.data.data;
    },
  });

  useEffect(() => {
    if (units) {
      const sortData = units.sort((a, b) => a.id - b.id);
      setSortedData(sortData);
    }
  }, [units]);

  const handleNavigate = (isCompleted, index) => {
    if (isCompleted) {
      localStorage.setItem(`${tUnit}Number`, index + 1);
      navigate(
        `/${type === "steam" ? "steam" : "bootcamp"}/${id}/${tUnit}-pathway/${
          sortedData[index].id
        }`
      );
    } else {
      showNotification({
        icon: <AlertCircle />,
        title: t("warning"),
        message: t(`warning_${tUnit}`),
        color: "red",
      });
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    navigate(
      `/${type === "steam" ? "steam" : "bootcamp"}/${id}/${tUnit}-pathway`
    );
  };

  useEffect(() => {
    if (sortedData.length > 0) {
      const icon = getIconSrc(
        sortedData[unitNumber - 1] &&
          sortedData[unitNumber - 1][`${tUnit}_icon`],
        true,
        "%23FFF3BD"
      );
      setUnitBadge(icon);
    }
  }, [sortedData, unitNumber, tUnit]);

  if (isLoading) return <LottieLoading />;

  return (
    <PathwayStyle type={type}>
      <Helmet>
        <title>{t("week_pathway_title")}</title>
        <meta name="description" content={t("week_pathway_description")} />
      </Helmet>

      <ImageStyle height={xs ? "74px" : undefined} left={0} top={0}>
        <LeftTop theme={pathwayThemes[type]} />
      </ImageStyle>
      <ImageStyle height={xs ? "104px" : undefined} left={0} bottom={0}>
        <LeftBottom theme={pathwayThemes[type]} />
      </ImageStyle>
      {!xs && (
        <ImageStyle
          left={0}
          top="50%"
          style={{ transform: "translateY(-65%)" }}
        >
          <LeftCenter theme={pathwayThemes[type]} />
        </ImageStyle>
      )}
      <ImageStyle height={xs ? "145px" : undefined} right={0} top={20}>
        <RightTop theme={pathwayThemes[type]} />
      </ImageStyle>
      <ImageStyle height={xs ? "104px" : undefined} right={0} bottom={0}>
        <RightBottom theme={pathwayThemes[type]} />
      </ImageStyle>

      {status && (
        <Modal opened={isOpen} onClose={closeModal} size="lg">
          <Stack pb={120} align="center" justify="center" spacing={3}>
            <Text size={xs ? 24 : 30} weight={600} color="#029199">
              {t("congrat")}
            </Text>
            <Text
              align="center"
              size={xs ? 18 : 20}
              weight={400}
              color="#029199"
            >
              {t("congrat_message")}
            </Text>
            <div
              style={{
                position: "relative",
                marginTop: "25px",
                width: "252px",
                height: "181px",
              }}
            >
              <img src={badge} alt="badge" />
              {unitBadge && (
                <img
                  style={{
                    width: "75px",
                    height: "75px",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50% , -50%)",
                  }}
                  src={unitBadge}
                  alt="badge"
                />
              )}
            </div>
          </Stack>
          <Lottie
            animationData={congrat}
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              overflow: "hidden",
              width: "100%",
              height: "90vh",
            }}
          />
        </Modal>
      )}

      {!xs ? (
        <WeekContainer dataLength={sortedData.length}>
          <Title
            mb={110}
            align="center"
            size={28}
            weight={700}
            color={type === "steam" ? "#029199" : "#686868"}
          >
            {type === "steam" ? t("week_pathway") : t("section_pathway")}
          </Title>

          {sortedData && sortedData.length === 1 && (
            <StageContainer
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stage
                type={type}
                onClick={() => {
                  localStorage.setItem(`${tUnit}Number`, 1);
                  navigate(
                    `/${type}/${id}/${tUnit}-pathway/${sortedData[0].id}`
                  );
                }}
              >
                <Text
                  sx={{
                    position: "absolute",
                    width: "70%",
                    textAlign: "center",
                    top: "-80px",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                  size={14}
                  weight={500}
                  color={type === "steam" ? "#029199" : "#686868"}
                >
                  {sortedData[0].description.length < 130
                    ? sortedData[0].description
                    : sortedData[0].description.slice(0, 130) + "..."}
                </Text>
                <div className="white-circle">
                  {sortedData[0].is_completed ? (
                    <img
                      style={{ width: "35px", height: "35px" }}
                      src={getIconSrc(
                        sortedData[0] && sortedData[0][`${tUnit}_icon`],
                        true,
                        type === "steam" ? "%23B1DD47" : "%23FFCC42"
                      )}
                      alt={`badge-${sortedData[0].id}`}
                    />
                  ) : (
                    1
                  )}
                </div>
              </Stage>
            </StageContainer>
          )}

          {sortedData &&
            sortedData.length > 1 &&
            sortedData?.map((u, index) => (
              <React.Fragment key={index}>
                {index === 1 && (
                  <StageContainer
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Stage
                      type={type}
                      onClick={() => {
                        localStorage.setItem(`${tUnit}Number`, 1);
                        navigate(
                          `/${type}/${id}/${tUnit}-pathway/${sortedData[0].id}`
                        );
                      }}
                    >
                      <Text
                        sx={{
                          position: "absolute",
                          width: "63%",
                          textAlign: "right",
                          top: "-80px",
                          left: "-23%",
                        }}
                        size={14}
                        weight={500}
                        color={type === "steam" ? "#029199" : "#686868"}
                      >
                        {sortedData[0].description.length < 130
                          ? sortedData[0].description
                          : sortedData[0].description.slice(0, 130) + "..."}
                      </Text>
                      <div className="white-circle">
                        {sortedData[0].is_completed ? (
                          <img
                            style={{ width: "35px", height: "35px" }}
                            src={getIconSrc(
                              sortedData[0] && sortedData[0][`${tUnit}_icon`],
                              true,
                              type === "steam" ? "%23B1DD47" : "%23FFCC42"
                            )}
                            alt={`badge-${sortedData[0].id}`}
                          />
                        ) : (
                          1
                        )}
                      </div>
                    </Stage>
                    <Line type={type} />
                    <Stage
                      type={type}
                      onClick={() =>
                        handleNavigate(sortedData[0].is_completed, 1)
                      }
                    >
                      <Text
                        sx={{
                          position: "absolute",
                          width: "63%",
                          textAlign: "left",
                          top: "-80px",
                          right: "-23%",
                        }}
                        size={14}
                        weight={500}
                        color={type === "steam" ? "#029199" : "#686868"}
                      >
                        {sortedData[1].description.length < 130
                          ? sortedData[1].description
                          : sortedData[1].description.slice(0, 130) + "..."}
                      </Text>
                      <div className="white-circle">
                        {sortedData[1].is_completed ? (
                          <img
                            style={{ width: "35px", height: "35px" }}
                            src={getIconSrc(
                              sortedData[1] && sortedData[1][`${tUnit}_icon`],
                              true,
                              type === "steam" ? "%23B1DD47" : "%23FFCC42"
                            )}
                            alt={`badge-${sortedData[1].id}`}
                          />
                        ) : (
                          2
                        )}
                      </div>
                    </Stage>
                    <RocketIconWrapper rotate={"false"}>
                      <Rocket theme={pathwayThemes[type]} />
                    </RocketIconWrapper>

                    {sortedData?.length >= 3 && (
                      <HalfCircleWrapper right="-132px" top="41px">
                        <HalfCircle theme={pathwayThemes[type]} />
                      </HalfCircleWrapper>
                    )}
                  </StageContainer>
                )}
                {index === 2 && (
                  <NonReversedStage
                    sortedData={sortedData}
                    index={index}
                    type={type}
                    handleNavigate={handleNavigate}
                    tUnit={tUnit}
                  />
                )}
                {index === 4 && (
                  <ReversedStage
                    sortedData={sortedData}
                    index={index}
                    type={type}
                    handleNavigate={handleNavigate}
                    tUnit={tUnit}
                  />
                )}
                {index === 6 && (
                  <NonReversedStage
                    sortedData={sortedData}
                    index={index}
                    type={type}
                    handleNavigate={handleNavigate}
                    tUnit={tUnit}
                  />
                )}
                {index === 8 && (
                  <ReversedStage
                    sortedData={sortedData}
                    index={index}
                    type={type}
                    handleNavigate={handleNavigate}
                    tUnit={tUnit}
                  />
                )}
                {index === 10 && (
                  <NonReversedStage
                    sortedData={sortedData}
                    index={index}
                    type={type}
                    handleNavigate={handleNavigate}
                    tUnit={tUnit}
                  />
                )}
              </React.Fragment>
            ))}
        </WeekContainer>
      ) : (
        <ResponsiveWeekContainer>
          <Title
            pt={0}
            mb={20}
            align="center"
            size={18}
            weight={700}
            color={type === "steam" ? "#029199" : "#686868"}
          >
            {tUnit.charAt(0).toUpperCase() + tUnit.slice(1)} Pathway
          </Title>

          {sortedData &&
            sortedData?.map((u, index) => (
              <Group
                key={index}
                mb={15}
                style={
                  (index + 1) % 2 === 0
                    ? { flexDirection: "row-reverse", gap: "10px" }
                    : {}
                }
                noWrap
                align="center"
              >
                <Stage
                  type={type}
                  onClick={() => {
                    if (index === 0) {
                      navigate(`/${type}/${id}/${tUnit}-pathway/${u.id}`);
                    } else {
                      handleNavigate(sortedData[index - 1].is_completed, index);
                    }
                  }}
                >
                  <div className="white-circle">
                    {sortedData[index].is_completed ? (
                      <img
                        src={getIconSrc(
                          sortedData[index] &&
                            sortedData[index][`${tUnit}_icon`],
                          true,
                          type === "steam" ? "%23B1DD47" : "%23FFCC42"
                        )}
                        alt={`badge-${sortedData[index].id}`}
                      />
                    ) : (
                      index + 1
                    )}
                  </div>
                </Stage>
                <Text
                  style={{
                    width: "70%",
                    textAlign: (index + 1) % 2 === 0 ? "right" : "left",
                  }}
                  size={14}
                  weight={500}
                  color={type === "steam" ? "#029199" : "#686868"}
                >
                  {/* {u?.description?.length < 130
                    ? u?.description
                    : u?.description?.slice(0, 130) + "..."} */}
                  Dərin Öyrənmə Nədir? Keras və TensorFlow-a Giriş Neyron
                  Şəbəkələr CNN-lər (Convolutional Neural Networks) RNN-lər
                  (Recurrent Neura...
                </Text>
              </Group>
            ))}
        </ResponsiveWeekContainer>
      )}
    </PathwayStyle>
  );
};

export default WeekPathway;

const PathwayStyle = styled.div`
  background: ${(props) =>
    props.type === "steam"
      ? "radial-gradient(60.28% 60.28% at 50% 50%, #ffffff 55.19%, rgba(212, 253, 255, 0.28) 100%)"
      : "radial-gradient(60.28% 60.28% at 50% 50%, #FFFFFF 0%, rgba(255, 243, 211, 0.09) 96%)"};
  width: 100%;
  min-height: 100vh;
  position: relative;
`;

const ImageStyle = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  bottom: ${(props) => props.bottom};
  right: ${(props) => props.right};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

const WeekContainer = styled.div`
  width: 78%;
  margin: 0 auto;
  padding: ${(props) => (props.dataLength > 2 ? "40px 0" : "0")};
  display: ${(props) => (props.dataLength <= 2 ? "flex" : "block")};
  flex-direction: ${(props) => (props.dataLength <= 2 ? "column" : "row")};
  align-items: ${(props) => (props.dataLength <= 2 ? "center" : "flex-start")};
  justify-content: ${(props) =>
    props.dataLength <= 2 ? "center" : "flex-start"};
  min-height: ${(props) => (props.dataLength <= 2 ? "100dvh" : "auto")};
  @media (max-width: 1440px) {
    width: 82%;
  }
  @media (max-width: 1280px) {
    width: 88%;
  }
  @media (max-width: 1120px) {
    width: 92%;
  }
`;

const ResponsiveWeekContainer = styled.div`
  padding: 40px 0 100px 0;
  width: 90%;
  margin: 0 auto;
  .white-circle {
    img {
      width: 23px;
      height: 23px;
      @media (max-width: 1280px) {
        width: 30px;
        height: 30px;
      }
      @media (max-width: 990px) {
        width: 28px;
        height: 28px;
      }
      @media (max-width: 864px) {
        width: 26px;
        height: 26px;
      }
    }
  }
`;

