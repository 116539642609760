import React, { useState, useEffect } from "react";
import HighlightCarousel from "../../highlight/HighlightCarousel";
import PostList from "./PostList";
import CreatePostSide from "../../posts/CreatePostSide";
import { useMediaQuery } from "@mantine/hooks";
import { CommunityHeader, } from "./CommunityHeader";
import PostTabs from "./PostTabs";

import ManageAccordion from "./ManageAccordion";
import { ResponsiveCommunityHeader } from "./ResponsiveCommunityHeader";

const PostPanel = ({
  type,
  community,
  highlight,
  highlights,
  isLoading,
  isLoadingHighlights,
  isOwner,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [postId, setPostId] = useState(null);
  const [activeTab, setActiveTab] = useState("");
  const [postTab, setPostTab] = useState("posts");
  const [label, setLabel] = useState(null);
  const xs = useMediaQuery("(max-width: 720px)");



  return (
    <div>
        <ResponsiveCommunityHeader
          type={type}
          highlight={highlight}
          community={community}
          isLoading={isLoading}
          setPostTab={setPostTab}
        />
        <CommunityHeader
          type={type}
          highlight={highlight}
          community={community}
          isLoading={isLoading}
          setPostTab={setPostTab}
        />
      {xs && isOwner && (
        <ManageAccordion />
      )}
      {xs && <PostTabs setPostTab={setPostTab} />}
      {type === "community" && (
        <HighlightCarousel
          isOwner={isOwner}
          highlights={highlights}
          isLoading={isLoadingHighlights}
        />
      )}
      {/* Show CreatePostSide if:
          1. Type is community AND user is owner, OR
          2. Type is highlight AND (user is owner OR highlight is not private) */}
      {type === "community" ||
      (type === "highlight" && (isOwner || !highlight[0]?.only_me)) ? (
        <CreatePostSide
          type={type}
          communityId={community?.id}
          openModal={openModal}
          setOpenModal={setOpenModal}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          postId={postId}
          postTab={postTab}
          label={label}
        />
      ) : null}
      <PostList
        type={type}
        setActiveTab={setActiveTab}
        setOpenModal={setOpenModal}
        openModal={openModal}
        setPostId={setPostId}
        postId={postId}
        postTab={postTab}
        isOwner={isOwner}
        label={label}
        setLabel={setLabel}
      />
    </div>
  );
};

export default PostPanel;
