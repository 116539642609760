import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";

export const Quiz = ({ props }) => {
  const { t } = useTranslation();

  const {
    questions,
    chosenOption,
    setChosenOption,
    currentQuestion,
    optionNotChosen,
    setOptionNotChosen,
  } = props;

  function chooseOption({ index, id, text, video }) {
    setOptionNotChosen(false);
    setChosenOption({ index, id, text, video, confirmed: false });
  }
  const currentQuestionText =
    questions?.find((q) => q.id === currentQuestion.id)?.question || "";

  return (
    <>
      <Helmet>
        <title>
          {t("seo_quiz_title", { questionNumber: currentQuestion?.order })}
        </title>
        <meta
          name="description"
          content={t("seo_quiz_description", {
            questionText: currentQuestionText.slice(0, 150),
          })}
        />
        <meta name="keywords" content={t("seo_quiz_keywords")} />
        <meta
          property="og:title"
          content={t("seo_quiz_title", {
            questionNumber: currentQuestion.index,
          })}
        />
        <meta
          property="og:description"
          content={t("seo_quiz_description", {
            questionText: currentQuestionText.slice(0, 150),
          })}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={t("seo_quiz_title", {
            questionNumber: currentQuestion.index,
          })}
        />
        <meta
          name="twitter:description"
          content={t("seo_quiz_description", {
            questionText: currentQuestionText.slice(0, 150),
          })}
        />
      </Helmet>
      <QuizStyle>
        <AnimatePresence mode="wait">
          {questions?.map((q, index) => {
            return (
              currentQuestion.id === q.id && (
                <motion.div
                  key={q.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <div key={q.id} className="question">
                    <div className="questionDiv">
                      <h2 className="index">{q.order + "."}</h2>
                      <h2>{q.question}</h2>
                    </div>
                    <div className="options">
                      {chosenOption.confirmed === true ? (
                        <AnimatePresence mode="wait">
                          <motion.div
                            key={chosenOption.text}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.3 }}
                            className="explanation"
                          >
                            <div
                              style={{
                                padding: "56.25% 0 0 0",
                                position: "relative",
                                borderRadius: "10px",
                              }}
                            >
                              <iframe
                                src={chosenOption?.video}
                                allow="autoplay; fullscreen; picture-in-picture"
                                style={{
                                  border: "0px",
                                  position: "absolute",
                                  top: "0",
                                  left: "0",
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "10px",
                                }}
                                title="Video #1"
                              ></iframe>
                            </div>
                            <script src="https://player.vimeo.com/api/player.js"></script>
                            <div className="text">
                              {/* <h1>Şərh</h1> */}
                              <p>{chosenOption.text}</p>
                            </div>
                          </motion.div>
                        </AnimatePresence>
                      ) : (
                        q.optionnonspeciality_set &&
                        [...q.optionnonspeciality_set].reverse().map((v) => {
                          return (
                            <button
                              key={v.id}
                              className={
                                chosenOption.id === v.id
                                  ? "option choosenOption"
                                  : "option"
                              }
                              onClick={() =>
                                chooseOption({
                                  index: index + 1,
                                  id: v.id,
                                  text: v.answer,
                                  video: v.video,
                                })
                              }
                            >
                              <p className="label">{v.option.toUpperCase()}</p>
                              <p className="optionText">{v.explanation}</p>
                            </button>
                          );
                        })
                      )}
                      {optionNotChosen === true && (
                        <AnimatePresence mode="wait">
                          <motion.div
                            key={chosenOption.text}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.3 }}
                            className="comment"
                          >
                            <h3 style={{ color: "#E67700" }}>Diqqət!</h3>
                            <p>Zəhmət olmasa bir variant seçin</p>
                          </motion.div>
                        </AnimatePresence>
                      )}
                    </div>
                  </div>
                </motion.div>
              )
            );
          })}
        </AnimatePresence>
      </QuizStyle>
    </>
  );
};

const QuizStyle = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  button,
  a {
    margin: 0;
  }
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  .question {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    .questionDiv {
      display: flex;
      grid-gap: 1rem;
      h2 {
        color: #404040;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 120% */
      }
    }
    .options {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1rem;
      width: 100%;
      .comment {
        display: flex;
        flex-direction: column;
        grid-gap: 1rem;
        padding: 1rem;
        border: 1px solid #adb5bd;
        border-radius: 0.25rem;
        p {
          font-size: 17px;
        }
      }
      .explanation {
        display: grid;
        grid-gap: 2rem;
        /* align-items: center; */
        grid-template-columns: 2fr 1.5fr;
        .text {
          padding: 1rem;
          border-radius: 10px;
          background: rgba(255, 255, 255, 0.1);
          box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
        }
        p {
          color: #364152;
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          line-height: 28px; /* 164.706% */
        }
        div {
          display: flex;
          flex-direction: column;
          grid-gap: 1rem;
        }
      }
      .option {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0;
        cursor: pointer;
        background-color: transparent;
        color: #404040;
        border-radius: 10px;
        border: 1px solid rgba(140, 163, 165, 0.35);
        transition: all 0.15s;
        .label {
          margin: 1rem;
          font-size: 17px;
        }
        .optionText {
          margin: 0.75rem 0.75rem 0.75rem 0;
          font-size: 17px;
        }
        &:hover {
          background: linear-gradient(90deg, #2db5c2 0%, #029199 100%);
          color: #fff;
        }
      }
      .choosenOption {
        background: linear-gradient(90deg, #2db5c2 0%, #029199 100%);
        color: #fff;
      }
    }
  }
  @media (max-width: 800px) {
    padding-bottom: 2rem;

    .question {
      .options {
        .explanation {
          grid-template-columns: 1fr;
          border: 0;
          padding: 0;
          .text {
            max-height: initial;
            overflow-x: initial;
            overflow-y: initial;
          }
        }
        .option {
          .optionText {
            display: flex;
            text-align: left;
            justify-content: flex-start;
            align-items: center;
            padding: 0 0.5rem 0 0;
          }
        }
      }
    }
  }
`;
