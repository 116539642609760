import styled from "styled-components";
import { Link } from "react-router-dom";
import { React, useState } from "react";
import { useForm ,Controller} from "react-hook-form";
import axios from '../../../Helpers/Axios'
import {
  Grid,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useMediaQuery } from "@mantine/hooks";
import img from "../../../Assets/SVGs/sign/login-img.svg";
import LoginForm from "../../../Components/Sign/LoginForm";
import Cookies from "universal-cookie";
import responsiveBg from "../../../Assets/SVGs/sign/resSign.svg";
import { useTranslation } from "react-i18next";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { showNotification } from "@mantine/notifications";
import { AlertCircle } from "tabler-icons-react";
import { cookieUtils } from "../../../utils/cookieUtils";

function Login() {
  const matchesSm = useMediaQuery("(max-width: 990px)");
  const [gotTokens, setGotTokens] = useState(false);
  const cookie = new Cookies();
  const {t} = useTranslation()
  const aTokenX = cookie.get("access")
  const rTokenX = cookie.get("refresh")
  
  const { setValue, handleSubmit, formState : {errors} ,control } = useForm();

  const { mutate: loginMutate , isLoading : loginLoading } = useMutation({
    mutationFn: async (data) => {
      const response = await axios.post(`/api/v1/login/`, data);
      return response.data;
    },
    onSuccess: (data) => {
      cookieUtils.setAuthCookies(data.access, data.refresh);
      setGotTokens(true);
    },
    onError: (error) => {
      alert(error)
      if (error.response?.status === 401) {
        // Show your notification here
        showNotification({
          icon: <AlertCircle />,
          title: t('error'),
          message: t('invalid-email-or-password'),
          color: "red",
        });
      }
      
    },
  });

  useQuery(
    ["user-data", gotTokens],
    async () => {
      if (gotTokens) {
        const httpClient = axios.create({
          headers: {
            Authorization: `Bearer ${cookie.get("access")}`,
          },
        });

        return await httpClient
          .post("api/v1/user-data/")
          .then((response) => response.data);
      }
    },
    {
      onSuccess: (data) => {
        
        localStorage.setItem("taskooluser", JSON.stringify(data));
        window.location = "/home"
      },
      onError: (error) => {},
    },
    {
      enabled: !!gotTokens,
    }
  );
  
  const onSubmit = (data) => {
    if(aTokenX && rTokenX){
      cookieUtils.clearAuthCookies();
      window.location = "/login"
    } else {
      loginMutate(data);
    }
  };


  return (
<HelmetProvider >
<LoginDiv>
      <Helmet>
        <title>Login | Taskool</title>
        <meta name="description" content="Login to access your account and enjoy our services." />
        <meta name="robots" content="noindex, nofollow" />
        <link rel="canonical" href="https://www.taskool.com/login" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Login",
              "url": "https://www.taskool.com/login"
            }
          `}
        </script>
      </Helmet>
      <Grid>
        {!matchesSm && (
          <Grid.Col lg={4} md={4} sm={0}>
            <Link to="/">
              <div className="bg-image"></div>
            </Link>
          </Grid.Col>
        )}
    
        {matchesSm && (
          <Grid.Col lg={12} md={12} sm={12}>
            <div className="responsive-bg"></div>
          </Grid.Col>
        )}

        <Grid.Col lg={8} md={8} sm={12}>

          <div className="right-container">
            <Stack ml={10} spacing={10}>
              <Title
                sx={matchesSm ? { textAlign: "center" } : { textAlign: "left" }}
                size={matchesSm ? 24 : 28}
                color="#1A5059"
                weight={700}
              >
                {t('welcome-login')}
              </Title>
              <Text
                color="#757575"
                sx={matchesSm ? { textAlign: "center" } : { textAlign: "left" }}
                size={matchesSm ? 16 : 18}
                weight={300}
              >
                {t('filled')}
              </Text>
            </Stack>

            <div className="form-container">
              <LoginForm
                props={{
                  loginLoading,
                  setValue,
                  handleSubmit,
                  onSubmit,
                  matchesSm,
                  control,
                  errors,
                  Controller
                }}
              />
            </div>
          </div>
        </Grid.Col>
      </Grid>
    </LoginDiv>
</HelmetProvider>
  );
}

export default Login;

const LoginDiv = styled.div`
 overflow-x: hidden;
 overflow-y: hidden;
 max-height:100vh;

  label {
    margin-bottom: 7px;
  }

  .bg-image {
    background-image: url(${img});
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .form-container {
    border: 1px solid #c9c9c9;
    border-radius: 20px;
    padding: 35px 55px 35px 35px;
    margin-top: 20px;
  }

  .right-container {
    padding: 45px 40px;
    min-height: 100vh;
    margin-top: 50px;

    .google-button {
      .mantine-qo1k2.mantine-Button-label {
        font-size: 15px;
        font-weight: 500;
        color: #858585;
      }
    }
  }

  @media (max-width: 990px) {
    overflow-y: scroll;
    .right-container {
      padding: 0px 20px !important;
      margin-top: 10px !important;
      min-height: 85vh;
    }
    .responsive-bg {
      background-image: url(${responsiveBg});
      width: 100%;
      height: 20vh;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .mantine-Grid-col.mantine-cpx4oh {
      display: none !important;
    }

    .bg-image {
      display: none;
    }

    .form-container {
      border: 1px solid #c9c9c9;
      border-radius: 20px;
      padding: 25px 25px 25px 25px;
      margin-top: 20px;
    }
  }
`;
