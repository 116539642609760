import React, { useState, useEffect } from "react";
import CommunityCard from "./CommunityCard";
import {
  Grid,
  Pagination,
  Group,
  Center,
  Stack,
  Text,
  Button,
} from "@mantine/core";
import CreateCommunityCard from "./CreateCommunityCard";
import ModalCreateCommunity from "./modal/ModalCreateCommunity";
import CommunityCardSkeleton from "./skeletons/CommunityCardSkeleton";
import { IconUsers } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@mantine/hooks";

const CommunityList = ({
  user,
  hasActiveFilter,
  hasActiveTab,
  communities,
  isLoading,
  totalPages,
  activePage,
  setActivePage,
}) => {
  const [opened, setOpened] = useState(false);
  const [currentPage, setCurrentPage] = useState(activePage);
  const {t} = useTranslation()
  const size1120 = useMediaQuery('(max-width: 1120px)')
  useEffect(() => {
    setCurrentPage(activePage);
  }, [activePage]);

  if (isLoading) {
    return (
      <Grid>
        {[1, 2, 3, 4, 5, 6].map((index) => (
          <Grid.Col key={index} span={12} sm={6} md={4} lg={4} xl={4}>
            <CommunityCardSkeleton />
          </Grid.Col>
        ))}
      </Grid>
    );
  }

  if (!communities?.results?.length ) {
    if (!hasActiveFilter || hasActiveTab) {
      return (
        <Center style={{ minHeight: "60vh" }}>
          <Stack align="center" spacing="xl">
            <IconUsers size={80} color="#029199" opacity={0.5} />
            <Stack spacing="xs" align="center">
              <Text size="xl" weight={500} color="dimmed">
                {t("no-results-found")}
              </Text>
              <Text color="dimmed" align="center" size="sm">
                {t("try-changing-search-criteria")}
              </Text>
            </Stack>
          </Stack>
        </Center>
      );
    }

    return (
      <>
        <Center style={{ minHeight: "60vh" }}>
          <Stack align="center" spacing="xl">
            <IconUsers size={80} color="#029199" opacity={0.5} />
            <Stack spacing="xs" align="center">
                <Text size="xl" weight={500} color="dimmed">
                {t("no-groups-found")}
              </Text>
              <Text color="dimmed" align="center" size="sm">
               
              {t("create-group-message")}
              </Text>
            </Stack>
            <Button
              onClick={() => setOpened(true)}
              size="md"
              style={{
                backgroundColor: "#029199",
                "&:hover": {
                  backgroundColor: "#027b82",
                },
              }}
            >
              {t("create-group")}
            </Button>
          </Stack>
        </Center>
        <ModalCreateCommunity
          opened={opened}
          onClose={() => setOpened(false)}
        />
      </>
    );
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setActivePage(newPage);
  };

  return (
    <>
      <Grid>
{user &&        <Grid.Col span={size1120? 6  : 4} >
          <CreateCommunityCard setOpened={setOpened} />
        </Grid.Col>}
        {communities?.results?.map((community) => (
          <Grid.Col key={community.id} span={size1120? 6 : 4}>
            <CommunityCard data={community} />
          </Grid.Col>
        ))}
      </Grid>

      {totalPages > 1 && (
        <Group position="center" mt="xl">
          <Pagination
            total={totalPages}
            value={currentPage}
            onChange={handlePageChange}
            siblings={1}
            boundaries={2}
            color="cyan"
            styles={(theme) => ({
              button: {
                backgroundColor: "#029199",
                border: "1px solid #A8AEB4",
                color: "#A8AEB4",
                fontSize: "14px",
                fontWeight: 500,
                height: "32px",
                minWidth: "32px",
                padding: "0 6px",

                "&[data-active]": {
                  backgroundColor: "#029199 !important",
                  borderColor: "#029199",
                  color: theme.white,
                },

                "&:hover": {
                  backgroundColor: "#f1f3f5 !important",
                  borderColor: "#A8AEB4",
                },

                "&[data-active]:hover": {
                  backgroundColor: "#027b82 !important",
                },
              },
            })}
          />
        </Group>
      )}

      <ModalCreateCommunity opened={opened} onClose={() => setOpened(false)} />
    </>
  );
};

export default CommunityList;
