import { Progress, Stack, Text } from "@mantine/core";

export const ProgressBar = ({ props }) => {
  const { length, currentPosition, size } = props;

  let currentPercent = ((currentPosition / length) * 100).toFixed();
  if (currentPosition === length) {
    currentPercent = 100;
  }

  return (
    <Stack spacing={5} style={{borderBottom: "1px solid #ECECEC", paddingBottom: "0.5rem", marginBottom: "1rem"}} >
      <Progress
        styles={(theme) => ({
          bar: {
            background: "linear-gradient(90deg, #2DB5C2 0%, #029199 100%)",
          },
        })}
        value={currentPercent}
        size={size}
        radius="sm"
      />
      <Text align="right" size={16} weight={500} color="#B6B6B6">
        {currentPercent + "%"}
      </Text>
    </Stack>
  );
};
