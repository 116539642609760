import { Grid } from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import PostPanel from "../../features/Community/components/community-panel/post-panel";
import AboutPanel from "../../features/Community/components/community-panel/about-panel";
import { useGetCommunity } from "../../features/Community/hooks/community/useGetCommunity";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  AboutPanelContainer,
  CommunityPanelStyled,
  MainPanelContainer,
} from "../../features/Community/style";
import useGetAllHighligts from "../../features/Community/hooks/highlight/useGetAllHighligts";
import { useGetHighlight } from "../../features/Community/hooks/highlight/useGetHighlight";
import ModalCreateCommunity from "../../features/Community/components/modal/ModalCreateCommunity";
import { useCommunityContext } from "../../features/Community/context/CommunityContext";
import ModalCreateHighlight from "../../features/Community/components/modal/ModalCreateHighlight";
import { AppContext } from "../../Helpers/Context";
import { useMediaQuery } from "@mantine/hooks";
import ManageAccordion from "../../features/Community/components/community-panel/post-panel/ManageAccordion";
import NotificationModal from "../../Components/Modals/NotificationModal";

export const CommunityLayout = ({ children}) => {
  const { id ,highlightId} = useParams();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const status = searchParams.get("status");
  const [modalOpened, setModalOpened] = useState(!!status || false);

  const { data: community, isLoading } = useGetCommunity(id);
  const  {data : highlight} = useGetHighlight(id, highlightId);
  const {openedCommunityEditModal, setOpenedCommunityEditModal ,openedHighlightEditModal, setOpenedHighlightEditModal} = useCommunityContext();
  const xs = useMediaQuery('(max-width: 768px)');
  const lg = useMediaQuery('(max-width: 1120px)');
  const { user } = useContext(AppContext);
  const isOwner = user?.id === community?.author?.id;
  const location = useLocation();
  const isShowManageAccordion = isOwner && xs && !location.pathname.includes('owner');

  const closeModal = () => {
    setModalOpened(false);
    if (status === "Accepted") {
      navigate(`/community/${id}`);
    }
  };

  return (
    <CommunityPanelStyled>
       {status && (
        <NotificationModal
          modalOpened={modalOpened}
          closeModal={closeModal}
          status={status}
        />
      )}
      {isShowManageAccordion && <ManageAccordion />}
      <MainPanelContainer>{children}</MainPanelContainer>
      {!lg && <AboutPanelContainer>
        <AboutPanel highlight={highlight} community={community} isLoading={isLoading} />
      </AboutPanelContainer>}
      <ModalCreateCommunity community_id={id} opened={openedCommunityEditModal} onClose={() => setOpenedCommunityEditModal(false)} />
      <ModalCreateHighlight firstTime={false} highlight_id={highlightId} communityId={id} opened={openedHighlightEditModal} onClose={() => setOpenedHighlightEditModal(false)} />
    </CommunityPanelStyled>
  );
};

const CommunityPanel = ({type}) => {
  const { id } = useParams();
  const { user } = useContext(AppContext);
  const { data: community, isLoading } = useGetCommunity(id);
  const { data: highlights, isLoading: isLoadingHighlights } = useGetAllHighligts(id);
  const { pathname } = useLocation();
  const navigate = useNavigate();


  const isOwner = user?.id === community?.author?.id;
  useEffect(() => {
    if(user && community && isOwner && pathname === `/community/${id}`){
      navigate(`/community/${id}/owner`);
    }
    }, [isOwner, id, pathname, navigate,user,community]);
  return (
    <CommunityLayout >
      <PostPanel
        isOwner={isOwner}
        type="community"
        community={community}
        highlights={highlights}
        isLoading={isLoading}
        isLoadingHighlights={isLoadingHighlights}
      />
    </CommunityLayout>
  );
};

export default CommunityPanel;
