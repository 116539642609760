import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Text, Title } from "@mantine/core";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";

const LandingCard = ({ props }) => {
  const { t, icon, title, text, to, isClicked, isMobile } = props;

  return (
    <Card
      whileHover={!isMobile ? { scale: 1.05, backgroundColor: "#c9f6f9" } : {}}
      animate={
        isClicked ? { scale: 1.05, backgroundColor: "#c9f6f9" } : { scale: 1 }
      }
      initial={{ backgroundColor: "#fff" }}
      transition={{ duration: 1 }}
      $isClicked={isClicked}
      $isMobile={isMobile}
    >
      <motion.div
        whileHover={
          !isMobile ? { scale: 1.05, backgroundColor: "#c9f6f9" } : {}
        }
        animate={isClicked ? { scale: 1.05, backgroundColor: "#c9f6f9" } : {}}
        initial={{ backgroundColor: "#fff" }}
        className="img-con"
      >
        {title && <img src={icon} alt="icon-1" />}
        {!title && (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            dangerouslySetInnerHTML={{ __html: icon }}
          />
        )}
      </motion.div>
      {title && (
        <Title color="#3a3a3a" size={18} weight={700}>
          {t(title)}
        </Title>
      )}
      {!title && (
        <div
          className="text-content steamland"
          dangerouslySetInnerHTML={{ __html: text }}
        />
      )}
      {title && (
        <Text color="#585858" size={16} weight={400} className="text-content">
          {t(text)}
        </Text>
      )}
    </Card>
  );
};

export default LandingCard;

const Card = styled(motion.div)`
  cursor: pointer;
  background-color: #fff;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
  gap: 10px;
  padding: 20px 20px 20px 30px;
  min-height: 210px;
  cursor: pointer;
  @media (max-width: 1280px) {
    padding: 15px 15px 15px 20px;
  }
  @media (max-width: 1024px) {
    padding: 10px 10px 10px 15px;
  }
  @media (max-width: 576px) {
    padding: 15px 15px 15px 20px;
  }

  .img-con {
    width: 44px;
    height: 41px;
    border-radius: 7px;
    background-color: #c9f6f9;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1280px) {
      width: 36px;
      height: 36px;
    }
  }
  img {
    width: 24px;
    height: 24px;
  }
  .text-content {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .steamland {
    color: #575757;
    -webkit-line-clamp: 6;

    h3 {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: -0.48px;
      color: #1a5059 !important;
      @media (max-width: 1280px) {
        font-size: 16px;
      }
        @media (max-width: 1024px) {
        font-size: 15px;
      }
    }
    p {
      margin-top: 10px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.48px;
      color: #575757 !important;
      @media (max-width: 1280px) {
        font-size: 14px;
      }

    }
  }
  ul {
    list-style: disc !important;
    padding: 0;
    margin: 0;
  }

  ${({ $isMobile }) =>
    !$isMobile &&
    `
    &:hover .text-content {
      -webkit-line-clamp: unset;
    }
  `}

  @media (max-width: 576px) {
    width: 70%;
    gap: 15px;
    padding: 20px 20px 28px 30px;

    .text-content {
      -webkit-line-clamp: ${({ $isClicked }) => ($isClicked ? "none" : "4")};
      transition: all 1s ease;
    }
    .steamland {
      -webkit-line-clamp: ${({ $isClicked }) => ($isClicked ? "none" : "6")};
      transition: all 1s ease;
    }
  }
`;
