import React, { useState, useEffect } from 'react';
import { MediaPostStyled } from '../../style';
import player from "../../../../Assets/SVGs/community/post/player.svg";
import axios from 'axios';
import Player from '@vimeo/player';
import { useVimeoVideos } from '../../hooks/post/useVimeoVideos';
import ModalShowPost from '../modal/ModalShowPost';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from "@mantine/hooks";
import { renderText } from './TextPost';

const MediaPost = ({ post }) => {
  const xs = useMediaQuery('(max-width: 768px)');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const {id} = useParams()

  const { text, community_images = [], community_videos = [] } = post;
  const {
    videoThumbnails,
    selectedVideo,
    setSelectedVideo,
    getEmbedUrl,
    extractVimeoId
  } = useVimeoVideos(community_videos);

  // Mediaları birləşdir
  const allMedia = [
    ...community_images.map(img => ({ type: 'image', url: img.image })),
    ...community_videos.map(video => ({
      type: 'video',
      url: video.video_url,
      thumbnailUrl: videoThumbnails[video.video_url]
    }))
  ];

  const handleMediaClick = (media) => {
    setSelectedMedia(media);
    setIsModalOpen(true);
  };

  // Mediaları göstərmək üçün grid yarat
  const renderMediaGrid = () => {
    if (!allMedia.length) return null;

    const totalItems = allMedia.length;
    const gridClassName =
      totalItems === 1
        ? 'single-image'
        : totalItems === 2
        ? 'two-images'
        : 'three-plus-images';

    return (
      <div style={{marginTop: 16}} className={`media-grid ${gridClassName}`}>
        {allMedia.slice(0, Math.min(3, totalItems)).map((media, index) => (
          <div
            key={index}
            className={`media-container ${
              index === 2 && totalItems > 3 ? 'has-overlay' : ''
            }`}
            onClick={() => handleMediaClick(media)}
          >
            {media.type === 'image' ? (
              <img src={media.url} alt={`Media ${index + 1}`} />
            ) : (
              <div 
                className="video-preview"
                onClick={() => setSelectedVideo(media.url)}
              >
                <div className="video-thumbnail">
                  {media.thumbnailUrl ? (
                    <img src={media.thumbnailUrl} alt="Video thumbnail" />
                  ) : (
                    <div className="thumbnail-placeholder" />
                  )}
                  <div className="play-overlay">
                    <img src={player} alt="Play" className="play-icon" />
                  </div>
                </div>
              </div>
            )}
            {index === 2 && totalItems > 3 && (
              <div className="remaining-overlay">
                <span>+{totalItems - 3}</span>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  // Modal içeriğini güncelle
  const renderModal = () => {
    if (!selectedVideo) return null;
    const embedUrl = getEmbedUrl(selectedVideo);
    if (!embedUrl) return null;

    return (
      <div className="video-modal" onClick={() => setSelectedVideo(null)}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <button onClick={() => setSelectedVideo(null)}>Close</button>
          <iframe
            src={embedUrl}
            width="100%"
            height="500"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="Vimeo video player"
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (selectedVideo) {
      const videoId = extractVimeoId(selectedVideo);
      if (!videoId) return;

      // Player yaratmaq üçün düzgün element ID və konfiqurasiya
      const playerElement = document.createElement('div');
      playerElement.id = `vimeo-player-${videoId}`;
      document.body.appendChild(playerElement);

      const player = new Player(playerElement, {
        url: selectedVideo, // video URL-ni birbaşa təqdim edirik
        width: '100%',
        height: 500
      });

      return () => {
        player.destroy();
        playerElement.remove(); // təmizləmə
      };
    }
  }, [selectedVideo]);

  return (
    <MediaPostStyled>
      {renderText(text)}
      {renderMediaGrid()}
      <ModalShowPost
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        post={post}
        community_id={id}
        selectedMedia={selectedMedia}
        onMediaChange={setSelectedMedia}
        type="media"
      />
    </MediaPostStyled>
  );
};

export default MediaPost;
