import {
  Button,
  Divider,
  Navbar,
  ScrollArea,
  Stepper,
  UnstyledButton,
} from "@mantine/core";
import { AnimatePresence, motion } from "framer-motion";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { ChevronsLeft, Circle, CircleCheck } from "tabler-icons-react";

function Title({ props }) {
  const { videos, chosenSection, item } = props;

  const [currentVideoIndex, setCurrentVideoIndex] = useState(1);

  useEffect(() => {
    videos?.map(
      (video, index) =>
        video.id === chosenSection && setCurrentVideoIndex(index + 1)
    );
  }, [chosenSection]);

  return (
    videos?.length > 0 && (
      <motion.div
        className="listTitle"
        initial={item.initial}
        animate={item.animate}
        transition={{ duration: 0.5 }}
      >
        <h2
          style={{
            color: "#364152",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "25px",
          }}
        >
          Videolar
        </h2>
        <div>
          <AnimatePresence mode="wait">
            <motion.div
              key={currentVideoIndex + "x"}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              // exit={{ opacity:1, }}
              transition={{ duration: 0.5 }}
            >
              <h2
                style={{
                  color: "#364152",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "25px",
                }}
                className="index"
              >
                {currentVideoIndex}
              </h2>
            </motion.div>
          </AnimatePresence>
          <h2
            style={{
              color: "#364152",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "25px",
            }}
            className="index"
          >
            /{videos?.length}
          </h2>
        </div>
      </motion.div>
    )
  );
}

function Motopa(props) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      // exit={{ opacity:0 }}
      transition={{ duration: 0.66, delay: 0.5 }}
    >
      {props.children}
    </motion.div>
  );
}

export const VideoList = ({ props }) => {
  const { videos, chosenSection, setChosenSection, mini } = props;

  const container = {
    initial: {
      // opacity: 0,
      scale: 0,
    },
    animate: {
      // opacity: 1,
      scale: 1,
      transition: {
        staggerChildren: 0.2,
        duration: 0.25,
      },
    },
  };

  const item = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
  };

  function chooseVideo(id) {
    setChosenSection(id);
  }

  return videos?.length > 0 && mini === false ? (
    <motion.div
      initial={{ opacity: 0, x: 400 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 400 }}
      transition={{ duration: 0.5 }}
    >
      <ListStyle>
        <Navbar p={"md"} withBorder={false} styles={(theme) => ({})}>
          <Title props={{ videos, chosenSection, item }} />

          <Motopa>
            <Divider my="sm" color="#ffffff22" />
          </Motopa>

          <Navbar.Section
            grow
            component={ScrollArea}
            type="always"
            styles={(theme) => ({
              scrollbar: {
                "&, &:hover": {
                  background: "transparent",
                },
                '&[data-orientation="horizontal"]': {
                  display: "none",
                },
                '&[data-orientation="vertical"] .mantine-ScrollArea-thumb': {
                  backgroundColor: "#029199",
                },
              },
            })}
          >
            <Stepper
              active={chosenSection-1}
              onStepClick={(e) => setChosenSection(e+1)}
              orientation="vertical"
              styles={(theme) => ({

                stepIcon: {
                  borderWidth: 2,
                  backgroundColor: "white",
                  "&[data-completed]": {
                    backgroundColor: "#029199",
                    borderColor: "#029199",
                  },
                  "&[data-progress]": {
                    borderColor: "#029199",
                  },
                },
              })}
            >
              {videos.map((video) => (
                <Stepper.Step
                  onClick={() => chooseVideo(video.id)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "95%",
                    padding: "0px 10px",
                    borderRadius: chosenSection === video.id ? "10px" : "0",
                    background:
                      chosenSection === video.id
                        ? "rgba(255, 255, 255, 0.10)"
                        : "transparent",
                    boxShadow:
                      chosenSection === video.id
                        ? "0px 4px 15px 0px rgba(0, 0, 0, 0.10)"
                        : "none",
                    cursor: "pointer",
                    "&:hover": {
                      borderRadius: "10px",
                      background: "rgba(255, 255, 255, 0.10)",
                      boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.10)",
                    },
                  }}
                  key={video.id}
                  label={video.question}
                />
              ))}
            </Stepper>

           
          </Navbar.Section>

          <Motopa>
            <Divider my="sm" color="#ffffff22" />
          </Motopa>

          <Navbar.Section>
            <Motopa>
              <Button
                className="exit"
                mb={30}
                component={Link}
                to="/tip"
                leftIcon={<ChevronsLeft color="#fff" />}
                fullWidth
                variant="gradient"
                gradient={{ from: "#00BD90", to: "#009D78", deg: 90 }}
                sx={{
                  height: "48px",
                  borderRadius: "8px",
                  fontSize: "15px",
                  fontWeight: 500,
                  "&:hover": {
                    background: "linear-gradient(90deg, #009D78, #00BD90)",
                  },
                }}
              >
                Çıxış
              </Button>
            </Motopa>
          </Navbar.Section>
        </Navbar>
      </ListStyle>
    </motion.div>
  ) :null;
};

const ListStyle = styled.div`
  /* margin: 1rem; */
  h1,
  h2,
  h3,
  button,
  a {
    font-weight: 500;
  }
  a {
    font-weight: 500;
    font-size: 17px;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  .check{
    position:"absolute",
    bottom:"0",
    left:"6px",
  }
  .listTitle {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    h2 {
      padding: 0;
      margin: 0;
    }
    div {
      display: flex;
      align-items: center;
      .index {
        color: #ccc;
      }
    }
  }
  .sectionList {

    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    padding: 0;
    margin: 0;
    margin-right: 1rem;
    li {
      button {

        cursor: pointer;
        padding: 1.2rem 0.75rem;
        width: 100%;
        border: 1px solid transparent;
        transition: all 0.15s;
        color: #364152;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px; /* 156.25% */
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.10);
        &:hover {
          /* border: 1px solid #ddd; */
          box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.10);

        }

      }
    }
    .nope {
      display: grid;
      grid-template-columns: 1fr 10fr;
      grid-gap: 0.5rem;
      align-items: center;
      justify-content: space-between;
    }
    .chosenVideo {
      background-color: #ffffff33;
      border: 1px solid #ffffff55;
      box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.10);

    }
  }
  @media (max-width: 800px) {
    .sectionList {
      display: flex;
      flex-direction: column;
      grid-gap: 0.5rem;
      padding: 0;
      margin: 0 1rem 2rem 1rem;
      .chosenVideo {
        background-color: #343a40;
        border: 1px solid #868e96;
      }
      li {
        button {
          background-color: #212529;
        }
      }
    }
    .exit {
      width: 92%;
      margin: 0 auto 2rem auto;
    }
  }
`;
