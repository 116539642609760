import React, { useEffect, useRef } from "react";
import { Accordion } from "@mantine/core";
import styled from "styled-components";

const FilterSelect = ({ props, children }) => {
  const { currentValue, className, setSearchValue, searchValue, accordionValue, setAccordionValue,value } = props;
  const accordionRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      accordionRef.current &&
      !accordionRef.current.contains(event.target) &&
      !event.target.classList.contains('filter-search-input') &&
      !event.target.closest('.filter-checkbox') && 
      !event.target.tagName === "svg"
    ) {
      setAccordionValue(null);
      setSearchValue(""); // Reset filter value when closing the accordion
    }
  };

  useEffect(() => {
    if (accordionValue) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [accordionValue]);

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleAccordion = (e) => {
    setAccordionValue(e === accordionValue ? null : e);
    setSearchValue(""); // Reset filter value when opening the accordion
  };


  return (
    <FilterSelectContainer currentValue={currentValue} value={accordionValue} ref={accordionRef}>
      <Accordion
        variant="separated"
        value={accordionValue}
        onChange={handleAccordion}
      >
        <Accordion.Item className={className} value={value}>
          <Accordion.Control>
            {accordionValue === value ? (
              <input
                type="text"
                placeholder={currentValue}
                value={searchValue}
                onChange={handleInputChange}
                autoFocus
                className="filter-search-input"
              />
            ) : (
              currentValue
            )}
          </Accordion.Control>

          <Accordion.Panel className={className}>
            {children}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </FilterSelectContainer>
  );
};

export default FilterSelect;

const FilterSelectContainer = styled.div`

.filter-search-input{
  border-radius: 4px;
  width: 100%;
  background-color : transparent;
  border : none;
  outline : none;
  font-size : 17px;
}
  .mantine-Accordion-item.mantine-uarb1j {
    border: 1px solid #c6c6c6;
    margin-bottom: 15px;
    @media (max-width: 768px) {
      border: none;
    }
  }

  .mantine-Accordion-panel.price.mantine-a2hpw8,
  .mantine-Accordion-panel.degree.mantine-a2hpw8,
  .mantine-Accordion-panel.speciality.mantine-a2hpw8,
  .mantine-Accordion-panel.country.mantine-a2hpw8,
  .mantine-Accordion-panel.region.mantine-a2hpw8 {
    box-shadow: 0px 4px 12px 0px #0000001f;
    border-radius: 12px;
    width: 95%;
    margin: 10px auto 0 auto;
    max-height: 150px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: #002e317a;
      border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #002e317a;
    }
  }
  .mantine-Accordion-item.country.mantine-uarb1j {
    button {
      background-color: ${(props) =>
        props.value === "country" ? "#029199" : "#fff"};
      color: ${(props) => (props.value === "country" ? "#fff" : "#029199")};
    }
    input {
      color: ${(props) => (props.value === "country" ? "#fff" : "#029199")};
    }
    border: 0 !important;
  }
  .mantine-Accordion-item.degree.mantine-uarb1j {
    button {
      background-color: ${(props) =>
        props.value === "country" ? "#029199" : "#fff"};
      color: ${(props) => (props.value === "country" ? "#fff" : "#029199")};
    }
    input {
      color: ${(props) => (props.value === "country" ? "#fff" : "#029199")};
    }
    border: 0 !important;
  }
  .mantine-Accordion-item.region.mantine-uarb1j {
    button {
      background-color: ${(props) =>
        props.value === "region" ? "#029199" : "#fff"};
      color: ${(props) => (props.value === "region" ? "#fff" : "#029199")};
    }
    border: 0 !important;

  }
  .mantine-Accordion-item.speciality.mantine-uarb1j {
    button {
      background-color: ${(props) =>
        props.value === "speciality" ? "#029199" : "#fff"};
      color: ${(props) => (props.value === "speciality" ? "#fff" : "#029199")};
    }
    border: 0 !important;

  }
  .mantine-Accordion-item.price.mantine-uarb1j {
    button {
      background-color: ${(props) =>
        props.value === "price" ? "#029199" : "#fff"};
      color: ${(props) => (props.value === "price" ? "#fff" : "#029199")};
    }
    border: 0 !important;
  }
  .mantine-Accordion-item.price.mantine-uarb1j,
  .mantine-Accordion-item.speciality.mantine-uarb1j,
  .mantine-Accordion-item.degree.mantine-uarb1j,
  .mantine-Accordion-item.country.mantine-uarb1j,
  .mantine-Accordion-item.region.mantine-uarb1j {
    border-radius: 26px;
    .mantine-pildck.mantine-Accordion-label {
      text-transform: capitalize;
      font-size: 17px;
      weight: 400;
    }
    .mantine-1h6pkea.mantine-Accordion-chevron {
      svg {
        width: 18px;
        height: 18px;
      }
    }
    
    button {
      border-radius: 29px;
      padding: 14px 24px;
      padding-left: 26px;
      border: 1px solid #8dcdd1;

      transition: all 0.2 ease-in-out;
      &:hover {
        transition: all 0.2 ease-in-out;
        background: #029199;
        color: #fff;
        input{
          transition: all 0.2 ease-in-out;
          background: #029199;
          color: #fff;
        }
        input::placeholder{
          background: #029199;
          color: #fff;
        }
      }
    }
  }

  .filter-checkbox {
    display: flex;
    align-items: center;
    gap: 10px;
    label {
      color: #848484;
      font-size: 14px;
      font-weight: 400;
    }
  }
`;
