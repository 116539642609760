import React from "react";
import PostLayout from "./PostLayout";
import { Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import DOMPurify from 'dompurify';

export const renderText = (text) => {
  const textWithHashtags = text.replace(
    /#(\w+)/g, 
    '<strong>#$1</strong>'
  );
  
  const cleanHtml = DOMPurify.sanitize(textWithHashtags, {
    ALLOWED_TAGS: ['strong', 'p', 'br', 'div', 'span'],
    ALLOWED_ATTR: ['style', 'class']
  });
  
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: cleanHtml
      }}
    />
  );
};

const TextPost = ({ post }) => {
  const xs = useMediaQuery('(max-width: 768px)');
  


  return (
    <Text size={xs ? 13 : 16} weight={400} color="202020" lh={xs ? "20px" : "28px"}>
      {renderText(post.text)}
    </Text>
  );
};

export default TextPost;
