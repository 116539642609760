import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAllCategories } from "../../../hooks/category/useGetAllCategories";
import { useGetAllCollaboratorList } from "../../../hooks/badges/useGetAllCollaboratorList";
import {
  CommunityHeaderStyled,
  ResponsiveCommunityHeaderStyled,
} from "../../../style";
import { Accordion, Group, Skeleton, Stack, Text } from "@mantine/core";
import {
  IconBell,
  IconChevronDown,
  IconEdit,
  IconShare3,
} from "@tabler/icons-react";
import { AboutPanelStyled } from "../about-panel";
import AboutSection from "../about-panel/components/AboutSection";
import CategorySection from "../about-panel/components/CategorySection";
import MembersSection from "../about-panel/components/MembersSection";
import CreatorSection from "../about-panel/components/CreatorSection";
import CollaboratorSection from "../about-panel/components/CollaboratorSection";
import SavedPostsSection from "../about-panel/components/SavedPostsSection";
import ShareDropdown from "./ShareDropdown";
import Button from "../../../../../Components/FormComponent/Button";
import { AppContext } from "../../../../../Helpers/Context";
import { useCommunityContext } from "../../../context/CommunityContext";
import { useTranslation } from "react-i18next";
export const ResponsiveCommunityHeader = ({
  type,
  highlight,
  community,
  isLoading,
  setPostTab,
  route,
}) => {
  const { t } = useTranslation();
  const data = type === "highlight" ? highlight[0] : community;
  const { id, highlight_id } = useParams();
  const { user } = useContext(AppContext);
  const navigate = useNavigate();
  const { setCommunityData, setCommunityId,setOpenedHighlightEditModal,setHighlightId } = useCommunityContext();
  const [showShareDropdown, setShowShareDropdown] = useState(false);

  
  const { data: categories } = useGetAllCategories();
  const { data: collaborators } = useGetAllCollaboratorList(id);
  
  const image =
    type === "highlight"
      ? `https://taskilled.blob.core.windows.net/taskool-store/${highlight[0]?.image}`
      : community?.cover_photo_url;


  const category  = categories && community ? categories[community.category] : null;
  const is_owner = data?.author?.id === user?.id;
  
  const getShareUrl = () => {
    const baseUrl = "https://taskool.com";
    if (type === "highlight") {
      return `${baseUrl}/community/${id}/highlight/${highlight_id}`;
    }
    return `${baseUrl}/community/${id}`;
  };
  const handleEditBanner = () => {
    if(type === 'community') {

    const existData = {
      cover_photo: data.cover_photo,
      category: data.category,
      name: data.name,
      description: data.description,
      lang_id: data.lang_id,
      price: data.price,
      is_free: data.is_free,
    };
    setCommunityData(existData);
    setCommunityId(id);
    localStorage.setItem("communityData", JSON.stringify(existData));
    localStorage.setItem("updated_community_id", id);
    navigate(`/categories/${data.category}/cover-photo/edit`);
  } else {
    setOpenedHighlightEditModal(true);
    setHighlightId(highlight_id);
    localStorage.setItem("updated_highlight_id", highlight_id);
  }


  };
  if (isLoading) return <ResponsiveCommunityHeaderSkeleton />;

  return (
    <ResponsiveCommunityHeaderStyled>
      <div className="top-side">
        <img src={image} alt="community-cover" />
        {is_owner && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "30px",
              height: "30px",
              borderRadius: "50px",
              boxShadow: "0 0 0 0.5em 0",
              position: "absolute",
              bottom: "10px",
              right: "10px",
              background: "#FFF",
            }}
          >
            <IconEdit onClick={handleEditBanner} size={20} color="#ECA200" />
          </div>
        )}
      </div>

      <Accordion
        styles={{
          item: {
            backgroundColor: "#fff",
            "&:hover": {
              backgroundColor: "#fff",
            },
          },
        }}
      >
        <Accordion.Item value="community-info">
          <Accordion.Control
            chevron={<IconChevronDown size={16} />}
            className="community-header-accordion-control"
            styles={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Stack>
              <Text
               style={{ 
                lineHeight: "31px",
                maxWidth: "100%",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
                wordBreak: "break-word"
              }}
              size="17px" weight={600} color="#202020">
                {data?.name}
              </Text>
              <div
                style={{
                  width: "100%",
                  height: "1px",
                  backgroundColor: "#e4e4e4",
                }}
              ></div>
              <Group spacing={10}>
                <div
                  style={{
                    color: "#364152",
                    cursor: "pointer",
                    paddingRight: "10px",
                  }}
                >
                  {(type === "community" && community?.is_free) ||
                  type === "highlight"
                    ? t("free")
                    : `${t("Paid")} ${community?.price}₼`}
                </div>

                <div
                  onClick={() => setShowShareDropdown(true)}
                  style={{
                    padding: "10px 29px",
                    backgroundColor: "#eee",
                    borderRadius: "24px",
                    color: "#202020",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <IconShare3 size={20} />
                  {t("share")}
                </div>
              </Group>
            </Stack>
          </Accordion.Control>
          <Accordion.Panel>
            <AboutPanelStyled>
              <AboutSection isLoading={isLoading} data={data} />
              <CategorySection isLoading={isLoading} category={category} />
              <MembersSection isLoading={isLoading} data={data} />
              <CreatorSection isLoading={isLoading} data={data} />
              {collaborators && collaborators?.result?.length > 0 && <CollaboratorSection
                isLoading={isLoading}
                collaborators={collaborators}
              />}
              <SavedPostsSection id={id} />
            </AboutPanelStyled>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>

      <ShareDropdown
        isOpen={showShareDropdown}
        onClose={() => setShowShareDropdown(false)}
        url={getShareUrl()}
      />
    </ResponsiveCommunityHeaderStyled>
  );
};

const ResponsiveCommunityHeaderSkeleton = () => {
  return (
    <CommunityHeaderStyled>
      <div className="top-side">
        <Skeleton height={160} radius="md" />
      </div>
      <Accordion mt={60}>
        <Accordion.Item value="community-info">
          <Accordion.Control>
            <Stack spacing="md">
              <Skeleton height={24} width="60%" />
              <div
                style={{
                  width: "100%",
                  height: "1px",
                  backgroundColor: "#e4e4e4",
                }}
              />
              <Group spacing={10}>
                <Skeleton height={40} width={100} />
                <Skeleton height={40} width={40} circle />
                <Skeleton height={40} width={120} />
              </Group>
            </Stack>
          </Accordion.Control>
        </Accordion.Item>
      </Accordion>
    </CommunityHeaderStyled>
  );
};
