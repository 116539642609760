import React, { useEffect, useState } from "react";
import { ViewContainer } from ".";
import CustomAccordion from "../../../Components/Profile/CustomAccordion";
import SkillEndorsementForm from "./ProfileForms/SkillEndorsementForm";
import EndorsementView from "../../../Components/Profile/EndorsementView";
import { Group, Stack } from "@mantine/core";
const SikllEndorsement = ({
  t,
  user,
  userSkills,
  selectDatas,
  handleSkillHeaderId,
  handleCreateUserSkill,
  handleDeleteUserSkill,
  publicUser
}) => {
  const [isCreateFormActive, setIsCreateFormActive] = useState(false);
  const [isEditFormActive, setIsEditFormActive] = useState(false);
  const [showIcons, setShowIcons] = useState(false);
  const [selectedEndorsementId, setSelectedEndorsementId] = useState();
  const [existEndorsements, setExistEndorsements] = useState(null)
  useEffect(() => {
    if(selectedEndorsementId){
     const filteredEndorsement = userSkills.find((skill) => {
       return skill.id === selectedEndorsementId;
     })
     setExistEndorsements(filteredEndorsement)
    }
  },[selectedEndorsementId,userSkills])
  return (
    <ViewContainer>
      <div style={{ padding: "10px 30px 10px 20px" }}>
        <CustomAccordion
          defaultValue="endorsement"
          value="endorsement"
          data={userSkills}
          title={t('skill-endorsement')}
          handleActiveCreateForm={setIsCreateFormActive}
          isCreateFormActive={isCreateFormActive}
          handleActiveIcons={setShowIcons}
          isShowIcons={showIcons}
          handleSkillHeaderId={handleSkillHeaderId}
          handleId={setSelectedEndorsementId}
          isPublic = {publicUser ? true : false}

        >
          {isCreateFormActive || isEditFormActive ? (
            <SkillEndorsementForm
              t={t}
              handleCreateUserSkill={handleCreateUserSkill}
              user={user}
              selectDatas={selectDatas}
              handleActiveCreateForm={setIsCreateFormActive}
              handleActiveEditForm={setIsEditFormActive}
              existEndorsement = {existEndorsements}
              formType={isCreateFormActive ? "create" : "edit"}
              endorsementId= {selectedEndorsementId}
              handleActiveIcons={setShowIcons}


            />
          ) : (
            <Stack spacing={10}>
              {userSkills?.map((userSkill, index) => (
                  <EndorsementView
                    key={userSkill?.id}
                    t={t}
                    handleDataId={setSelectedEndorsementId}
                    handleActiveEditForm={setIsEditFormActive}
                    handleDeleteUserSkill={handleDeleteUserSkill}
                    isShowIcons={showIcons}
                    data={userSkill}
                    type="endorsement"
                  />
              ))}
            </Stack>
          )}
        </CustomAccordion>
      </div>
    </ViewContainer>
  );
};

export default SikllEndorsement;
