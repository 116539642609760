import React from "react";
import { AppShell, Stack, Text } from "@mantine/core";
import { motion } from "framer-motion";
import { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { NavButtons } from "./NavButtons";
import { ProgressBar } from "../../../Components/TIP/ProgressBar";
import { Quiz } from "./Quiz";
import { QuizFinished } from "../Results/QuizFinished";
import { LoaderResults } from "../Results/LoaderResults";
import { useQuery } from "@tanstack/react-query";
import axios from "../../../Helpers/Axios";
import { PickGender } from "./PickGender";
import { AppContext } from "../../../Helpers/Context";
import { useMediaQuery } from "@mantine/hooks";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { IconChevronLeft } from "@tabler/icons-react";

export const Profession = () => {
  const { t } = useTranslation();
  const { user } = useContext(AppContext);

  const { data: programPurchased } = useQuery(
    ["program is purchased"],
    async () =>
      await axios
        .get("/forum/success_order/")
        .then((res) => res.data.successfuly_paid)
  );

  let location = useLocation();

  const { data: questions, refetch } = useQuery(
    ["spec questions"],
    async () => {
      return await axios
        .get("/forum/specialty/multidata/")
        .then((res) => res.data.QuestionSpeciality);
    },
    {
      onSuccess: (data) => {
        setCurrentQuestion({
          order: data[0]?.order,
          index: 0,
          id: data[0]?.id,
        });
        setPrevQuestion({ order: data[0]?.order, index: 0, id: data[0]?.id });
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [location.pathname]);

  const [currentQuestion, setCurrentQuestion] = useState({
    order: 1,
    index: 0,
    id: 0,
  });
  const [prevQuestion, setPrevQuestion] = useState({
    order: 1,
    index: 0,
    id: 0,
  });

  const [chosenOptions, setChosenOptions] = useState({ id: [] });
  const [pendingOptions, setPendingOptions] = useState({ id: [] });

  const [haveBackedOnce, setHaveBackedOnce] = useState(false);
  const [quizFinished, setQuizFinished] = useState(false);
  const [timePassed, setTimePassed] = useState(false);

  function chooseOption({ id, multi }) {
    if (haveBackedOnce) {
      if (pendingOptions.id.includes(id)) {
        let newIds = pendingOptions.id.filter((e) => e !== id && e);
        setPendingOptions((e) => ({ id: newIds }));
      }

      if (!pendingOptions.id.includes(id)) {
        if (multi) {
          setPendingOptions((e) => ({ id: [...e.id, id] }));
        } else {
          setPendingOptions((e) => ({ id: [id] }));
        }
      }
    } else {
      if (chosenOptions.id.includes(id)) {
        let newIds = chosenOptions.id.filter((e) => e !== id && e);
        setChosenOptions((e) => ({ id: newIds }));
      }

      if (!chosenOptions.id.includes(id)) {
        if (multi) {
          setChosenOptions((e) => ({ id: [...e.id, id] }));
        } else {
          setChosenOptions((e) => ({ id: [id] }));
        }
      }
    }
  }

  function finishQuiz() {
    setPendingOptions({ ...chosenOptions });
    setHaveBackedOnce(false);

    if (!haveBackedOnce && pendingOptions.id.length > 0) {
      sendConfirmed();
    }

    setQuizFinished(true);
  }

  let isFirstQuestion =
    currentQuestion.id === (questions?.length > 0 && questions[0]?.id);
  let isLastQuestion =
    currentQuestion.id ===
    (questions?.length > 0 && questions[questions.length - 1]?.id);

  function sendConfirmed() {
    // Post singlechoice
    if (pendingOptions?.id?.length === 1) {
      postOption(user.id, prevQuestion.id, pendingOptions?.id[0]);
    }

    // Post multichoice
    if (pendingOptions?.id?.length > 1) {
      pendingOptions?.id?.forEach((id) =>
        postOption(user.id, prevQuestion.id, id)
      );
    }

    if (isLastQuestion) {
      if (chosenOptions?.id?.length === 1) {
        postOption(user.id, currentQuestion.id, chosenOptions?.id[0]);
      }

      // Post multichoice
      if (chosenOptions?.id?.length > 1) {
        chosenOptions?.id?.forEach((id) =>
          postOption(user.id, currentQuestion.id, id)
        );
      }
    }
  }

  function changeQuestion({ to }) {
    if (to === "prev" && !isFirstQuestion) {
      setCurrentQuestion((e) => ({
        index: e.index - 1,
        id: questions[e.index - 1]?.id,
        order: questions[e.index - 1]?.order,
      }));
      setPrevQuestion((e) => ({
        index: e.index,
        id: questions[e.index - 1]?.id,
        order: questions[e.index - 1]?.order,
      }));
      setChosenOptions({ id: [] });
      setHaveBackedOnce(true);
    }

    if (to === "next" && !isLastQuestion) {
      if (haveBackedOnce && chosenOptions.id.length !== 0) {
        setPendingOptions({ ...chosenOptions });
      }

      if (chosenOptions.id.length !== 0) {
        setPendingOptions({ ...chosenOptions });
      }

      setHaveBackedOnce(false);
      setChosenOptions({ id: [] });
      setCurrentQuestion((e) => ({
        index: e.index + 1,
        id: questions[e.index + 1]?.id,
        order: questions[e.index + 1]?.order,
      }));

      if (!haveBackedOnce) {
        setPrevQuestion((e) => ({
          index: e.index + 1,
          id: questions[e.index]?.id,
          order: questions[e.index]?.order,
        }));
      }

      if (!haveBackedOnce && pendingOptions.id.length > 0) {
        sendConfirmed();
      }
    }
  }

  async function postOption(user, question, option) {
    axios.post("/forum/specialty/userchooseoptioncreate/", {
      user: user,
      question: question,
      option: option,
    });
  }

  const matches = useMediaQuery("(max-width: 800px)");

  return (
    <>
      <Helmet>
        <title>{t("seo_tip_profession_title")}</title>
        <meta
          name="description"
          content={t("seo_tip_profession_description")}
        />
        <meta name="keywords" content={t("seo_tip_profession_keywords")} />
        <meta property="og:title" content={t("seo_tip_profession_title")} />
        <meta
          property="og:description"
          content={t("seo_tip_profession_description")}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={t("seo_tip_profession_title")} />
        <meta
          name="twitter:description"
          content={t("seo_tip_profession_description")}
        />
      </Helmet>

      <AppShell
        padding={0}
        styles={{
          backgroundColor: currentQuestion?.id === 41 ? "#FFF" : "#EFF3F4",
          position: "relative",
        }}
      >
        {programPurchased &&
        questions?.length > 0 &&
        !quizFinished &&
        currentQuestion?.id !== 0 ? (
          <ProfessionStyle currentQuestion={currentQuestion}>
            <motion.div
              key="speciality"
              initial={{ opacity: 0, y: -500 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -500 }}
              transition={{ duration: 0.5 }}
              className="specialityWrapper"
            >
              {currentQuestion.id === 41 ? (
                <PickGender
                  props={{
                    finishQuiz,
                    chooseOption,
                    chosenOptions,
                    pendingOptions,
                  }}
                />
              ) : (
                <>
                  {!matches && (
                    <Stack justify="center" align="center" spacing={10}>
                      <Link
                        style={{
                          position: "absolute",
                          top: "10rem",
                          left: "4rem",

                          color: "#000",

                          fontWeight: 500,
                        }}
                        to="/tip/vision"
                      >
                        <IconChevronLeft size={28} color="#202224" />
                      </Link>

                      <Text
                        component="h1"
                        color="#202224"
                        size={32}
                        weight={600}
                      >
                        TİP - ə Xoşgəlmisiniz!
                      </Text>
                      <Text
                        mb={10}
                        component="p"
                        color="#979797"
                        size={18}
                        weight={500}
                      >
                        Başlamaq üçün 40 addımı tamamlayın
                      </Text>
                      <Text
                        component="span"
                        color="#08959E"
                        size={18}
                        weight={500}
                      >
                        Sual: {(currentQuestion?.order) + "/" + 40}
                      </Text>
                    </Stack>
                  )}

                  <div
                    style={{
                      borderRadius: 10,
                      background: "#FFF",
                      boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.10)",
                      padding: "2rem",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <ProgressBar
                        props={{
                          length: 40,
                          currentPosition:
                            41 - questions?.length + currentQuestion.index,
                          color: "teal.8",
                          size: "md",
                        }}
                      />
                      <Quiz
                        props={{
                          chooseOption,
                          prevQuestion,
                          setPrevQuestion,
                          haveBackedOnce,
                          questions,
                          currentQuestion,
                          chosenOptions,
                          setChosenOptions,
                          pendingOptions,
                          setPendingOptions,
                        }}
                      />
                    </div>
                  </div>
                  <NavButtons
                    props={{
                      chosenOptions,
                      haveBackedOnce,
                      currentQuestion,
                      finishQuiz,
                      changeQuestion,
                      isFirstQuestion,
                      isLastQuestion,
                    }}
                  />
                </>
              )}
            </motion.div>
          </ProfessionStyle>
        ) : (
          quizFinished === true &&
          timePassed === false && <LoaderResults props={{ setTimePassed }} />
        )}
        {timePassed && <QuizFinished props={{ timePassed }} />}
      </AppShell>
    </>
  );
};

const ProfessionStyle = styled.div`
  background-color: ${(props) => props.currentQuestion?.id === 41 ? "#FFF" : "#eff3f4"};
  h1,
  h2,
  h3,
  a,
  button {
    font-weight: 500;
  }
  overflow: hidden;
  padding-top: 3.5rem;
  padding-bottom: 5rem;
  .exit {
    max-width: max-content;
    padding: 0 5rem;
    margin: 0 auto;
    margin-top: -3rem;
    border: 3px solid #495057;
  }
  .specialityWrapper {
    display: flex;
    flex-direction: column;
    max-width: 70%;
    grid-gap: 2rem;
    border-radius: 0.25rem;
    margin: 0 auto;
  }
  @media (max-width: 800px) {
    padding: 0;
    .specialityWrapper {
      max-width: 100%;
      padding: 1rem;
      border-radius: 0;
    }
  }
`;
