import { Grid, TextInput, ActionIcon } from "@mantine/core";
import { IconAdjustments, IconSearch } from "@tabler/icons-react";
import React, { useState, useRef, useEffect } from "react";
import DropDownCategory from "./category/DropDownCategory";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const CommunityFilter = ({queryfilterParams, setQueryfilterParams}) => {
  const {t} = useTranslation()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [searchTimeout, setSearchTimeout] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);


  const handleSearch = (e) => {
    if (searchTimeout) clearTimeout(searchTimeout);
    const newTimeout = setTimeout(() => {
      setQueryfilterParams({...queryfilterParams, search: e.target.value});
    }, 500);
    setSearchTimeout(newTimeout);
  };

  const handleCategory = (category) => {
    setQueryfilterParams({...queryfilterParams, category});
    setIsDropdownOpen(false);
  };

  return (
    <GridContainer align="center">
          <TextInput
            onChange={handleSearch}
          placeholder={t("search")}
          rightSection={<IconSearch size="1rem" color="#757575" />}
          radius={10}
          styles={{
            input: {
              padding: "20px 16px",
            },
          }}
        />
        <div style={{ position: "relative" }} ref={dropdownRef}>
          <ActionIcon
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            style={{
              backgroundColor: "#0291991A",
              border: "1px solid #029199",
              width: "50px",
              height: "41px",
            }}
            variant="light"
            color="#029199"
            radius={10}
          >
            <IconAdjustments
              color="#029199"
              style={{ transform: "rotate(90deg)" }}
              size="1.2rem"
            />
          </ActionIcon>
          {isDropdownOpen && (
              <DropDownCategory handleCategory={handleCategory} onClose={() => setIsDropdownOpen(false)} />
          )}
        </div>
    </GridContainer>
  );
};

export default CommunityFilter;

const GridContainer = styled.div`
display : grid;
grid-template-columns: 1fr auto;
gap: 10px;
`;
