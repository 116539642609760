import { motion } from 'framer-motion';
export const AnimatedSVG = () => {
  return (
    <motion.svg width="361" height="324" viewBox="0 0 361 324" fill="none" xmlns="http://www.w3.org/2000/svg">
      <motion.g clipPath="url(#clip0_598_840)">
        <motion.path
          opacity="0.25"
          d="M252.09 312.848C252.09 314.139 204.441 315.196 145.761 315.196C87.0812 315.196 39.433 314.139 39.433 312.848C39.433 311.557 87.0812 310.5 145.761 310.5C204.441 310.5 252.09 311.557 252.09 312.848Z"
          fill="#606060"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
        />
        <motion.path
          d="M219.464 102.247C269.224 160.473 279.669 228.09 218.407 285.73C170.055 331.043 86.8466 316.369 36.9685 258.143C-12.7922 199.917 -12.3228 117.743 38.0248 74.66C88.4896 31.5774 169.703 43.9035 219.464 102.247Z"
          fill="#991740"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5, delay: 0.5 }}
        />
        <motion.path
          d="M225.199 275.368C275.563 232.325 276.524 150.76 227.346 93.1855C178.168 35.6115 97.473 23.8312 47.1091 66.8735C-3.25484 109.916 -4.21606 191.482 44.9622 249.056C94.1404 306.63 174.835 318.41 225.199 275.368Z"
          fill="#FF1C4F"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1 }}
        />
        <motion.path
          d="M212.01 259.925C254.933 223.242 255.767 153.745 213.873 104.698C171.979 55.652 103.221 45.6294 60.2984 82.3122C17.3757 118.995 16.5418 188.492 58.436 237.539C100.33 286.585 169.088 296.608 212.01 259.925Z"
          fill="white"
          initial={{ x: -100 }}
          animate={{ x: 0 }}
          transition={{ duration: 1 }}
        />
        <motion.path
          d="M199.965 245.823C236.087 214.952 236.801 156.48 201.559 115.222C166.318 73.9635 108.466 65.543 72.3438 96.4139C36.2217 127.285 35.5079 185.757 70.7495 227.015C105.991 268.273 163.843 276.694 199.965 245.823Z"
          fill="#FF1C4F"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
        />
        <motion.path
          d="M187.386 231.097C216.362 206.333 216.915 159.404 188.621 126.28C160.327 93.1547 113.899 86.377 84.9229 111.141C55.9464 135.905 55.3934 182.833 83.6877 215.958C111.982 249.083 158.409 255.861 187.386 231.097Z"
          fill="white"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1 }}
        />
        <motion.path
          d="M174.73 216.282C196.561 197.625 196.987 162.28 175.682 137.338C154.378 112.396 119.409 107.301 97.578 125.958C75.747 144.616 75.3206 179.96 96.6256 204.902C117.931 229.844 152.899 234.939 174.73 216.282Z"
          fill="#FF1C4F"
          initial={{ y: 50 }}
          animate={{ y: 0 }}
          transition={{ duration: 1 }}
        />
        <motion.path
          d="M161.846 201.197C176.384 188.773 176.666 165.235 162.477 148.623C148.288 132.012 125 128.617 110.462 141.041C95.9248 153.465 95.6424 177.004 109.832 193.615C124.021 210.227 147.309 213.622 161.846 201.197Z"
          fill="white"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
        />
        <motion.path
          d="M147.522 161.412C153.625 168.573 153.507 178.786 147.287 184.186C140.95 189.586 130.857 188.06 124.754 180.899C118.651 173.738 118.769 163.525 124.989 158.125C131.326 152.725 141.419 154.133 147.522 161.412Z"
          fill="#FF1C4F"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1 }}
        />
        <motion.path
          d="M263.474 46.3696C263.474 46.3696 276.97 0 284.012 0C290.936 0 293.87 15.613 293.87 15.613L263.474 46.3696Z"
          fill="#FF7F51"
          initial={{ rotate: 0 }}
          animate={{ rotate: 360 }}
          transition={{ duration: 2 }}
        />
        <motion.path
          d="M264.647 47.6622C264.647 47.6622 311.004 34.1622 311.004 27.1187C311.004 20.1926 295.396 17.2578 295.396 17.2578L264.647 47.6622Z"
          fill="#FF7F51"
          initial={{ x: -100 }}
          animate={{ x: 0 }}
          transition={{ duration: 1 }}
        />
        <motion.path
          d="M295.044 16.3184L136.138 171.157"
          stroke="#004A67"
          strokeWidth="2.0972"
          strokeMiterlimit="10"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{ duration: 2 }}
        />
        <motion.path
          d="M304.315 112.11C304.315 112.11 336.237 75.8357 342.574 78.7705C348.912 81.7053 344.922 97.0835 344.922 97.0835L304.315 112.11Z"
          fill="#029199"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
        />
        <motion.path
          d="M304.902 113.754C304.902 113.754 352.667 121.149 355.601 114.81C358.535 108.471 345.626 99.3145 345.626 99.3145L304.902 113.754Z"
          fill="#029199"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1 }}
        />
        <motion.path
          d="M345.626 98.2578L136.138 171.158"
          stroke="#004A67"
          strokeWidth="2.0972"
          strokeMiterlimit="10"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{ duration: 2 }}
        />
        <motion.path
          d="M310.183 209.543C310.183 209.543 356.423 195.456 360.178 201.325C364.051 207.195 352.55 218.112 352.55 218.112L310.183 209.543Z"
          fill="#A7CA52"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
        />
        <motion.path
          d="M309.831 211.188C309.831 211.188 346.565 242.648 352.433 238.774C358.301 234.901 352.081 220.344 352.081 220.344L309.831 211.188Z"
          fill="#A7CA52"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1 }}
        />
        <motion.path
          d="M352.667 219.404L136.138 171.156"
          stroke="#004A67"
          strokeWidth="2.0972"
          strokeMiterlimit="10"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{ duration: 2 }}
        />
        <motion.path
          d="M277.557 279.625C277.557 279.625 325.44 286.434 326.379 293.36C327.318 300.286 312.296 305.334 312.296 305.334L277.557 279.625Z"
          fill="#CC4237"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
        />
        <motion.path
          d="M276.501 281.035C276.501 281.035 296.452 325.057 303.376 324.118C310.3 323.179 310.887 307.331 310.887 307.331L276.501 281.035Z"
          fill="#CC4237"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1 }}
        />
        <motion.path
          d="M311.826 306.626L136.138 171.156"
          stroke="#004A67"
          strokeWidth="2.0972"
          strokeMiterlimit="10"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{ duration: 2 }}
        />
      </motion.g>
      <defs>
        <clipPath id="clip0_598_840">
          <rect width="361" height="324" fill="white" />
        </clipPath>
      </defs>
    </motion.svg>
  );
};


export const AnimatedXsSVG = () => {
  return (
    <motion.svg width="200" height="180" viewBox="0 0 200 180" fill="none" xmlns="http://www.w3.org/2000/svg">
      <motion.g clipPath="url(#clip0_598_840)">
        <motion.path
          opacity="0.25"
          d="M139.8 175.7C139.8 176.3 113.4 176.8 80.9 176.8C48.4 176.8 22 176.3 22 175.7C22 175.1 48.4 174.6 80.9 174.6C113.4 174.6 139.8 175.1 139.8 175.7Z"
          fill="#606060"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
        />
        <motion.path
          d="M121.7 57C149.3 89.4 155.2 127 121.3 159.2C94.3 185.3 48.2 177.1 20.6 144.7C-7 112.3 -6.7 66.1 20.8 39.8C48.6 13.5 94.9 18.8 121.7 57Z"
          fill="#991740"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5, delay: 0.5 }}
        />
        <motion.path
          d="M124.8 153.2C152.6 129.4 153.1 84 125.8 51.9C98.5 19.9 53.9 13.4 26.1 37.2C-1.7 61 -2.2 106.4 25.1 138.5C52.8 170.5 97.4 177 124.8 153.2Z"
          fill="#FF1C4F"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1 }}
        />
        <motion.path
          d="M117.4 144.2C141.2 124 141.6 85.6 118.4 58.2C95.2 30.8 57.1 25.3 33.3 45.5C9.5 65.7 9.1 104.1 32.3 131.5C55.5 158.9 93.6 164.4 117.4 144.2Z"
          fill="white"
          initial={{ x: -100 }}
          animate={{ x: 0 }}
          transition={{ duration: 1 }}
        />
        <motion.path
          d="M110.7 136.4C132.9 118.8 133.4 86.1 113.8 63.4C94.2 40.7 61.2 36.1 39 53.7C16.8 71.3 16.3 104 35.9 126.7C55.5 149.4 88.5 154 110.7 136.4Z"
          fill="#FF1C4F"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
        />
        <motion.path
          d="M103.8 129.4C120.3 115.8 120.6 89.4 104.2 71.4C87.8 53.4 61.5 49.6 45 63.2C28.5 76.8 28.2 103.2 44.6 121.2C61 139.2 87.3 143 103.8 129.4Z"
          fill="white"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1 }}
        />
        <motion.path
          d="M96.7 122.2C109.2 111.4 109.4 91.6 97.4 77.6C85.4 63.6 65.9 60.8 53.4 71.6C40.9 82.4 40.7 102.2 52.7 116.2C64.7 130.2 84.2 133 96.7 122.2Z"
          fill="#FF1C4F"
          initial={{ y: 50 }}
          animate={{ y: 0 }}
          transition={{ duration: 1 }}
        />
        <motion.path
          d="M89.8 115.2C98.2 107.1 98.3 94 89.6 84.5C80.9 75 66.5 72.8 58.1 80.9C49.7 89 49.6 102.1 58.3 111.6C67 121.1 81.4 123.3 89.8 115.2Z"
          fill="white"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
        />
        <motion.path
          d="M81.7 92.2C85.1 96.3 85 102 80.9 105.2C76.6 108.4 70.5 107.3 67.1 103.2C63.7 99.1 63.8 93.4 67.9 90.2C72.2 87 78.3 88.1 81.7 92.2Z"
          fill="#FF1C4F"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1 }}
        />
        <motion.path
          d="M145.2 26.5C145.2 26.5 152.8 0 156.3 0C159.7 0 161.2 6.7 161.2 6.7L145.2 26.5Z"
          fill="#FF7F51"
          initial={{ rotate: 0 }}
          animate={{ rotate: 360 }}
          transition={{ duration: 2 }}
        />
        <motion.path
          d="M145.8 27.1C145.8 27.1 171.3 19.4 171.3 15.4C171.3 11.4 162.7 9.8 162.7 9.8L145.8 27.1Z"
          fill="#FF7F51"
          initial={{ x: -100 }}
          animate={{ x: 0 }}
          transition={{ duration: 1 }}
        />
        <motion.path
          d="M162.7 9.3L75 86.6"
          stroke="#004A67"
          strokeWidth="2.1"
          strokeMiterlimit="10"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{ duration: 2 }}
        />
        <motion.path
          d="M167.6 61.8C167.6 61.8 184.9 41.8 188.2 43.5C191.5 45.2 189.4 53.7 189.4 53.7L167.6 61.8Z"
          fill="#029199"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
        />
        <motion.path
          d="M167.9 62.7C167.9 62.7 194.3 66.7 195.6 63.3C196.9 59.9 189.9 54.8 189.9 54.8L167.9 62.7Z"
          fill="#029199"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1 }}
        />
        <motion.path
          d="M189.9 54.3L75 86.6"
          stroke="#004A67"
          strokeWidth="2.1"
          strokeMiterlimit="10"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{ duration: 2 }}
        />
        <motion.path
          d="M170.4 116.4C170.4 116.4 195.9 108.5 198.1 111.7C200.4 114.9 194.1 120.8 194.1 120.8L170.4 116.4Z"
          fill="#A7CA52"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
        />
        <motion.path
          d="M170.2 117.3C170.2 117.3 190.4 134.8 193.5 133.4C196.6 132 193.1 123.7 193.1 123.7L170.2 117.3Z"
          fill="#A7CA52"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1 }}
        />
        <motion.path
          d="M193.3 123.7L75 86.6"
          stroke="#004A67"
          strokeWidth="2.1"
          strokeMiterlimit="10"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{ duration: 2 }}
        />
        <motion.path
          d="M152.1 157.7C152.1 157.7 178.1 161.7 178.5 165.6C179 169.5 171.1 172.5 171.1 172.5L152.1 157.7Z"
          fill="#CC4237"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
        />
        <motion.path
          d="M151.4 158.3C151.4 158.3 162.3 183.1 165.9 182.5C169.5 181.9 169.8 173.2 169.8 173.2L151.4 158.3Z"
          fill="#CC4237"
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1 }}
        />
        <motion.path
          d="M171.7 172.9L75 86.6"
          stroke="#004A67"
          strokeWidth="2.1"
          strokeMiterlimit="10"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{ duration: 2 }}
        />
      </motion.g>
      <defs>
        <clipPath id="clip0_598_840">
          <rect width="200" height="180" fill="white" />
        </clipPath>
      </defs>
    </motion.svg>
  );
};


