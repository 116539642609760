import { Button } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import styled from "styled-components";
import { ArrowLeft, ArrowRight, Flag3 } from "tabler-icons-react";

export const NavButtons = ({ props }) => {
  const {
    chosenOptions,
    haveBackedOnce,
    currentQuestion,
    finishQuiz,
    changeQuestion,
    isFirstQuestion,
    isLastQuestion,
  } = props;

  const matches = useMediaQuery("(max-width: 800px)");

  return (
    <NavButtonsStyle>
      <div className="innerx">
        <Button
          pl={40}
          pr={52}
          variant={"filled"}
          disabled={isFirstQuestion || haveBackedOnce}
          leftIcon={<ArrowLeft />}
          styles={(theme) => ({
            root: {
              background:
                isFirstQuestion || haveBackedOnce
                  ? "linear-gradient(90deg, #343A40 0%, #212529 100%)"
                  : "linear-gradient(90deg, #2DB5C2 0%, #029199 100%)",
              height: "48px",
              fontSize: "15px",
              fontWeight: 500,
              "&:hover": {
                background:
                  isFirstQuestion || haveBackedOnce
                    ? "linear-gradient(90deg, #212529 0%, #343A40 100%)"
                    : "linear-gradient(90deg, #2DB5C2 100%, #029199 0%)",
              },
            },
          })}
          radius={10}
          onClick={() => changeQuestion({ to: "prev" })}
        >
          Geri
        </Button>

        {!isLastQuestion && (
          <Button
            pl={52}
            pr={40}
            radius={10}
            variant="filled"
            disabled={chosenOptions.id.length === 0 && !haveBackedOnce}
            rightIcon={<ArrowRight />}
            styles={(theme) => ({
              root: {
                background:
                  chosenOptions.id.length === 0 && !haveBackedOnce
                    ? "linear-gradient(90deg, #343A40 0%, #212529 100%)"
                    : "linear-gradient(90deg, #2DB5C2 0%, #029199 100%)",
                height: "48px",
                fontSize: "15px",
                fontWeight: 500,
                "&:hover": {
                  background:
                    chosenOptions.id.length === 0 && !haveBackedOnce
                      ? "linear-gradient(90deg, #212529 0%, #343A40 100%)"
                      : "linear-gradient(90deg, #2DB5C2 100%, #029199 0%)",
                },
              },
            })}
            onClick={() => changeQuestion({ to: "next" })}
          >
            İrəli
          </Button>
        )}

        {isLastQuestion && (
          <Button
            className={"addBorder buttonNav"}
            rightIcon={<Flag3 />}
            onClick={() => finishQuiz()}
            radius={10}
            variant="filled"
            styles={(theme) => ({
              root: {
                background: "linear-gradient(90deg, #2DB5C2 0%, #029199 100%)",
                height: "48px",
                fontSize: "15px",
                fontWeight: 500,
                "&:hover": {
                  background: "linear-gradient(90deg, #2DB5C2 100%, #029199 0%)",
                },
              },
            })}
          >
            Finish
          </Button>
        )}
      </div>
    </NavButtonsStyle>
  );
};

const NavButtonsStyle = styled.div`
  h2 {
    margin: 0;
  }
  .innerx {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 1rem;
    .buttonNav {
      font-weight: 500;
      font-size: 16px;
      min-width: 160px;
      border: 1px solid transparent;
      &:hover {
        background-color: #495057;
      }
    }
    .addBorder {
      border: 1px solid #adb5bd;
    }
  }
  @media (max-width: 800px) {
    button {
      width: 100%;
    }
    h2 {
      text-align: center;
      margin-top: -1rem;
      margin-bottom: 1rem;
    }
  }
`;
