import { AppShell, Button, Stack, Text } from "@mantine/core";
import { motion } from "framer-motion";
import { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { ArrowUp, Check } from "tabler-icons-react";
import { NavButtons } from "./NavButtons";
import { ProgressBar } from "../../../Components/TIP/ProgressBar";
import { Quiz } from "./Quiz";
import { typog } from "../../../Assets/SVGs/topography";
import { QuizFinished } from "./QuizFinished";
import { VisionPaywall } from "./VisionPaywall";
import axios from "../../../Helpers/Axios";
import { useQuery } from "@tanstack/react-query";
import { useMediaQuery } from "@mantine/hooks";
import { AppContext } from "../../../Helpers/Context";
import {
  IconChevronLeft,
  IconChevronsRight,
  IconFlag3,
} from "@tabler/icons-react";

export const Vision = () => {
  const { data: programPurchased } = useQuery(
    ["program is purchased"],
    async () =>
      await axios
        .get("/forum/success_order/")
        .then((res) => res.data.successfuly_paid)
  );

  let location = useLocation();

  const { data: questions, refetch } = useQuery(
    ["non spec questions"],
    async () => {
      return await axios
        .get("/forum/nonspecialty/multidata/")
        .then((res) => res.data.QuestionNonSpeciality);
    },
    {
      onSuccess: (data) =>
        setCurrentQuestion({
          index: 1,
          id: data[0]?.id,
          order: data[0]?.order,
        }),
    }
  );

  useEffect(() => {
    refetch();
  }, [location.pathname]);

  const [progressBarValue, setProgressBarValue] = useState(0);
  const [quizFinished, setQuizFinished] = useState(false);
  const [showQuiz, setShowQuiz] = useState(false);

  const [chosenOption, setChosenOption] = useState({
    index: 0,
    id: null,
    text: null,
    confirmed: false,
  });
  const [optionNotChosen, setOptionNotChosen] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState({ index: 0, id: 0 });

  const matches = useMediaQuery("(max-width: 800px)");

  return (
    <AppShell padding={0} style={{ backgroundColor: "#EFF3F4", position: "relative", paddingBottom: "5rem" }}>
      <VisionStyle>
        {(quizFinished || (programPurchased && questions?.length === 0)) && (
          <motion.div
            key="finished"
            initial={{ opacity: 0, y: -500 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -500 }}
            transition={{ duration: 0.5 }}
            className="specialityWrapper"
          >
            <QuizFinished />
          </motion.div>
        )}
        {quizFinished === false && currentQuestion?.id !== 0 && (
          <>
            {questions?.length === 16 && showQuiz === false && (
              <motion.div
                key="paywall"
                initial={{ opacity: 0, y: -500 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -500 }}
                transition={{ duration: 0.5 }}
                className="specialityWrapper"
              >
                <VisionPaywall props={{ programPurchased, setShowQuiz }} />
              </motion.div>
            )}

            {((programPurchased &&
              questions?.length < 16 &&
              questions?.length !== 0) ||
              showQuiz === true) && (
              <motion.div
                key="speciality"
                initial={{ opacity: 0, y: -500 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -500 }}
                transition={{ duration: 0.5 }}
                className="specialityWrapper"
              >
                {!matches && (
                  <Stack justify="center" align="center" spacing={10}>
                    <Link
                      style={{
                        position: "absolute",
                        top: "8rem",
                        left: "4rem",

                        color: "#000",

                        fontWeight: 500,
                      }}
                      to="/tip"
                    >
                      <IconChevronLeft size={28} color="#202224" />
                    </Link>

                    <Text component="h1" color="#202224" size={32} weight={600}>
                      TİP - ə Xoşgəlmisiniz!
                    </Text>
                    <Text
                      mb={10}
                      component="p"
                      color="#979797"
                      size={18}
                      weight={500}
                    >
                      Başlamaq üçün 16 addımı tamamlayın
                    </Text>
                    <Text
                      component="span"
                      color="#08959E"
                      size={18}
                      weight={500}
                    >
                      Sual: {currentQuestion?.order + "/" + 16}
                    </Text>
                  </Stack>
                )}
                <div
                  style={{
                    borderRadius: 10,
                    background: "#FFF",
                    boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.10)",
                    padding: "2rem",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <ProgressBar
                      props={{
                        length: 16,
                        currentPosition:
                          16 - questions?.length + progressBarValue,
                        color: "teal.8",
                        size: "md",
                      }}
                    />

                    <Quiz
                      props={{
                        questions,
                        chosenOption,
                        setChosenOption,
                        currentQuestion,
                        optionNotChosen,
                        setOptionNotChosen,
                      }}
                    />
                  </div>
                </div>
                <NavButtons
                  props={{
                    currentQuestion,
                    setProgressBarValue,
                    setQuizFinished,
                    entries: questions,
                    index: currentQuestion.index,
                    setPosition: setCurrentQuestion,
                    chosenOption,
                    setChosenOption,
                    setOptionNotChosen,
                  }}
                />
              </motion.div>
            )}
          </>
        )}
      </VisionStyle>
    </AppShell>
  );
};

const VisionStyle = styled.div`
  background-color: #eff3f4;
  h1,
  h2,
  h3,
  a,
  button {
    font-weight: 500;
  }
  overflow: hidden;
  padding-top: 4.5rem;
  padding-bottom: 5rem;
  .exit {
    max-width: max-content;
    padding: 0 5rem;
    margin: 0 auto;
    margin-top: -3rem;
    border: 3px solid #495057;
  }
  .specialityWrapper {
    display: flex;
    flex-direction: column;
    max-width: 70%;
    grid-gap: 2rem;
    border-radius: 0.25rem;
    margin: 0 auto;
  }
  @media (max-width: 800px) {
    overflow: initial;
    padding: 0;
    .specialityWrapper {
      max-width: 100%;
      border-radius: 0;
      padding: 1rem;
    }
  }
`;
