import { Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

export function Slide({ props }) {
  const matches = useMediaQuery("(max-width: 738px)");

  const texts = [
    <div key={"bir"} style={{ padding: "0 .5rem" }}>
      <Text align="center" size={matches ? 20 : 34} weight={500}>
        Özünü, ixtisasları, iş dünyasını
      </Text>
      <Text align="center" size={matches ? 24 : 34} weight={500}>
        <ins>tanı və inkişaf et!</ins>
      </Text>
    </div>,
    <Text
      style={{ padding: "0 .5rem" }}
      key={"iki"}
      align="center"
      size={matches ? 24 : 34}
      weight={500}
    >
      İxtisas/Karyera üzrə <ins>dünyada ən geniş</ins> rəqəmsal konsultasiya
      proqramı
    </Text>,
  ];

  let { currentSlide, setCurrentSlide } = props;

  const changeSlide = useCallback(() => {
    if (currentSlide === 0) {
      setCurrentSlide(1);
    } else {
      setCurrentSlide(0);
    }
  }, [currentSlide]);

  useEffect(() => {
    const intervalID = setInterval(changeSlide, 4000);
    return () => clearInterval(intervalID);
  }, [changeSlide]);

  return texts[currentSlide];
}

export const Introduction = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const max800 = useMediaQuery("(max-width: 800px)");
  const max360 = useMediaQuery("(max-width: 360px)");

  return (
    <IntroStyle props={{ max800, max360 }}>
      <div className="video">
        <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
          <iframe
            title="Video #1"
            src="https://player.vimeo.com/video/787145525?h=463c9faf98&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            allow="autoplay; fullscreen; picture-in-picture"
            style={{
              border: "0px",
              position: "absolute",
              top: "0",
              right: "0",
              width: max800 ? "100%" : "98.9%",
              height: "100%",
              borderRadius: "9px",
            }}
          />
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </div>
    </IntroStyle>
  );
};

const IntroStyle = styled.div`
  display: grid;
  grid-template-columns: 0.5fr;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  margin-bottom: 2rem;
  ins {
    text-decoration: none;
    color: #ffdf00;
  }
  .text {
    margin-bottom: ${(props) => (props.props.max800 ? "5rem" : "0")};
    position: relative;
    border-radius: 0.25rem;
    display: flex;
    background-color: #2c3136;
    justify-content: center;
    align-items: center;
    padding: ${(props) => (props.props.max800 ? "0" : "0 .5rem")};
    max-width: 100%;
    min-height: 180px;
  }
  .video {
    background-color: #fff;
    width: 100%;
    height: 100%;
    padding: ${(props) => (!props.props.max800 ? "0.9rem 1rem 0.1rem 0.7rem" : "0")};
    border-radius: 9px;
  }

  @media (max-width: 738px) {
    grid-template-columns: 1fr;
    .video {
      order: -1;
    }
    .text {
      div {
        line-height: 1.2;
      }
    }
  }
`;
