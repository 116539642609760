import React, { useState } from "react";
import CustomSelect from "../../../Components/Profile/CustomSelect";
import { ViewContainer } from ".";
import CustomAccordion from "../../../Components/Profile/CustomAccordion";
import TagList from "../../../Components/Profile/TagList";

const StudyInterest = ({t,publicUser,userSkills, user, selectDatas, handleSkillHeaderId,handleCreateUserSkill,handleDeleteUserSkill }) => {
  const [isCreateFormActive, setIsCreateFormActive] = useState(false);
  const [isEditFormActive, setIsEditFormActive] = useState(false);

  return (
    <ViewContainer>
      <div style={{ padding: "10px 30px 10px 20px" }}>
        <CustomAccordion
          defaultValue="interest"
          value="interest"
          data={userSkills}
          title={t('study-interest')}
          handleActiveCreateForm={setIsCreateFormActive}
          isCreateFormActive={isCreateFormActive}
          handleActiveEditForm={setIsEditFormActive}
          isEditFormActive={isEditFormActive}
          handleSkillHeaderId={handleSkillHeaderId}
          isPublic = {publicUser ? true : false}

        >
          {isCreateFormActive || isEditFormActive ? (
            <CustomSelect
              t={t}
              selectDatas={selectDatas}
              handleCreateUserSkill={handleCreateUserSkill}
              userSkills={userSkills}
              user={user}
              type ={isCreateFormActive ? "create" : "edit"}
              handleDeleteUserSkill={handleDeleteUserSkill}
              handleActiveCreateForm={setIsCreateFormActive}
              handleActiveEditForm={setIsEditFormActive}
            />
          ) : (
            <TagList tagDatas={userSkills} />
          )}
        </CustomAccordion>
      </div>
    </ViewContainer>
  );
};

export default StudyInterest;
