import { Tabs } from '@mantine/core';
import CommunityList from './CommunityList';
import { useGetAllCommunity } from '../hooks/community/useGetAllCommunity';
import { CommunityTabsStyled } from '../style';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const CommunityTabs = ({ data,noFilterData,isLoading,setActivePage,setActiveTab,activeTab,activePage,hasActiveFilter,hasActiveTab ,user}) => {
 

  const handleTabChange = (value) => {
    setActiveTab(value);
    setActivePage(1);
  };

  const handlePageChange = (newPage) => {
    setActivePage(newPage);
    window.scrollTo(0, 0);
  };


  
  const totalPages = Math.ceil(data?.count / 10); // 10 items per page  
  const {t} = useTranslation()
  

  return (
    <CommunityTabsStyled>
      <Tabs defaultValue="all" value={activeTab} onTabChange={handleTabChange}>
        {(data?.count > 0 || (hasActiveFilter || hasActiveTab)) && <Tabs.List style={{ display: 'flex', alignItems: 'flex-start', gap: "15px" }}>
          <Tabs.Tab value="all">{t("all-groups")}</Tabs.Tab>
    {user && (
      <>
        <div style={{ height: '16px', borderRight: '2px solid #364152' }}></div>
        <Tabs.Tab value="managed">{t("managed-groups")}</Tabs.Tab>
        <div style={{ height: '16px', borderRight: '2px solid #364152' }}></div>
        <Tabs.Tab value="joined">{t("joined-groups")}</Tabs.Tab>
      </>
    )}
      </Tabs.List>}

        <Tabs.Panel value="all">
          <CommunityList user={user} hasActiveFilter={hasActiveFilter} hasActiveTab={hasActiveTab} communities={user ? data : noFilterData} isLoading={isLoading} totalPages={totalPages} activePage={activePage} setActivePage={handlePageChange} />
        </Tabs.Panel>

{user &&        <Tabs.Panel value="managed">
          <CommunityList hasActiveFilter={hasActiveFilter} hasActiveTab={hasActiveTab} communities={data} isLoading={isLoading} totalPages={totalPages} activePage={activePage} setActivePage={handlePageChange} />
        </Tabs.Panel>}

{user &&        <Tabs.Panel value="joined">
          <CommunityList hasActiveFilter={hasActiveFilter} hasActiveTab={hasActiveTab} communities={data} isLoading={isLoading} totalPages={totalPages} activePage={activePage} setActivePage={handlePageChange} />
        </Tabs.Panel>}
      </Tabs>
    </CommunityTabsStyled>
  );
};

export default CommunityTabs;
