import React, { useState } from "react";
import { ViewContainer } from ".";
import CustomAccordion from "../../../Components/Profile/CustomAccordion";
import MainInfoCard from "../../../Components/Profile/MainInfoCard";
import JobForm from "./ProfileForms/JobForm";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "../../../Helpers/Axios";
import { Stack } from "@mantine/core";

const ActivityView = ({ t, user, publicUser }) => {
  const [isCreateFormActive, setIsCreateFormActive] = useState(false);
  const [isEditFormActive, setIsEditFormActive] = useState(false);
  const [selectedExperienceId, setSelectedExperienId] = useState(null);
  const [selectDeletedExperienceId, setSelectDeletedExperienceId] =
    useState(null);
  const [showIcons, setShowIcons] = useState(false);
  const queryClient = useQueryClient();

  const link = publicUser
    ? `/api/experience?guid=${publicUser?.guid}`
    : `/api/experience/`;

  const { data: experiences } = useQuery({
    queryKey: publicUser ? ["experiences", publicUser?.guid] : ["experiences"],
    queryFn: async () => {
      try {
        const response = await axios.get(link);
        return response.data;
      } catch (error) {
        console.error(error);
      }
    },
  });

  const { mutate: deleteExperience } = useMutation({
    mutationFn: async (id) => {
      await axios.delete(`/api/experience/${id}/`);
    },
    onSuccess: () => {
      setSelectDeletedExperienceId(null);
      queryClient.invalidateQueries(["experiences"]);
    },
    onError: (error) => {
      console.error(error);
    },
    enabled: !!selectDeletedExperienceId,
  });

  return (
    <ViewContainer>
      <div style={{ padding: "10px 20px 10px 20px" }}>
        <CustomAccordion
          defaultValue="job"
          value="job"
          data={experiences}
          title={t("activity-volunteer")}
          handleActiveCreateForm={setIsCreateFormActive}
          isCreateFormActive={isCreateFormActive}
          handleActiveIcons={setShowIcons}
          isShowIcons={showIcons}
          handleId={setSelectedExperienId}
          isPublic={publicUser ? true : false}
        >
          {isCreateFormActive || isEditFormActive ? (
            <JobForm
              t={t}
              handleActiveCreateForm={setIsCreateFormActive}
              handleActiveUpdateForm={setIsEditFormActive}
              experienceId={selectedExperienceId}
              formType={isCreateFormActive ? "create" : "update"}
              user={user}
            />
          ) : (
            <Stack spacing={20}>
              {experiences?.map((experience) => (
                <MainInfoCard
                  t={t}
                  handleDataId={setSelectedExperienId}
                  handleDeletedData={deleteExperience}
                  handleActiveUpdateForm={setIsEditFormActive}
                  isShow={showIcons}
                  key={experience?.id}
                  data={experience}
                  type="experience"
                />
              ))}
            </Stack>
          )}
        </CustomAccordion>
      </div>
    </ViewContainer>
  );
};

export default ActivityView;
