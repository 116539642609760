import { useQuery } from "@tanstack/react-query";
import { useCommunity } from "./useCommunity";
import { getAllCommunity } from "../../services/apiCommunity";

export const useGetAllCommunity = (params,page = 1,queryfilterParams,user) => {
    const {queryKeys,handleError} = useCommunity();

    return useQuery({
        queryKey: queryKeys.all(page,params,queryfilterParams),
        queryFn: () => getAllCommunity(params,page,queryfilterParams),
        keepPreviousData: true,
        staleTime: 5000,
        onError: (error) => handleError(error),
        onSuccess: () => {
            window.scrollTo(0, 0);
        },
        enabled: user !== null  
     })
}
