import { Button, RingProgress, Text, ThemeIcon } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import axios from "../../../Helpers/Axios";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Check, PlayerPlay } from "tabler-icons-react";

export const VideosCard = ({ props }) => {
  const { user } = props;

  const [lastWatched, setLastWatched] = useState(0);

  useQuery(
    ["lastWatchedVideoId"],
    async () => {
      return await axios
        .get("/forum/user_choose_recommendation/")
        .then((res) => res.data);
    },
    { onSuccess: (data) => setLastWatched(data[0]?.recommendation) }
  );

  return (
    <VideosCardStyle>
      <div className="texts">
        <RingProgress
          size={84}
          thickness={6}
          sections={[
            { value: (lastWatched ? lastWatched : 0) * 5.55, color: "#029199" },
          ]}
          className="ring"
          label={
            lastWatched === 18 ? (
              <ThemeIcon color="#029199" variant="filled" radius="xl" size="xl">
                <Check size={26} />
              </ThemeIcon>
            ) : (
              <Text
                style={{ background: "#E9F0F0" }}
                color="#029199"
                weight={600}
                align="center"
                size="lg"
                sx={{ borderRadius: "50%", padding: "15px 0px" }}
              >
                {lastWatched ? lastWatched : 0}/18
              </Text>
            )
          }
        />
        <h3>1. Seminar</h3>
      </div>
      <div className="textWrap">
        <p>
          Burada sən təhsilə, əmək bazarına və ən əsası öz inkişafına necə
          yanaşmalı olduğunla bağlı bir sıra dəyərli tövsiyələr eşidəcəksən.
        </p>
      </div>
      <Button
        fullWidth
        radius={10}
        leftIcon={<PlayerPlay size={25} />}
        size="md"
        component={Link}
        variant="gradient"
        gradient={{ from: "#029199", to: "#029199", deg: 35 }}
        to={user?.id ? "/tip/videos" : "/login"}
      >
        Videolara keçid et
      </Button>
    </VideosCardStyle>
  );
};

const VideosCardStyle = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  padding: 1rem;
  text-align: center;
  justify-content: space-between;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.08);
  .ring {
    margin: 0rem auto 0rem auto;
  }
  .texts {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
  }
  .textWrap {
    display: flex;
    align-items: center;
    min-height: 80px;
  }
  h3 {
    color: #029199;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 155.556% */
  }
  p {
    color: #747474;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 173.333% */
  }
  h3,
  h2,
  p {
    margin: 0 auto;
  }
`;
