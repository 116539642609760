import styled from "styled-components";

export const PromoStyle = styled.div`
  form {
    display: grid;
    grid-gap: 0.5rem;
    align-items: flex-end;
    justify-content: space-between;
    label {
      color: var(--Text, #0a0d13);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 122.222% */
      margin-bottom: 6px;
    }
    input {
      font-size: 15px;
    }
  }

  .promoCodeForm {
    grid-template-columns: 3fr 1fr;
  }
  .promoInstallmentForm {
    grid-template-columns: 1fr;
  }

  .promoPart {
    margin-bottom: 1rem;
    p {
      color: var(--Text, #0a0d13);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 122.222% */
      margin-bottom: 6px;
      text-align: left;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    form {
      grid-template-columns: 1fr;
      grid-gap: 0.25rem;
    }
  }
`;

export const UlduzumStyle = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  form {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 0.5rem;
    align-items: flex-end;
    justify-content: space-between;
    label {
      font-size: 15px;
    }
    input {
      font-size: 15px;
      padding-left: 3rem;
    }
    .mantine-TextInput-icon {
      padding: 0 0.5rem;
      margin-right: 1rem;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    form {
      grid-template-columns: 1fr;
      grid-gap: 0.25rem;
    }
  }
`;

export const PaymentStyle = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  background-color: #fff;
  border-radius: 4px;
  padding: 1rem;
  p {
    text-align: center;
    b {
      font-weight: 500;
    }
  }
  button,
  a {
    font-weight: 500;
    font-size: 16px;
  }
  .loggedOutPayButton {
    background: var(--yellow);
    color: #000;
    &:hover {
      background: var(--yellow-shadow);
    }
  }
  .payButtons {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    .payButton {
      background: var(--yellow);
      color: #000;
      &:hover {
        background: var(--yellow-shadow);
      }
    }
  }
  .submit {
    margin-top: 1.5rem;
    .noPromo {
      text-align: center;
      font-weight: 400;
      color: #ffa200;
      font-size: 18px;
    }
    .yesPromo {
      color: #000;
      text-align: center;
      font-weight: 400;
      font-size: 18px;
    }
  }
  .price {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 1rem;
    margin: 0;
    ins {
      text-decoration: none;
      font-size: 17px;
      color: #888;
    }
    h2 {
      margin: 0;
      font-weight: 500;
    }
  }
  @media (max-width: 768px) {
    width: 90%;
    p {
      margin: 0.75rem 2rem 2rem 2rem;
    }
  }
`;
