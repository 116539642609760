import { Button, List, Text } from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import { ChevronsRight } from "tabler-icons-react";
import { Payment } from "../../../Components/PaymentForTip/_Payment";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import vision from "../../../Assets/Images/tip_vision.png";
import { Box } from "@mantine/core";
import { IconChevronLeft, IconPointFilled } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Modal } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

export const QuizFinished = () => {
  const { t } = useTranslation();
  const xs = useMediaQuery("(max-width: 738px)");
  return (
    <>
      <Helmet>
        <title>{t("seo_quiz_finished_title")}</title>
        <meta name="description" content={t("seo_quiz_finished_description")} />
        <meta name="keywords" content={t("seo_quiz_finished_keywords")} />
        <meta property="og:title" content={t("seo_quiz_finished_title")} />
        <meta
          property="og:description"
          content={t("seo_quiz_finished_description")}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={t("seo_quiz_finished_title")} />
        <meta
          name="twitter:description"
          content={t("seo_quiz_finished_description")}
        />
      </Helmet>
      <PaywallStyle>
        <div className="container">
          <div>
            <Box
              component={Link}
              to="/tip"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "20px",
              }}
            >
              <IconChevronLeft
                style={{ color: "#029199", marginTop: "1px" }}
                size={24}
              />
              <Text
                size={xs ? 20 : 26}
                weight={600}
                color="#029199"
                align="center"
              >
                Birinci mərhələ sona çatdı
              </Text>
            </Box>
            <Text
              size={16}
              color="#404040"
              weight={400}
              style={{
                lineHeight: "27px",
              }}
              mb={10}
            >
              Konsultasiya mərhələsini bitirdin. İnanırıq ki, sənin üçün faydalı
              oldu. İxtisas sorğusuna keçid edirsən. Bu bir nailiyyət və ya
              imtahan testi deyil. Səndən gözləntimiz odur ki, bütün suallara
              diqqətli, şəffaf və dürüst cavab verərsən. Əks halda bizim
              təhlilimiz (komputerin uzlaşdırması) yanlış və ya yaxşı
              düşünülməmiş cavablara uyğun aparılacaq və nəticədə özünə uyğun
              olmayan tövsiyələr almaqla itirən tərəf yalnız sən özün olacaqsan.
            </Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{ width: "80%", height: "auto" }}
              src={vision}
              alt="vision"
            />

            {/* {!programPurchased && (
            <Payment props={{ applyChangesAfterPurchase }} />
          )} */}
          </div>
        </div>
      </PaywallStyle>
      <Button
        mt={10}
        fullWidth
        rightIcon={<ChevronsRight />}
        variant="gradient"
        component={Link}
        to="/tip/profession"
        gradient={{ from: "#029199", to: "#017379", deg: 90 }}
        sx={{
          height: "48px",
          borderRadius: "8px",
          fontSize: "15px",
          fontWeight: 500,
          "&:hover": {
            background: "linear-gradient(90deg, #017379, #029199)",
          },
        }}
      >
        Ixtisas sorgusu
      </Button>
    </>
  );
};

const PaywallStyle = styled.div`
  width: 100%;

  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 2rem;
    align-items: center;
    justify-content: space-between;
  }

  @media (max-width: 738px) {
    .container {
      grid-template-columns: 1fr;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
  }
`;
