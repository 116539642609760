import { Button } from "@mantine/core";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  ArrowLeft,
  ArrowRight,
  ChevronLeft,
  ChevronsRight,
} from "tabler-icons-react";
import { VideoTextModal } from "./VideoTextModal";
import { useMediaQuery } from "@mantine/hooks";

export const VideoSection = ({ props }) => {
  const { videos, chosenSection, setChosenSection, modalOpen, setModalOpen } =
    props;

  function changeVideo(props) {
    const { to, id } = props;

    if (to === "prev") {
      setChosenSection(id - 1);
    }
    if (to === "next") {
      setChosenSection(id + 1);
    }
  }
  const xs = useMediaQuery("(max-width: 738px)");

  return (
    <VideoSectionStyle>
      <VideoTextModal props={{ modalOpen, setModalOpen }} />
      <motion.div
        initial={{ opacity: 0, x: -200 }}
        animate={{
          opacity: 1,
          x: 0,
          transition: { duration: 0.2, delay: 0.2 },
        }}
        exit={{ opacity: 0, x: -200 }}
        transition={{ duration: 0.3 }}
      >
        <AnimatePresence mode="wait">
          {videos?.length > 0 &&
            videos.map(
              (video) =>
                chosenSection === video.id && (
                  <motion.div
                    key={video.id + "vid"}
                    className="videoDiv"
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 50 }}
                    transition={{ duration: 0.5 }}
                  >
                    <div className="header">
                      <Button
                        leftIcon={
                          <ChevronLeft
                            size={xs ? 20 : 24}
                            style={{ marginTop: "2px" }}
                          />
                        }
                        onClick={() =>
                          changeVideo({ to: "prev", id: video.id })
                        }
                        radius="xs"
                        variant="outline"
                        styles={{
                          root: {
                            border: "none",
                            backgroundColor: "transparent",
                            color: "#202224",
                            fontSize: "28px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "24px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "left",
                            "&:disabled": {
                              backgroundColor: "transparent",
                              color: "#202224",
                              cursor: "no-drop",
                            },
                          },
                        }}
                        disabled={videos[0]?.id === video.id}
                      >
                        {video?.question}
                      </Button>
                      {!xs && (
                        <div className="buttons">
                          {videos[videos?.length - 1]?.id !==
                          video.id ? null : (
                            <Button
                              component={Link}
                              to="/tip/vision"
                              fullWidth
                              rightIcon={<ChevronsRight />}
                              variant="gradient"
                              gradient={{
                                from: "#00BD90",
                                to: "#009D78",
                                deg: 90,
                              }}
                              sx={{
                                height: "48px",
                                borderRadius: "8px",
                                fontSize: "15px",
                                fontWeight: 500,
                                "&:hover": {
                                  background:
                                    "linear-gradient(90deg, #009D78, #00BD90)",
                                },
                              }}
                            >
                              Konsultasiyaya keçid
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                    <div
                      style={{ padding: "56.25% 0 0 0", position: "relative" }}
                    >
                      <iframe
                        src={video.video}
                        allow="autoplay; fullscreen; picture-in-picture"
                        style={{
                          border: "0px",
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "100%",
                          height: "100%",
                        }}
                        title="Video #1"
                      ></iframe>
                    </div>
                    <script src="https://player.vimeo.com/api/player.js"></script>

                    {xs && (
                      <div className="buttons">
                        {videos[videos?.length - 1]?.id !== video.id ? null : (
                          <Button
                            component={Link}
                            to="/tip/vision"
                            fullWidth
                            rightIcon={<ChevronsRight />}
                            variant="gradient"
                            gradient={{
                              from: "#00BD90",
                              to: "#009D78",
                              deg: 90,
                            }}
                            sx={{
                              height: "48px",
                              borderRadius: "8px",
                              fontSize: "15px",
                              fontWeight: 500,
                              "&:hover": {
                                background:
                                  "linear-gradient(90deg, #009D78, #00BD90)",
                              },
                            }}
                          >
                            Konsultasiyaya keçid
                          </Button>
                        )}
                      </div>
                    )}
                  </motion.div>
                )
            )}
        </AnimatePresence>
      </motion.div>
    </VideoSectionStyle>
  );
};

const VideoSectionStyle = styled.div`
  overflow: hidden;
  padding: 20px 0;
  h1,
  h2,
  h3 {
    font-weight: 500;
    margin-bottom: 0;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 738px) {
      display: flex;
      justify-content: flex-start;
      button {
        font-size: 20px;
      }
    }
  }
  .videoDiv {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    width: 92%;
    margin: 0 auto;
    .videoFrame {
      height: 620px;
      width: 100%;
    }
    .videoText {
      p {
        line-height: 1.3rem;
        font-size: 17px;
      }
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      grid-gap: 1rem;
      a,
      button {
        font-weight: 500;
        font-size: 16px;
        min-width: 140px;
        @media (max-width: 738px) {
        }
      }
    }
  }
  @media (max-width: 738px) {
    .videoDiv {
      width: 95%;
      .header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
`;
