import { Button, List, Text } from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import { ChevronsRight } from "tabler-icons-react";
import { Payment } from "../../../Components/PaymentForTip/_Payment";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import vision from "../../../Assets/Images/tip_note.png";
import { Box } from "@mantine/core";
import { IconChevronLeft, IconPointFilled } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Modal } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

export const VisionPaywall = ({ props }) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const xs = useMediaQuery("(max-width: 738px)");

  const { programPurchased, setShowQuiz } = props;
  const [modalOpen, setModalOpen] = useState(false);

  function applyChangesAfterPurchase() {
    queryClient.invalidateQueries("program is purchased");
  }

  const handleConsultation = () => {
    if (!programPurchased) {
      setModalOpen(true);
    } else {
      setShowQuiz(true);
    }
  };

  return (
    <>
      <Helmet>
        <title>{t("seo_vision_paywall_title")}</title>
        <meta
          name="description"
          content={t("seo_vision_paywall_description")}
        />
        <meta name="keywords" content={t("seo_vision_paywall_keywords")} />
        <meta property="og:title" content={t("seo_vision_paywall_title")} />
        <meta
          property="og:description"
          content={t("seo_vision_paywall_description")}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={t("seo_vision_paywall_title")} />
        <meta
          name="twitter:description"
          content={t("seo_vision_paywall_description")}
        />
      </Helmet>
      <PaywallStyle props={{ programPurchased }}>
        <div className="container">
          <div>
            <Box
              component={Link}
              to="/tip"
              style={{
                display: "flex",
                alignItems:"center",
                justifyContent: xs ? "center" : "flex-start",
                gap: "10px",
                marginBottom: "20px",
              }}
            >
              <IconChevronLeft
                style={{ color: "#029199", marginTop: "1px" }}
                size={24}
              />
              <Text size={26} weight={600} color="#029199" align="center">
                Vacib Qeyd!{" "}
              </Text>
            </Box>
            <Text
              size={16}
              color="#404040"
              weight={400}
              style={{
                lineHeight: "27px",
              }}
              mb={10}
            >
              Bizə güvəndiyin üçün sənə minnətdarıq. Sorğuda heç kimlə canlı
              bağlantı qurulmayacaq və təkmilləşdirilmiş proqramlaşdırma
              sayəsində bütün nəticələr verdiyin cavablara uyğun olaraq
              avtomatik şəkildə ortaya çıxacaq. Bura qədər izlədiyin məqamlar,
              hamıya müraciət etdiyinizümumi tövsiyələr idi. Bundan sonra ancaq
              sənin verdiyin cavablara uyğun fikirlər eşidəcəksən. Girişdə də
              dediyi kimi, sorğunun əsas məqsədir sənin 3 yöndən fərqindəliyini
              artırmaq və sağlam dünyagörüşü yaratmaqdır:
            </Text>
            <List
              size={16}
              color="#404040"
              weight={400}
              style={{
                lineHeight: "27px",
              }}
              mb={10}
            >
              <List.Item
                style={{
                  fontSize: "16px",
                  color: "#404040",
                  fontWeight: 400,
                  lineHeight: "27px",
                }}
                icon={<IconPointFilled size={14} color="#404040" />}
              >
                Özünü tanıma
              </List.Item>
              <List.Item
                style={{
                  fontSize: "16px",
                  color: "#404040",
                  fontWeight: 400,
                  lineHeight: "27px",
                }}
                icon={<IconPointFilled size={14} color="#404040" />}
              >
                İxtisasları tanıma
              </List.Item>
              <List.Item
                style={{
                  fontSize: "16px",
                  color: "#404040",
                  fontWeight: 400,
                  lineHeight: "27px",
                }}
                icon={<IconPointFilled size={14} color="#404040" />}
              >
                Karyera dünyasını tanıma
              </List.Item>
            </List>
            <Text
              size={16}
              color="#404040"
              weight={400}
              style={{
                lineHeight: "27px",
              }}
              mb={10}
            >
              Odur ki, dünyagörüşü sorğusunu ciddiyə al və bil ki, bu sənin üçün
              ən azı növbəti mərhələdə olan ixtisas seçimi sorğusu qədər faydalı
              ola bilər.
            </Text>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{ width: "80%", height: "auto" }}
              src={vision}
              alt="vision"
            />

            {/* {!programPurchased && (
            <Payment props={{ applyChangesAfterPurchase }} />
          )} */}
          </div>
        </div>
      </PaywallStyle>
      <Button
        mt={30}
        onClick={handleConsultation}
        fullWidth
        rightIcon={<ChevronsRight />}
        variant="gradient"
        gradient={{ from: "#029199", to: "#017379", deg: 90 }}
        sx={{
          height: "48px",
          borderRadius: "8px",
          fontSize: "15px",
          fontWeight: 500,
          "&:hover": {
            background: "linear-gradient(90deg, #017379, #029199)",
          },
        }}
      >
        İrəli
      </Button>
      <Modal
        opened={modalOpen}
        onClose={() => setModalOpen(false)}
        size="lg"
        centered
        title="Ödəniş "
        withCloseButton={true}
        styles={{
          modal: {
            backgroundColor: "#fff",
            borderRadius: "12px",
            width: xs ? "100%" : "38%",
          },
          close: {
            color: "#1C1C1C",
            "&:hover": {
              backgroundColor: "#f5f5f5",
            },
          },
          header: {
            color: "#0A0D13",
            fontSize: xs ? "20px" : "24px",
            fontWeight: "600",
            lineHeight: "28px",
            letterSpacing: "-0.48px",
            position: "relative",
            "&:after": {
              content: '""',
              display: "block",
              position: "absolute",
              bottom: 0,
              left: "50%",
              transform: "translateX(-50%)",
              width: "95%",
              height: "1px",
              backgroundColor: "#EBEBEB",
            },
            padding: "0 1rem 0.5rem 1rem",
          },
        }}
      >
        <Payment props={{ applyChangesAfterPurchase }} />
      </Modal>
    </>
  );
};

const PaywallStyle = styled.div`
  width: 100%;

  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 2rem;
    align-items: center;
    justify-content: space-between;

  }

  @media (max-width: 738px) {
    .container {
      grid-template-columns: 1fr;
      gap: 20px;
      text-align: center;

      > div:first-child {
        order: 1;
      }
      > div:last-child {
        display: none;
      }
    }
  }
`;
