import React, { useContext } from "react";
import styled from "styled-components";
import { Button, Group, Stack, Text, Title } from "@mantine/core";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import profileTop from "../../../Assets/SVGs/dashboard/profileTop.svg";
import {
  Mail,
  TrendingUp,
  Book,
  Atom,
  Users,
  Edit,
  Pencil,
} from "tabler-icons-react";
import Interest from "../../../Assets/SVGs/dashboard/interest.svg";
import vector from "../../../Assets/SVGs/dashboard/Vector.svg";
import { AppContext } from "../../../Helpers/Context";
import defaultAvatar from "../../../Assets/SVGs/header/user.png";
import { useMediaQuery } from "@mantine/hooks";
import CommunityTabs from "./CommunityTabs";
import { Home, Award, ChartBar, Bulb, Bookmark } from "tabler-icons-react";
import { useCommunityContext } from "../../../features/Community/context/CommunityContext";
import { useGetCommunity } from "../../../features/Community/hooks/community/useGetCommunity";
import axios from "../../../Helpers/Axios";
import { useQuery } from "@tanstack/react-query";

const NavbarContent = ({ t }) => {
  const { user } = useContext(AppContext);
  const location = useLocation();
  const includesData = {
    owner: location.pathname.includes("/owner"),
    community: location.pathname.includes("/community/"),
    badges: location.pathname.includes("/badges"),
    collaborators: location.pathname.includes("/collaborators"),
    highlights: location.pathname.includes("/highlights"),
    saved: location.pathname.includes("/saved"),
  };
  const showCommunityTabs =
    includesData.community ||
    includesData.badges ||
    includesData.collaborators ||
    includesData.highlights ||
    includesData.saved;
  const { id, highlightId } = useParams();
  const {
    setOpenedCommunityEditModal,
    setCommunityId,
    setOpenedHighlightEditModal,
    setHighlightId,
  } = useCommunityContext();
  const { data: community } = useGetCommunity(id);
  const cards = [
    {
      title: "TIP",
      background: "#029199",
      vectorColor: "#D5E6AC",
      icon: TrendingUp,
    },
    {
      title: "Courses",
      background: "#FF7F51",
      vectorColor: "#FFC3A3",
      icon: Book,
    },
    {
      title: `${t("steam")}`,
      background: "#FFBF2A",
      vectorColor: "#FFECC0",
      icon: Atom,
    },
    {
      title: "Communities",
      background: "#A89ADE",
      vectorColor: "#D6CCFF",
      icon: Users,
    },
  ];

  const tabs = [
    {
      title: t("home-community"),
      icon: Home,
      path: `/community/${id}/owner`,
    },
    {
      title: t("badges"),
      icon: Award,
      path: `/community/${id}/badges`,
    },
    {
      title: t("collaborator-list"),
      icon: Users,
      path: `/community/${id}/collaborators`,
    },
    {
      title: t("admin-analytics"),
      icon: ChartBar,
      path: `/community/${id}/analytics`,
    },
    {
      title: t("community-highlights"),
      icon: Bulb,
      path: `/community/${id}/highlights`,
    },
    {
      title: t("saved-posts"),
      icon: Bookmark,
      path: `/community/${id}/saved`,
    },
  ];

  const isHighlight = location.pathname.includes("/highlight/");
  const isCommunity = includesData.community;
  const isOwner = community?.author?.id === user?.id;

  const xl = useMediaQuery('(max-width: 1440px)');

  const handleEdit = () => {
    if (isCommunity && !isHighlight) {
      setOpenedCommunityEditModal(true);
      setCommunityId(id);
      localStorage.setItem("updated_community_id", id);
    } else {
      setOpenedHighlightEditModal(true);
      setHighlightId(highlightId);
      localStorage.setItem("updated_highlight_id", highlightId);
    }
  };

  const { data: userSkills, isLoading: isUserSkillsLoading } = useQuery({
    queryKey: ["userSkills"],
    queryFn: async () => {
      return await axios.get(`/api/userskill/`).then((res) => res.data);
    },
  });

  const filterSkillsByHeader = (header) => {
    return userSkills?.filter((skill) => skill.skill.header === header);
  };

  return (
    <section style={{ width: "100%" }}>
      {user && (
        <ProfileInfo>
          <img
            style={{ borderRadius: "15px 15px 0 0", width: "100%" }}
            src={profileTop}
            alt="Profile-Top"
          />

          <img
            style={{ objectFit: "cover" }}
            className="profile-img"
            src={
              user?.profile_img && user?.profile_img.includes("user-profile")
                ? user?.profile_img
                : defaultAvatar
            }
            alt="user-img"
          />

          <Stack
            spacing={xl ? 8 : 10}
            sx={{
              borderRadius: "0 0 15px 15px",
              padding: xl ? "40px 10px 10px 10px" : "40px 20px 20px 20px",
            }}
            align="center"
          >
            <Link to="/profile">
              <Title order={3} weight={700} color="#364152" size={18}>
                {user?.first_name} {user?.last_name}
              </Title>
            </Link>
            <Group 
              spacing={5} 
              sx={{ 
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
              }}
            >
              <Mail 
                size={xl ? 18 : 20} 
                strokeWidth={1.5} 
                color="#989898" 
                style={{ marginTop: '3px' }}
              />
              <Text 
                size={xl ? 14 : 16} 
                weight={400} 
                color="#989898"
                sx={{
                  maxWidth: xl ? 150 : 170,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  '&:hover': {
                    overflow: 'visible',
                    whiteSpace: 'normal',
                    wordBreak: 'break-all'
                  }
                }}
              >
                {user?.email}
              </Text>
            </Group>
            <div
              style={{
                width: "70%",
                height: "1px",
                backgroundColor: "#dcdcdc",
              }}
            ></div>

            {filterSkillsByHeader(5) && filterSkillsByHeader(5).length > 0 ? (
              <Group>
                <Text size={xl ? 14 : 16} weight={400} color="#989898">
                  SAT :{" "}
                  {filterSkillsByHeader(5)[0].skill.skill === "SAT"
                    ? filterSkillsByHeader(5)[0].point
                    : "-"}
                </Text>
                <Text size={xl ? 14 : 16} weight={400} color="#989898">
                  |
                </Text>
                <Text size={xl ? 14 : 16} weight={400} color="#989898">
                  IELTS :{" "}
                  {filterSkillsByHeader(5)[0].skill.skill === "IELTS"
                    ? filterSkillsByHeader(5)[0].point
                    : "-"}
                </Text>
              </Group>
            ) : (
              <Button
                component={Link}
                to="/profile"
                styles={{
                  root: {
                    backgroundColor: "transparent",
                    fontSize: xl ? 13 : 14,
                    fontWeight: "500",
                    color: "#FE6513",
                    borderRadius: "15px",
                    border: "1px solid #FE6513",
                    height: xl ? "36px" : "38px",
                    width: "100%",
                    "&:hover": {
                      backgroundColor: "#FFF2ED",
                      color: "#FE6513",
                    },
                  },
                }}
              >
                Profilini tamamla
              </Button>
            )}
            <Button
              styles={{
                root: {
                  backgroundColor: "#D8FDFF",
                  fontSize: xl ? 13 : 14,
                  fontWeight: "400",
                  color: "#029199",
                  borderRadius: "15px",
                  marginTop: xl ? "6px" : "10px",
                  "&:hover": {
                    backgroundColor: "#D8FDFF",
                  },
                },
              }}
              sx={{
                width: "100%",
                height: xl ? "36px" : "38px",
              }}
              leftIcon={<img style={{width: xl ? 18 : 20}} src={Interest} alt="Interest" />}
            >
              {user?.fields && user?.fields.length > 0
                ? t("interest") + " | " + user?.fields[0]?.field
                : user?.user_type?.type}
            </Button>
          </Stack>
        </ProfileInfo>
      )}
      {(isCommunity || isHighlight) && isOwner && (
        <EditSectionStyled>
          <Group
            style={{ cursor: "pointer" }}
            align="center"
            position="center"
            spacing={10}
          >
            <Button
              leftIcon={
                <Edit size={18} strokeWidth={2} color="#ECA200" />
              }
              styles={() => ({
                root: {
                  fontSize: "16px",
                  fontWeight: "500",
                  backgroundColor: "transparent",
                  border: "none",
                  color: "#ECA200",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                },
              })}
              onClick={handleEdit}
            >
              {isHighlight ? t("edit-highlight") : t("edit-community")}
            </Button>
          </Group>
        </EditSectionStyled>
      )}

      {showCommunityTabs && isOwner && (
        <CommunityTabsStyle>
          <Text
            size={14}
            mb={20}
            mt={20}
            weight={600}
            color="#029199"
          >
            {t("manage")}
          </Text>
          <CommunityTabs tabs={tabs} />
        </CommunityTabsStyle>
      )}

      <ProgramsInfo user={user}>
        {cards.map((card, index) => (
          <Link
            to={`/${
              card.title === "Courses"
                ? "bootcamps"
                : card.title === `${t("steam")}`
                ? "steam"
                : card.title.toLowerCase()
            }`}
            key={index}
          >
            <CardContainer
              background={card.background}
              vectorColor={card.vectorColor}
            >
              <img className="vector-img" src={vector} alt="Vector" />
              <Stack spacing={12} style={{ padding: "0 25px" }} mt={15}>
                <Group sx={{ zIndex: 99999 }}>
                  <Text weight={700} size={14} color="#fff">
                    {card.title}
                  </Text>
                </Group>
                <Button
                  size="md"
                  radius={14}
                  leftIcon={<card.icon strokeWidth={1.5} />}
                  styles={() => ({
                    root: {
                      fontSize: "12px",
                      background: card.vectorColor,
                      color: "#364152",
                      cursor: "pointer",
                      padding: "0 20px",
                      height: "40px",
                      width: "100%",
                      "&:hover": {
                        opacity: 0.8,
                        background: card.vectorColor,
                      },
                    },
                  })}
                >
                  {t("go-to")}
                </Button>
              </Stack>
            </CardContainer>
          </Link>
        ))}
      </ProgramsInfo>
    </section>
  );
};

export default NavbarContent;

const ProfileInfo = styled.div`
  width: 100%;
  border-radius: 15px;
  position: relative;
  background-color: #fff;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  .profile-img {
    position: absolute;
    width: 64px;
    height: 64px;
    border-radius: 50px;
    border: 1px solid #c5c5c5;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
  }
  .mantine-qo1k2.mantine-Button-label {
    overflow-x: scroll;
    margin-top: 3px;
  }
  @media (max-width: 1440px) {
    .profile-img {
      width: 60px;
      height: 60px;
    }
  }
`;

const CardContainer = styled.div`
  width: 100%;
  height: 99px;
  padding-bottom: 10px;
  border: 1px solid #c5c5c5;
  margin-top: 8px;
  border-radius: 11px;
  background-color: ${(props) => props.background};
  position: relative;
  z-index: 1;
  .vector-img {
    position: absolute;
    width: 131px;
    height: 68px;
    top: 0;
    right: 0;
    border-radius: 0 11px 0 0;
    z-index: 0;
    filter: ${(props) =>
      `brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%) opacity(${
        props.vectorColor === "#D5E6AC" ? "0.5" : "0.3"
      })`};
  }
  .mantine-Button-root {
    transition: opacity 0.3s ease;
}
  }
`;

const ProgramsInfo = styled.div`
  padding: 0.1px 8
  px 8px 8px;
  margin-top: ${(props) => (props.user ? "25px" : "0")};
  border-radius: 11px;
`;

export const CommunityTabsStyle = styled.div`
  background-color: #fff;
  padding: 0.1px 25px 25px 25px;
  margin-top: 15px;
  border-radius: 11px;

  @media (max-width: 768px) {
    padding: 0.1px 10px 0px 10px;
    .manage-accordion {
      .mantine-Accordion-item.mantine-v4lv9f {
        border-bottom: none;
        .mantine-UnstyledButton-root.mantine-Accordion-control.mantine-21wjnm {
          display: flex;
          align-items: flex-start;
          padding: 16px 0px 16px 8px;
          &:hover {
            background-color: #fff;
          }
          .mantine-1h6pkea.mantine-Accordion-chevron {
            margin-top: 8px;
          }
        }
      }
    }
  }
`;

const EditSectionStyled = styled.div`
  background-color: #fff;
  margin-top: 15px;
  border-radius: 11px;
  padding: 15px 0px;
`;
