import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button, List, Loader, Progress, Text, ThemeIcon } from "@mantine/core";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import { DoorExit } from "tabler-icons-react";
import { useFetchResults } from "./useFetchResults";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@mantine/hooks";

export const QuizFinished = ({ props }) => {
  const { timePassed } = props;
  const { t } = useTranslation();

  const [currentProffession, setCurrentProffession] = useState({});
  const [resultsArray, isLoading] = useFetchResults({
    quizFinished: timePassed,
  });

  useEffect(() => {
    if (resultsArray) {
      setCurrentProffession(resultsArray[0]);
    }
  }, [resultsArray]);

  const matches = useMediaQuery("(max-width: 800px)");

  return (
    <>
      <Helmet>
        <title>{t("seo_tip_quiz_finished_title")}</title>
        <meta
          name="description"
          content={t("seo_tip_quiz_finished_description")}
        />
      </Helmet>

      <AnimatePresence mode="wait">
        <motion.div
          key="results"
          initial={{ opacity: 0, y: -500 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -500 }}
          transition={{ duration: 0.5 }}
        >
          <QuizFinishedStyle>
            <Text align={matches ? "center" : "left"} size={22} weight={600} color="#029199">
              SORĞU NƏTİCƏNİZ
            </Text>
            {isLoading ? (
              <Loader
                visible="true"
                variant="bars"
                color={"violet"}
                size="xl"
                style={{ display: "flex", margin: "0 auto" }}
              />
            ) : (
              <div className="section">
                <List spacing="xs" size="sm" center className="listItems">
                  {resultsArray?.map((sub, index) => {
                    return (
                      <div
                        key={sub?.id}
                        className={
                          currentProffession?.id === sub?.id && !matches
                            ? "xsa"
                            : ""
                        }
                      >
                        <motion.div
                          icon={" "}
                          onClick={() =>
                            setCurrentProffession({ ...sub, index })
                          }
                          className="item"
                        >
                          <Text size={16} weight={500} >
                            {index + 1}. {sub?.name}
                          </Text>
                          <ThemeIcon
                            size={30}
                            px={24}
                            radius="xs"
                            color="transparent"
                          >
                            <Text className="point" weight={500} size={16}>
                              {Number(sub?.point).toFixed(1)}
                            </Text>
                          </ThemeIcon>
                        </motion.div>
                        <Progress
                          mt={5}
                          mb={5}
                          radius="xs"
                          size="sm"
                          color={"#ffdf00"}
                          value={sub?.point}
                          styles={(theme) => ({
                            bar: {
                              background:
                                "linear-gradient(90deg, #FE6027 0%, #FE6027 100%)",
                            },
                          })}
                        />
                        {matches && currentProffession?.id === sub?.id && (
                          <div className="desc">
                            <Text align="center" size={"22px"} mb={10}>
                              {`${currentProffession?.name}`}
                            </Text>
                            <Text align="center" size={"16"}>
                              {currentProffession?.description}
                            </Text>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </List>
                {!matches && (
                  <div className="desc">
                    <Text
                      align="left"
                      size={"22px"}
                      weight={600}
                      color="#364152"
                      mb={10}
                      className="title"
                    >
                      {`${currentProffession?.name}`}
                    </Text>
                    <Text
                      align="left"
                      size={"17px"}
                      weight={400}
                      style={{
                        lineHeight: "28px",
                      }}
                      color="#364152"
                      className="text"
                    >
                      {currentProffession?.description}
                    </Text>
                  </div>
                )}
              </div>
            )}
            <Button
              component={Link}
              to="/"
              fullWidth
              rightIcon={<DoorExit />}
              variant="gradient"
              gradient={{ from: "violet", to: "#b565af", deg: 35 }}
              styles={(theme) => ({
                root: {
                  background:
                    "linear-gradient(90deg, #2DB5C2 0%, #029199 100%)",
                  height: "48px",
                  fontSize: "15px",
                  fontWeight: 500,
                  "&:hover": {
                    background:
                      "linear-gradient(90deg, #2DB5C2 100%, #029199 0%)",
                  },
                },
              })}
            >
              Ana səhifə
            </Button>
          </QuizFinishedStyle>
        </motion.div>
      </AnimatePresence>
    </>
  );
};

const QuizFinishedStyle = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  border-radius: 0.25rem;
  margin: 0 auto;
  padding: 2rem;
  max-width: 90%;
  h1,
  h2,
  h3,
  a,
  button {
    font-weight: 500;
  }
  .section {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    width: 100%;
    margin: 0 auto;
    grid-gap: 1rem;
    button {
      width: 100%;
    }
    .listItems {
      position: relative;
      border-radius: 10px;
      background: #fff;
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
      padding: 1.5rem 2.5rem 1.5rem 1.5rem;
      .xsa {
        ::before {
          margin-top: 0.95rem;
          right: 10px;
          position: absolute;
          content: " ";
          width: 0;
          height: 0;
          align-items: center;
          padding: 0.2rem;
          border-radius: 50%;
          background-color: #ffdf00;
        }
      }
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px;
        border: 1px solid #fe6027;
        background: #fff2ed;
        color: #404040;
        cursor: pointer;
        transition: all 0.15s;
        padding: 0.4rem 1rem;
        .point {
          color: #fe6027;
        }
        :hover {
          background: #fe6027;
          color: white;
          .point {
            color: white;
          }
        }
      }
    }
  }
  .desc {
    color: #000;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    padding: 1rem;
  }
  @media (max-width: 800px) {
    padding: 1rem 1rem 3rem 1rem;
    border-radius: 0;
    .section {
      grid-template-columns: 1fr;
      margin: 0;
      padding: 0;
    }
    .desc {
      margin-bottom: 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
     
    }
  }
`;
