import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Button, Text } from "@mantine/core";
import { Flag3, GenderFemale, GenderMale } from "tabler-icons-react";
import { useMediaQuery } from "@mantine/hooks";

export const PickGender = ({ props }) => {
  const { t } = useTranslation();

  const { finishQuiz, chooseOption, chosenOptions, pendingOptions } = props;
  const xs = useMediaQuery('(max-width: 576px)');

  return (
    <>
      <Helmet>
        <title>{t("seo_tip_pick_gender_title")}</title>
        <meta
          name="description"
          content={t("seo_tip_pick_gender_description")}
        />
      </Helmet>

      <div style={{ height: "100%" }} className="question">
        <div className="questionDiv">
          <Text align="center" size={28} weight={600} mb={30}>
            Cinsiniz:
          </Text>
        </div>
        <div className="options" style={{ display: "flex", flexDirection: xs ? "column" : "row", gridGap: "1rem" }}>
          <Button
            size="xl"
            leftIcon={<GenderFemale size={30} />}
            fullWidth
            color="grape"
            key={158}
            radius={10}
            style={{
              border:
                chosenOptions?.id?.includes(158) ||
                pendingOptions?.id?.includes(158)
                  ? "3px solid #be4bdb"
                  : "1px solid transparent",
              background:
                chosenOptions?.id?.includes(158) ||
                pendingOptions?.id?.includes(158)
                  ? "linear-gradient(135deg, #f3d9fa 0%, #be4bdb 100%)"
                  : undefined,
              transform:
                chosenOptions?.id?.includes(158) ||
                pendingOptions?.id?.includes(158)
                  ? "scale(1.02)"
                  : "scale(1)",
              boxShadow:
                chosenOptions?.id?.includes(158) ||
                pendingOptions?.id?.includes(158)
                  ? "0 4px 15px rgba(190, 75, 219, 0.3)"
                  : "none",
              transition: "all 0.2s ease",
            }}
            onClick={() => chooseOption({ id: 158, multi: false })}
          >
            <Text size className="optionText" style={{
              color: chosenOptions?.id?.includes(158) ||
                pendingOptions?.id?.includes(158)
                ? "#fff"
                : undefined
            }}>
              Qadın
            </Text>
          </Button>
          <Button
            size="xl"
            leftIcon={<GenderMale size={30} />}
            fullWidth
            color="blue"
            key={159}
            radius={10}
            style={{
              border:
                chosenOptions?.id?.includes(159) ||
                pendingOptions?.id?.includes(159)
                  ? "3px solid #228be6"
                  : "1px solid transparent",
              background:
                chosenOptions?.id?.includes(159) ||
                pendingOptions?.id?.includes(159)
                  ? "linear-gradient(135deg, #d0ebff 0%, #228be6 100%)"
                  : undefined,
              transform:
                chosenOptions?.id?.includes(159) ||
                pendingOptions?.id?.includes(159)
                  ? "scale(1.02)"
                  : "scale(1)",
              boxShadow:
                chosenOptions?.id?.includes(159) ||
                pendingOptions?.id?.includes(159)
                  ? "0 4px 15px rgba(34, 139, 230, 0.3)"
                  : "none",
              transition: "all 0.2s ease",
            }}
            onClick={() => chooseOption({ id: 159, multi: false })}
          >
            <Text size className="optionText" style={{
              color: chosenOptions?.id?.includes(159) ||
                pendingOptions?.id?.includes(159)
                ? "#fff"
                : undefined
            }}>
              Kişi
            </Text>
          </Button>
        </div>
        <Button
          size="md"
          mt={30}
          fullWidth
          className={"addBorder buttonNav"}
          leftIcon={<Flag3 />}
          onClick={() => chosenOptions.id.length > 0 && finishQuiz()}
          radius={10}
          variant="filled"
          styles={(theme) => ({
            root: {
              background: "linear-gradient(90deg, #be4bdb 0%, #228be6 100%)",
              height: "48px",
              fontSize: "15px",
              fontWeight: 500,
              "&:hover": {
                background: "linear-gradient(90deg, #228be6 0%, #be4bdb 100%)",
              },
            },
          })}
        >
          Sorğunu bitir
        </Button>
      </div>
    </>
  );
};
