import React, { useState } from "react";
import { ViewContainer } from ".";
import CustomAccordion from "../../../Components/Profile/CustomAccordion";
import CustomSingleSelect from "../../../Components/Profile/CustomSingleSelect";
import { Text } from "@mantine/core";

const UserCountry = ({
  t,
  publicUser,
  handleDataId,
  user,
  selectDatas,
  selectedCountryId,
  selectedData,
  handleEditUser,
  type,
  isStudent
}) => {
  const [isCreateFormActive, setIsCreateFormActive] = useState(false);
  const [isEditFormActive, setIsEditFormActive] = useState(false);
  return (
    <ViewContainer
      isRequired={true}
      isEmpty={isStudent ? !user?.country_name : !user?.country?.name}
    >
      <div style={{ padding: "10px 30px 10px 20px" }}>
        <CustomAccordion
          defaultValue="country"
          value="country"
          userData={user?.country}
          title={t("country")}
          handleActiveCreateForm={setIsCreateFormActive}
          isCreateFormActive={isCreateFormActive}
          handleActiveEditForm={setIsEditFormActive}
          isEditFormActive={isEditFormActive}
          isPublic={publicUser ? true : false}
          user={user}
        >
          {isCreateFormActive || isEditFormActive ? (

            <CustomSingleSelect
              t={t}
              user={user}
              data={selectDatas}
              selectedData={selectedData}
              selectDataType={type}
              formType={isCreateFormActive ? "create" : "edit"}
              handleDataId={handleDataId}
              selectedCountryId={selectedCountryId}
              handleActiveCreateForm={setIsCreateFormActive}
              handleActiveEditForm={setIsEditFormActive}
              handleEditUser={handleEditUser}
            />
          ) : (
           <Text>
             {isStudent ? user?.country_name : user?.country?.name}
           </Text>
          )}
        </CustomAccordion>
      </div>
    </ViewContainer>
  );
};

export default UserCountry;
