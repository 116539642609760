import { React, useState } from "react";
import styled from "styled-components";
import { Button, Grid, Stack, Text, Title } from "@mantine/core";
import bg from "../../../Assets/SVGs/landing/vectorBackground.svg";
import LandingCard from "../../../Components/Cards/LandingCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { useMediaQuery } from "@mantine/hooks";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import icon1 from "../../../Assets/SVGs/landing/icon1.svg";
import icon2 from "../../../Assets/SVGs/landing/icon2.svg";
import icon3 from "../../../Assets/SVGs/landing/icon3.svg";
import icon4 from "../../../Assets/SVGs/landing/icon4.svg";
import { Link, useNavigate } from "react-router-dom";

export const SecondPart = ({ props }) => {
  const matches = useMediaQuery("(max-width: 576px)");
  const navigate = useNavigate();

  const leftVariants = {
    hidden: { opacity: 0, x: -100 },
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.2,
        duration: 0.5,
      },
    }),
  };

  const cardVariants = {
    hidden: { opacity: 0, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const { ref, inView } = useInView({
    triggerOnce: true, // Trigger animation only once
    threshold: 0.1, // Trigger when 10% of the component is in view
  });

  const [clickedIndex, setClickedIndex] = useState(null);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isTablet = useMediaQuery("(max-width: 1024px)");

  const handleClick = (index, card) => {
    if (isMobile) {
      if (clickedIndex === index) {
        navigate(card.to);
      } else {
        setClickedIndex(index);
      }
    } else {
      navigate(card.to);
    }
  };

  const handleSlideChange = () => {
    setClickedIndex(null);
  };

  const handleBackgroundClick = (e) => {
    // Check if the click is outside of the Swiper container
    const swiperContainer = document.querySelector(".swiper");
    if (!swiperContainer?.contains(e.target)) {
      setClickedIndex(null);
    }
  };

  const { t, secondPartData } = props;
  return (
    <SecondPartStyle ref={ref} onClick={handleBackgroundClick}>
      <Grid align="center">
        <Grid.Col xl={6} md={6} sm={12}>
          <Stack align={isTablet ? "center" : "flex-start"}>
            <motion.div
              custom={0}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={leftVariants}
            >
              <Title order={3} color="#1A5059" weight="bold" style={{textAlign: isTablet ? "center" : "left"}}>
                {secondPartData ? secondPartData.title : t("question")}
              </Title>
            </motion.div>
            {(matches && !secondPartData) && (
              <motion.div
                custom={0}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={leftVariants}
              >
                <AuthSection>
                  <LoginButton component={Link} to="/login">
                    {t("login")}
                  </LoginButton>
                  <RegisterButton component={Link} to="/register">
                    {t("register")}
                  </RegisterButton>
                </AuthSection>
              </motion.div>
            )}
            <motion.div
              custom={1}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={leftVariants}
            >
              {secondPartData && (
                <div
                  className="txt"
                  dangerouslySetInnerHTML={{
                    __html: secondPartData.description,
                  }}
                />
              )}
              {!secondPartData && (
                <Text style={{textAlign: isTablet ? "center" : "left"}} color="#565656" size={16} weight="400" className="txt">
                  {t("answer-1")}
                </Text>
              )}
            </motion.div>
            {!secondPartData && (
              <motion.div
                custom={2}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={leftVariants}
              >
                <Text style={{textAlign: isTablet ? "center" : "left"}} color="#565656" size={16} weight="400" className="txt">
                  {t("answer-2")}
                </Text>
              </motion.div>
            )}
          </Stack>
        </Grid.Col>
        <Grid.Col xl={6} md={6} sm={12} className="right">
          <img src={bg} alt="bg" className="bg-image" />
          {matches ? (
            <Swiper
              slidesPerView={1}
              spaceBetween={60}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper programs"
              onSlideChange={handleSlideChange}
            >
              <SwiperSlide onClick={() => handleClick(0, { to: secondPartData ? "/steamland" : "/olympics" })}>
                {" "}
                <motion.div
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={cardVariants}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <LandingCard
                    props={{
                      steamland_title: secondPartData?.steam_title,
                      steamland_description: secondPartData?.steam_description,
                      t,
                      icon: icon1,
                      title: "olympic-hackaton-title",
                      text: "olympic-hackaton-content",
                      isClicked: clickedIndex === 0,
                      isMobile,
                    }}
                  />
                </motion.div>
              </SwiperSlide>
              <SwiperSlide onClick={() => handleClick(1, { to: secondPartData ? "/steamland" : "/steam" })}>
                {" "}
                <motion.div
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={cardVariants}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <LandingCard
                    props={{
                      steamland_title: secondPartData?.steam_project_title,
                      steamland_description: secondPartData?.steam_project_description,
                      t,
                      icon: icon2,
                      title: "steam-title",
                      text: "steam-content",
                      isClicked: clickedIndex === 1,
                      isMobile,
                    }}
                  />
                </motion.div>
              </SwiperSlide>{" "}
              <SwiperSlide
                onClick={() => handleClick(2, { to: secondPartData ? "/steamland" : "/universities" })}
              >
                {" "}
                <motion.div
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={cardVariants}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <LandingCard
                    props={{
                      steamland_title: secondPartData?.simulation_title,
                      steamland_description: secondPartData?.simulation_description,
                      t,
                      icon: icon3,
                      title: "study-title",
                      text: "study-content",
                      isClicked: clickedIndex === 2,
                      isMobile,
                    }}
                  />
                </motion.div>
              </SwiperSlide>{" "}
              <SwiperSlide
                onClick={() => handleClick(3, { to: secondPartData ? "/steamland" : "/communities" })}
              >
                {" "}
                <motion.div
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={cardVariants}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <LandingCard
                    props={{
                      steamland_title: secondPartData?.olympiad_title,
                      steamland_description: secondPartData?.olympiad_description,
                      t,
                      icon: icon4,
                      title: "community-title",
                      text: "community-content",
                      isClicked: clickedIndex === 3,
                      isMobile,
                    }}
                  />
                </motion.div>
              </SwiperSlide>
            </Swiper>
          ) : (
            <div className="card-container">
              <Link style={{width: "100%"}} to={secondPartData?.steam_title ? "/steamland" : "/olympics"} className="card">
                <motion.div
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={cardVariants}
                >
                  <LandingCard
                    props={{
                      steamland_title: secondPartData?.steam_title,
                      steamland_description: secondPartData?.steam_description,
                      t,
                      icon: icon1,
                      title: "olympic-hackaton-title",
                      text: "olympic-hackaton-content",
                      to: "/olympics",
                      isClicked: clickedIndex === 0,
                      isMobile,
                    }}
                  />
                </motion.div>
              </Link>
              <Link style={{width: "100%"}} to={secondPartData?.steam_project_title ? "/steamland" : "/steam"} className="card">
                <motion.div
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={cardVariants}
                >
                  <LandingCard
                    props={{
                      steamland_title: secondPartData?.steam_project_title,
                      steamland_description: secondPartData?.steam_project_description,
                      t,
                      icon: icon2,
                      title: "steam-title",
                      text: "steam-content",
                      to: "/steam",
                      isClicked: clickedIndex === 1,
                      isMobile,
                    }}
                  />
                </motion.div>
              </Link>
              <Link style={{width: "100%"}} to={secondPartData?.simulation_title ? "/steamland" : "/universities"} className="card">
                <motion.div
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={cardVariants}
                >
                  <LandingCard
                    props={{
                      steamland_title: secondPartData?.simulation_title,
                      steamland_description: secondPartData?.simulation_description,
                      t,
                      icon: icon3,
                      title: "study-title",
                      text: "study-content",
                      to: "/universities",
                      isClicked: clickedIndex === 2,
                      isMobile,
                    }}
                  />
                </motion.div>
              </Link>
              <Link style={{width: "100%"}} to={secondPartData?.olympiad_title ? "/steamland" : "/communities"} className="card">
                <motion.div
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={cardVariants}
                >
                  <LandingCard
                    props={{
                      steamland_title: secondPartData?.olympiad_title,
                      steamland_description: secondPartData?.olympiad_description,
                      t,
                      icon: icon4,
                      title: "community-title",
                      text: "community-content",
                      to: "/communities",
                      isClicked: clickedIndex === 3,
                      isMobile,
                    }}
                  />
                </motion.div>
              </Link>
            </div>
          )}
        </Grid.Col>
      </Grid>
    </SecondPartStyle>
  );
};

const SecondPartStyle = styled.div`
  max-width: 90%;
  margin: 0 auto 80px auto;
  position: relative;
  .card-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 25px;

    .card {
      border: 1px solid #black;
      z-index: 5;
    }
  }

  .right {
    position: relative;
    .bg-image {
      position: absolute;
      left: -70px;
      top: 0;
      z-index: 1;
    }
  }

  .txt {
    width: 65%;
    margin-top: 20px;
    p{
    font-size: 16px;
    font-weight: 400;
    color: #575757;
    }

    ul{
      list-style : disc;
      padding: 0;
      margin-left: 20px;
      margin-top: 10px;
      li{
        font-size: 16px;
        font-weight: 400;
        color: #575757;
        margin-bottom: 6px;
        }
    }
    @media (max-width: 1024px) {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  @media screen and (max-width: 990px) {
    .right {
      position: relative;
      .bg-image {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 40px;
        z-index: 1;
      }
    }
  }
  @media screen and (max-width: 576px) {
    .programs {
      padding: 10px 10px 40px 10px;
    }
    .cardWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .swiper-wrapper {
      padding-top: 10px;
    }
    .swiper-pagination-bullets {
      bottom: 0px;
    }
    .right {
      position: relative;
      margin-top: 40px;
      .bg-image {
        width: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        z-index: 1;
      }
    }
    .txt {
      width: 100%;
      margin-top: 20px;
    }
  }
  .programs {
    position: relative;
    z-index: 2;
  }
`;

const AuthSection = styled.div`
  padding: 10px 0px 0px 0px;
  display: flex;
  justify-content: left;
  gap: 10px;
`;

const LoginButton = styled(Button)`
  background: transparent;
  color: #323922;
  border: 1px solid #323922;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 500;
  height: 42px;
  flex: 1;
  padding: 0px 25px;
  &:hover {
    background: transparent;
    color: #323922;
    opacity: 0.9;
  }
`;

const RegisterButton = styled(Button)`
  background: #ccf075;
  color: black;
  border: none;
  border-radius: 25px;
  font-size: 14px;
  font-weight: 500;
  height: 42px;
  flex: 1;
  padding: 0px 20px;
  &:hover {
    background: #ccf075;
    color: black;
    opacity: 0.9;
  }
`;
