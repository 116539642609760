import { Radio, Group } from "@mantine/core";
import styled from 'styled-components';
import { PromoCode } from "./PromoCode";
import { PayWithInstallment } from "./PayWithInstallment";
import { useContext } from "react";
import { AppContext } from "../../Helpers/Context";

const PaymentMethodTitle = styled.div`
color: var(--Text, #0A0D13);
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 12px; /* 66.667% */
margin-bottom: 10px;
`;

const CustomRadioGroup = styled(Radio.Group)`
margin-bottom: 24px;
  .mantine-Radio-root {
    margin-right: 24px;
  }
  
  .mantine-Radio-label {
    font-size: 15px;
    color: #1C1C1C;
  }

  .mantine-Radio-radio {
    &:checked {
      background-color: #00BD90;
      border-color: #00BD90;
    }

    &:not(:checked):hover {
      border-color: #00BD90;
    }
  }

  .mantine-Radio-inner {
    color: #00BD90;
  }
`;

export function PromoTabs({ props }) {
  const {
    coursePrice,
    user,
    promoEntered,
    setPromoEntered,
    promoResponse,
    setPromoResponse,
    promoDoesNotExist,
    setPromoDoesNotExist,
    setCoursePrice,
    phoneNumber,
    setPhoneNumber,
    ulduzumResponse,
    setUlduzumResponse,
    noUlduzumPromo,
    setNoUlduzumPromo,
  } = props;

  const { setActiveTab, activeTab } = useContext(AppContext);


  function emptyFields() {
    setPromoEntered("");
    setPromoResponse(null);
    setPromoDoesNotExist(false);
    setPhoneNumber("");
  }

  return (
    <div>
      <PaymentMethodTitle>Ödəmə üsulu:</PaymentMethodTitle>
      <CustomRadioGroup
        value={activeTab}
        onChange={(value) => {
          setActiveTab(value);
          emptyFields();
        }}
      >
        <Group spacing={10}>
          <Radio 
            value="promo" 
            label="Promo"
            styles={{
              radio: {
                cursor: "pointer",
                '&:checked': {
                  backgroundColor: '#00BD90',
                  borderColor: '#00BD90',
                },
                '&:not(:checked):hover': {
                  borderColor: '#00BD90',
                }
              }
            }}
          />
          <Radio 
            value="installment" 
            label="Taksitlə ödəniş"
            styles={{
              radio: {
                cursor: "pointer",
                '&:checked': {
                  backgroundColor: '#00BD90',
                  borderColor: '#00BD90',
                },
                '&:not(:checked):hover': {
                  borderColor: '#00BD90',
                }
              }
            }}
          />
        </Group>
      </CustomRadioGroup>

      {activeTab === "promo" && (
        <PromoCode
          props={{
            coursePrice,
            user,
            promoEntered,
            setPromoEntered,
            promoResponse,
            setPromoResponse,
            promoDoesNotExist,
            setPromoDoesNotExist,
            setCoursePrice,
          }}
        />
      )}

      {activeTab === "installment" && (
        <PayWithInstallment
          props={{
            coursePrice,
          }}
        />
      )}
    </div>
  );
}
