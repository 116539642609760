import { Button } from '@mantine/core'
import axios from '../../../Helpers/Axios';
import { useContext } from 'react';
import styled from "styled-components"
import { ArrowRight, Check, Flag3 } from 'tabler-icons-react';
import { AppContext } from '../../../Helpers/Context';
import { IconChevronsRight, IconFlag3 } from '@tabler/icons-react';

export const NavButtons = ({ props }) => {

  const { currentQuestion, setProgressBarValue, entries, index, setPosition, chosenOption, setChosenOption, setOptionNotChosen, setQuizFinished } = props;

  const { user } = useContext(AppContext)

  function confirmAnswer(){
    if(chosenOption.id !== null){
      setChosenOption(e => ({ ...e, confirmed:true }))
      setProgressBarValue(e => e + 1)
      setOptionNotChosen(false)
      axios.post("/forum/nonspecialty/create/", {
        user:user?.id,
        option:chosenOption.id,
        question:currentQuestion.id,
      })
    }else{
      setOptionNotChosen(true)
    }
  }

  function changeQuestion(){
    setPosition(e => ({ index:index + 1, id:entries[index]?.id, order:entries[index]?.order }))
    setChosenOption({ index:0, id:null, text:null, confirmed:false })
  }

  return (
    <NavButtonsStyle>
                {chosenOption.confirmed === true &&
                  index === entries?.length && (
                    <div style={{ display: "flex", justifyContent: "right" }}>
                      <Button
                        className={"addBorder buttonNav"}
                        rightIcon={<IconFlag3 />}
                        onClick={() => setQuizFinished(true)}
                        radius={10}
                        variant="filled"
                        styles={(theme) => ({
                          root: {
                            background:
                              "linear-gradient(90deg, #2DB5C2 0%, #029199 100%)",
                            height: "48px",
                            fontSize: "15px",
                            fontWeight: 500,
                            "&:hover": {
                              background:
                                "linear-gradient(90deg, #2DB5C2 100%, #029199 0%)",
                            },
                          },
                        })}
                      >
                        Finish
                      </Button>
                    </div>
                  )}
                {chosenOption.confirmed === false && (
                  <div style={{ display: "flex", justifyContent: "right" }}>
                    <Button
                      className={
                        chosenOption.id !== null
                          ? "addBorder buttonNav"
                          : "buttonNav"
                      }
                      rightIcon={
                        chosenOption.id !== null ? <IconChevronsRight /> : null
                      }
                      onClick={() => confirmAnswer()}
                      radius={10}
                      variant="filled"
                      styles={(theme) => ({
                        root: {
                          background:
                            "linear-gradient(90deg, #2DB5C2 0%, #029199 100%)",
                          height: "48px",
                          fontSize: "15px",
                          fontWeight: 500,
                          "&:hover": {
                            background:
                              "linear-gradient(90deg, #2DB5C2 100%, #029199 0%)",
                          },
                        },
                      })}
                    >
                      Təsdiqlə
                    </Button>
                  </div>
                )}
                {chosenOption.confirmed === true &&
                  index !== entries?.length && (
                    <div style={{ display: "flex", justifyContent: "right" }}>
                      <Button
                        pl={55}
                        pr={40}
                        rightIcon={<IconChevronsRight />}
                        onClick={() => changeQuestion()}
                        radius={10}
                        variant="filled"
                        styles={(theme) => ({
                          root: {
                            background:
                              "linear-gradient(90deg, #2DB5C2 0%, #029199 100%)",
                            height: "48px",
                            fontSize: "15px",
                            fontWeight: 500,
                            "&:hover": {
                              background:
                                "linear-gradient(90deg, #2DB5C2 100%, #029199 0%)",
                            },
                          },
                        })}
                      >

                        İrəli
                      </Button>
                    </div>
                  )}

    </NavButtonsStyle>
  )
}

const NavButtonsStyle = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 1rem;
  .buttonNav{
    font-weight: 500;
    font-size: 16px;
    min-width: 160px;
    border: 1px solid transparent;
    &:hover{
      background-color: #495057;
    }
  }
  .addBorder{
    border: 1px solid #ADB5BD;
  }
  h2{
    margin: 0;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    button{
      width: 100%;
    }
  }
`