import React from 'react';
import styled from 'styled-components';
import { IconChevronDown } from '@tabler/icons-react';
import { Link, useLocation } from 'react-router-dom';

const MenuBarDropdown = ({ title, items, isNoneDropdown, link, onClose, isOpen, onToggle }) => {
  const currentLang = localStorage.getItem("tsklng") || "az";
  const location = useLocation();

  const handleItemClick = () => {
    onToggle(null);
    onClose();
  };

  if (isNoneDropdown) {
    return (
      <DropdownContainer>
        <StyledLink to={link} onClick={handleItemClick}>
          <DropdownHeader>
            <span>{title}</span>
          </DropdownHeader>
        </StyledLink>
      </DropdownContainer>
    );
  }

  return (
    <DropdownContainer>
      <DropdownHeader onClick={() => onToggle(title)}>
        <span className={location.pathname === link ? 'active' : ''}>
          {title}
        </span>
        <ChevronIcon isOpen={isOpen}>
          <IconChevronDown style={{paddingTop: '6px'}} size={16} color="#fff" />
        </ChevronIcon>
      </DropdownHeader>
      
      <DropdownContent isOpen={isOpen}>
        {items?.map((item, index) => {
          if (item.link === "/tip" && currentLang !== "az") {
            return null;
          }
          return (
            <StyledLink 
              key={index} 
              to={item.link} 
              onClick={handleItemClick}
              className={location.pathname === item.link ? 'active' : ''}
            >
              <DropdownItem>{item.label}</DropdownItem>
            </StyledLink>
          );
        })}
      </DropdownContent>
    </DropdownContainer>
  );
};

export default MenuBarDropdown;

const DropdownContainer = styled.div`
  width: 100%;
`;

const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 0;
  cursor: pointer;

  span {
    font-weight: 500;
  }

  span.active {
    color: #CCF075;
  }
`;

const ChevronIcon = styled.div`
  transition: transform 0.3s ease;
  transform: ${props => props.isOpen ? 'rotate(180deg)' : 'rotate(0)'};
`;

const DropdownContent = styled.div`
  overflow: hidden;
  max-height: ${props => props.isOpen ? '500px' : '0'};
  transition: max-height 0.3s ease-in-out;
`;

const DropdownItem = styled.div`
  color: white;
  font-size: 14px;
  padding: 6px 0 12px 20px;
  cursor: pointer;

  &:hover {
    color: #CCF075;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;

  &.active {
    ${DropdownItem} {
      color: #CCF075;
    }
  }
`;